<template>
  <div class="container">
    <div class="row">
      <h5>관제점 그룹</h5>
      <table class="table">
        <thead>
          <tr>
            <th v-for="(item, index) in ptGroupStats" :key="index">
              {{ item.value }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(item, index) in ptGroupStats" :key="index">
              {{ item.cnt }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <h5>관제점 유형</h5>
      <table class="table">
        <thead>
          <tr>
            <th v-for="(item, index) in isVirtualStats" :key="index">
              {{ item.value }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(item, index) in isVirtualStats" :key="index">
              {{ item.cnt }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <h5>집계 유형</h5>
      <table class="table">
        <thead>
          <tr>
            <th v-for="(item, index) in ptTypeCodeStats" :key="index">
              {{ item.value }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(item, index) in ptTypeCodeStats" :key="index">
              {{ item.cnt }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <h5>사용중 구분</h5>
      <table class="table">
        <thead>
          <tr>
            <th v-for="(item, index) in enabledStats" :key="index">
              {{ item.value }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(item, index) in enabledStats" :key="index">
              {{ item.cnt }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <h5>단위 유형 구분</h5>
      <table class="table">
        <thead>
          <tr>
            <th v-for="(item, index) in unitLargeCodeStats" :key="index">
              {{ item.value }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(item, index) in unitLargeCodeStats" :key="index">
              {{ item.cnt }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="detail-notification-icon">
            <i class="fas fa-external-link-alt"></i>
            <div class="detail-notification-text">조건을 지정하여 관제점을 선택하세요. </div>
        </div> -->
  </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";

export default {
  async mounted() {
    await this.loadData();
  },
  data() {
    return {
      isVirtualStats: [],
      ptTypeCodeStats: [],
      enabledStats: [],
      ptGroupStats: [],
      unitLargeCodeStats: [],
    };
  },
  methods: {
    async loadData() {
      try {
        const result = await backEndApi.pointInfo.getPointOverview();

        // item.value
        // item.cnt

        let othersCnt = 0;
        let totalCnt = 0;

        //isVirtualStats
        this.isVirtualStats = result.data.isVirtualStats.map((item) => {
          try {
            const found = this.$store.state.commonCodes.isVirtual.find(
              (code) => code.value == item.value
            );
            return { value: found.text, cnt: item.cnt };
          } catch (err) {
            return { value: "others", cnt: item.cnt };
          }
        });
        othersCnt = this.isVirtualStats
          .filter((item) => item.value == "others")
          .reduce((acc, item) => {
            return acc + parseInt(item.cnt);
          }, 0);
        this.isVirtualStats = this.isVirtualStats.filter(
          (item) => item.value != "others"
        );
        this.isVirtualStats.push({ value: "잔여항목", cnt: othersCnt });

        totalCnt = this.isVirtualStats.reduce((acc, item) => {
          return acc + parseInt(item.cnt);
        }, 0);
        this.isVirtualStats.push({ value: "합계", cnt: totalCnt });

        //ptTypeCodeStats
        this.ptTypeCodeStats = result.data.ptTypeCodeStats.map((item) => {
          try {
            const found = this.$store.state.commonCodes.ptTypeCode.find(
              (code) => code.value == item.value
            );
            return { value: found.text, cnt: item.cnt };
          } catch (err) {
            return { value: "others", cnt: item.cnt };
          }
        });
        othersCnt = this.ptTypeCodeStats
          .filter((item) => item.value == "others")
          .reduce((acc, item) => {
            return acc + parseInt(item.cnt);
          }, 0);
        this.ptTypeCodeStats = this.ptTypeCodeStats.filter(
          (item) => item.value != "others"
        );
        this.ptTypeCodeStats.push({ value: "잔여항목", cnt: othersCnt });

        totalCnt = this.ptTypeCodeStats.reduce((acc, item) => {
          return acc + parseInt(item.cnt);
        }, 0);
        this.ptTypeCodeStats.push({ value: "합계", cnt: totalCnt });

        //enabledStats
        this.enabledStats = result.data.enabledStats.map((item) => {
          try {
            const found = this.$store.state.commonCodes.enabled.find(
              (code) => code.value == item.value
            );
            return { value: found.text, cnt: item.cnt };
          } catch (err) {
            return { value: "others", cnt: item.cnt };
          }
        });
        othersCnt = this.enabledStats
          .filter((item) => item.value == "others")
          .reduce((acc, item) => {
            return acc + parseInt(item.cnt);
          }, 0);
        this.enabledStats = this.enabledStats.filter(
          (item) => item.value != "others"
        );
        this.enabledStats.push({ value: "잔여항목", cnt: othersCnt });

        totalCnt = this.enabledStats.reduce((acc, item) => {
          return acc + parseInt(item.cnt);
        }, 0);
        this.enabledStats.push({ value: "합계", cnt: totalCnt });

        //unitLargeCodeStats
        this.unitLargeCodeStats = result.data.unitLargeCodeStats.map((item) => {
          try {
            const found = this.$store.state.unitTypes.find(
              (code) => code.value == item.value
            );
            return { value: found.text, cnt: item.cnt };
          } catch (err) {
            return { value: "others", cnt: item.cnt };
          }
        });
        othersCnt = this.unitLargeCodeStats
          .filter((item) => item.value == "others")
          .reduce((acc, item) => {
            return acc + parseInt(item.cnt);
          }, 0);
        this.unitLargeCodeStats = this.unitLargeCodeStats.filter(
          (item) => item.value != "others"
        );
        this.unitLargeCodeStats.push({ value: "잔여항목", cnt: othersCnt });

        totalCnt = this.unitLargeCodeStats.reduce((acc, item) => {
          return acc + parseInt(item.cnt);
        }, 0);
        this.unitLargeCodeStats.push({ value: "합계", cnt: totalCnt });

        //ptGroupStats
        this.ptGroupStats = result.data.ptGroupStats.map((item) => {
          return { value: item.value, cnt: item.cnt };
        });

        totalCnt = this.ptGroupStats.reduce((acc, item) => {
          return acc + parseInt(item.cnt);
        }, 0);
        this.ptGroupStats.push({ value: "합계", cnt: totalCnt });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid orangered; */
  text-align: center;
  overflow: auto;
}

.detail-notification-icon {
  margin-bottom: 10px;
  color: #444;
  font-size: 24px;
}

.detail-notification-text {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* .row {
    display: flex;
    justify-content: center;
    width: 100%;
}

.col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
} */

h5 {
  margin: 0px;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table {
  width: 70%;
  border-collapse: collapse;
  text-align: center;
  margin-top: 10px;
  table-layout: fixed;
  /* 고정 테이블 레이아웃 설정 */
}

.table th,
.table td {
  border: 1px solid #90a4ae;
  padding: 8px;
  text-align: center;
  width: calc(100% / 3);
  /* 각 컬럼 균등 분할 */
}

.table thead {
  background-color: #78909c;
  color: #fff;
}

.table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>
