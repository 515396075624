<template>
	<div class="m-r-15">
		<div class="d-flex flex-row">
			<div class="col-lg-10 p-l-0 p-r-0">
				<div class="pull-left">
					<div class="h5">
						{{$t('시스템 관리')}} > {{$t('알람 관리')}} > {{$t('알람 발생 이력')}}
					</div>
				</div>
			</div>
		</div>
		<hr style="margin: 10px 0px 10px 0px" />

		<div class="form-inline m-b-10">
			<ln-select :label="$t('알람 그룹')" v-model="condition.alarmGroup" :items="alarmGroups" />
			<ln-picker :label="$t('시작일')" v-model="condition.sDt" :setting="{ format: 'YYYY-MM-DD' }" />
			<ln-picker :label="$t('종료일')" v-model="condition.eDt" :setting="{ format: 'YYYY-MM-DD' }" />
			<label><trans>검색어</trans></label>
			<span>&nbsp;</span>
			<valid-input :inputType="'text'" :inputValue.sync="condition.searchText" :placeholder="$t('검색어를 입력하세요.')"
				@keydownMethod="onSearchAlarmLog()" :rules="rules.ALARM_ENABLE_SEARCH_RULE" :NonStatus="true"
				style="marginRight: 20px"></valid-input>
			<btn-search @click="onSearchAlarmLog()" :disabled="loading" />
		</div>

		<div class="row">
			<div class="col-lg-12" :class="{ 'panel-loading': loading }">
				<vue-good-table
					v-bind="GRID_OPT"
					:columns="columns"
					:rows="alarmLogs"
					:pagination-options="{
						enabled: true,
						nextLabel: $t('다음'),
						prevLabel: $t('이전'),
						ofLabel: '/',
						pageLabel: $t('페이지'), // for 'pages' mode
						rowsPerPageLabel: $t('표시 개수'),
					}"
					>
					<div slot="emptystate" style="text-align: center;">
            {{$t('조회된 결과가 없습니다.')}}
          </div>
					<template slot="table-row" slot-scope="props">
						<template v-if="props.column.label == $t('일자')">
							{{ props.row.regDt.format("yyyy-MM-dd") }}
						</template>
						<template v-else-if="props.column.label == $t('시간')">
							{{ props.row.regDt.format("HH:mm:ss") }}
						</template>
						<template v-else-if="props.column.field == 'ptAddr'">
							<a href="javascript:;" @click="openPointModal(props.row)" v-b-tooltip.hover.right :title="$t('관제점 상세')"> {{ props.row.ptAddr }} </a>
						</template>
						<template v-else-if="props.column.field == 'passed'">
							{{ props.row.regDt }}
						</template>
						<template v-else-if="props.column.field == 'alarmType'">
							<a href="javascript:;" @click="openAlarmModal(props.row)" v-if="props.row.hasAlarm" v-b-tooltip.hover.right :title="$t('알람 상세')">
								<span> {{ props.row.alarmType | codeAlarmTypes }} </span>
							</a>
							<span v-else v-b-tooltip.hover :title="$t('삭제된 알람입니다.')">
								{{ props.row.alarmType | codeAlarmTypes }}
							</span>
						</template>
						<template v-else-if="props.column.field == 'currentValue'">
							{{ `${props.row.currentValue}` }}
						</template>
						<span v-else-if="props.column.field == 'description'" class="ellipsis-span">
							<a href="javascript:;" @click="openAlarmViewModal(props.row)" v-b-tooltip.hover.right :title="$t('알람로그 상세')">
								{{ props.row.description | codeChangelengthText(50) }}
							</a>
						</span>
						<template v-else>{{ props.formattedRow[props.column.field] }} </template>
					</template>
				</vue-good-table>
				<div class="panel-loader" v-if="loading"><span class="spinner-small"></span></div>
			</div>
		</div>

		<b-modal ref="pointModal" :hide-footer="true" :title="$t('관제점 상세')">
			<point-detail :ptAddr="paramPtAddr" @save-done="onPointSaveDone" :readonly="true" />
		</b-modal>

		<b-modal ref="alarmModal" :hide-footer="true" :title="$t('알람 상세')">
			<alarm-detail :alarm="paramAlarm" :readonly="true" />
		</b-modal>

		<b-modal ref="alarmViewModal" :hide-footer="true" :title="$t('알람로그 상세')">
			<alarm-view :alarmLogIdx="paramAlarm.alarmLogIdx" :readonly="true" />
		</b-modal>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import PointDetail from "../data-management/point/components/PointDetail.vue";
	import AlarmDetail from "./components/AlarmDetail.vue";
	import AlarmView from "./AlarmView.vue";
	import * as rules from "@src/consts/ruleConsts.js";

	export default {
		components: {
			PointDetail,
			AlarmDetail,
			AlarmView,
		},
		data() {
			return {
				// search condition datepicker
				condition: {
					alarmGroup: "",
					searchText: "",
					sDt: new Date().addDay(-1).format("yyyy-MM-dd"),
					eDt: new Date().format("yyyy-MM-dd"),
				},

				columns: [
					{
						label: this.$t("일자"),
						field: "regDt",
						type: "text",
						width: "7%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: this.$t("시간"),
						field: "regDt",
						type: "text",
						width: "7%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: this.$t("경과시간"),
						field: "passed",
						type: "text",
						width: "8%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: this.$t("알람 그룹"),
						field: "alarmGroup",
						type: "text",
						width: "10%",
						tdClass: "text-center text-inverse text-nowrap",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("관제점 주소"),
						field: "ptAddr",
						type: "text",
						width: "16%",
						tdClass: "text-left text-nowrap",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("알람 유형"),
						field: "alarmType",
						type: "text",
						width: "10%",
						tdClass: "text-center text-inverse text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: this.$t("현재값"),
						field: "currentValue",
						type: "number",
						width: "5%",
						tdClass: "text-center text-inverse text-nowrap",
						thClass: "text-center text-nowrap",
					},

					{
						label: this.$t("상태"),
						field: "status",
						type: "text",
						width: "5%",
						tdClass: "text-center text-inverse text-nowrap",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("설명"),
						field: "description",
						type: "text",
						width: "32%",
						tdClass: "text-left text-inverse text-nowrap text-ellipsis",
						thClass: "text-center text-nowrap",
					},
				],

				alarms: [], // 알람목록 - 알람이 삭제되면, 알람팝업을 띄울 수 없다.
				alarmLogs: [], // 알람로그 목록

				paramAlarm: {},
				paramPtAddr: "",

				// filter
				alarmGroups: [],
				filterGroup: "",

				loading: false,
				to_bind: null,
				rules,
			};
		},
		mounted() {
			// URL 파라미터로 ptAddr을 물고 들어오는 경우가 있음.
			if (!this.isEmpty(this.$route.params.ptAddr)) this.condition.searchText = this.$route.params.ptAddr;

			this.initSetting();
		},
		methods: {
			initSetting() {
				this.alarmGroups.clear();
				backEndApi.alarm.getAlarmGroups().then(({ data }) => {
					if (this.$err(data)) return;

					this.alarmGroups.range(data);
				});

				this.alarms.clear();
				backEndApi.alarm.searchAlarm().then(({ data }) => {
					if (this.$err(data)) return;

					this.alarms.range(data);

					this.onSearchAlarmLog();
				});
			},
			onSearchAlarmLog() {
				this.loading = true;
				clearTimeout(this.to_bind);
				this.to_bind = setTimeout(() => {
					let { alarmGroup, searchText, sDt, eDt } = this.condition; // searchText = ptAddr, description, max=5000 제한..

					this.alarmLogs.clear();
					backEndApi.alarm
						.searchAlarmLog(sDt, eDt, alarmGroup, searchText, 5000)
						.then(({ data }) => {
							if (this.$err(data)) return;

							this.alarmLogs.range(data);
              this.alarmLogs.forEach((log) => {
              
								log.hasAlarm = !this.isEmpty(this.alarms.find((alarm) => log.alarmGroup == alarm.alarmGroup && log.ptAddr == alarm.ptAddr));
							});

              this.alarmLogs.forEach((log) => {
                log.alarmGroup = global.xe.$t(log.alarmGroup);
                log.description = global.xe.$t(log.description);
              })
						})
						.then(() => {
							this.loading = false;
						});
				}, 100);
			},
			//#region 모달
			openAlarmModal(alarmLog) {
				this.paramAlarm = alarmLog;
				this.$refs.alarmModal.show();
			},
			openAlarmViewModal(alarmLog) {
				this.paramAlarm = alarmLog;
				this.$refs.alarmViewModal.show();
			},
			openPointModal(alarmLog) {
				this.paramPtAddr = alarmLog.ptAddr;
				this.$refs.pointModal.show();
			},
			onPointSaveDone() {
				this.$refs.pointModal.hide();
			},
			//#endregion
		},
	};
</script>
