
<template>
 <!-- ^트러블슈팅. vue-codemirror props 직접접근을 통한 데이터변경 에러. vue-codemirror와 codemirror간 이벤트 리스너가 차이가 났다. 라이브러리 선정 및 버전(module not found) 이슈.  -->
  <div ref="codeMirrorContainer" class="code-mirror-container"></div>
</template>

<script>
import "codemirror/lib/codemirror.css";
import "codemirror/addon/merge/merge.js";
import "codemirror/addon/merge/merge.css";
import "codemirror/theme/neo.css";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint.js";
import CodeMirror from "codemirror";
import { mapState } from "vuex";
import xelib from "xelib";
import 'codemirror/mode/javascript/javascript';


export default {
 
  props: ["value","disabled","hintList","arrName","existExplanation"],
  data() {
    return {
      codeMirrorInstance: null,

 /**
 *
 * @typedef {object} EditorOptions
 * @property {number} tabSize - 탭의 크기(스페이스 수).
 * @property {string} mode - 에디터의 모드 설정. 예: "plain/text",javascript.
 * @property {boolean} lineNumbers - 라인 번호를 표시할지 여부.
 * @property {boolean} lineWrapping - 긴 줄의 줄바꿈 여부.
 * @property {string} theme - 에디터의 테마 설정. 예: "neo".
 * @property {object} hintOptions - 자동완성 힌트 관련 옵션.
 * @property {Function} hintOptions.hint - 입력 처리 함수. `this`는 컴포넌트를 가리킵니다.
 * @property {boolean} hintOptions.completeSingle - 자동완성 힌트가 단일 항목으로 완성되는지 여부.
 */

/** @type {EditorOptions} */
      options: {
        tabSize: 4,
        mode:"javascript",
        lineNumbers: true,
        lineWrapping: true,
        readOnly:this.disabled?"nocursor":false,
        theme: "neo",
        hintOptions: {
          hint: this.handleInput,
          completeSingle: false,
        },
        style:{backgroundColor:{type:String,default:'#ffffff'}}
      },

      /**
       * 자동완성 리스트입니다.
       * @type {string[]} 
       */
      hintLists: null,
    };
  },
   created() {
    if(this.hintList){
      this.hintLists=this.hintList;
    }
    // this.processData();
    // this.totalHint();
  },
  mounted() {
    this.initializeCodeMirror(); 
  },
  beforeDestroy() {
    /** 컴포넌트 destroy 시 이벤트 해제  */
    if (this.codeMirrorInstance) {
      this.codeMirrorInstance.off("changes");
      this.codeMirrorInstance = null;
    }
  },
  computed: {
    ...mapState({
      pointList: (state) => state.pointList,
    }),
  },
  watch: {

    /**
     * 페이지가 변경될 시 새로운 데이터와 기존의 데이터를 비교하여 갱신.
     * @param {string} newVal props로 새로운 데이터를 받는다.
     */
    value(newVal) {
      if (
        this.codeMirrorInstance &&
        this.codeMirrorInstance.getValue() !== newVal
      ) {
        this.codeMirrorInstance.setValue(newVal);
      }
    },
  },
  methods: {

    /**
     * 자동완성 text를 공백을 기준으로 나눠 자동완성에 보여줄 내용과 실제로 입력될 내용을 분리하기위함
     * @param {string} item 공백을 기준으로 나눌 문자열
     * @returns {string} 나눈 배열에서 첫번째 토큰을 반환
     */
    inputHint(item){
      if(this.existExplanation){
                return  /UPL.\w+/.test(item.split(/\s/)[0])?item.split(/\s/)[0]:`${this.arrName}[${item.split(/\s/)[0]}]`;

      }
      else return /UPL.\w+/.test(item.split(/\s/)[0])?item:`${this.arrName}[${item}]`;
    },
    
    /**
     * UPL 함수들을 자동완성 목록에 포함시키기 위한 기능을 수행합니다.
     */
    totalHint() {

      /** @type {string[]} xelib UPL 지원 함수들에서 함수 이름만 추출하여 배열로 만듭니다. */
      const xelibArr = Object.keys(xelib.UplSupportFunctions);

      /** @type {string[]} UPL 전용 자동완성을 위해 각 함수 이름 앞에 'UPL.'을 추가합니다. */
      const xeliArrAddUpl = xelibArr?.map((item) => {
        return "UPL." + item;
      });

      // 기존의 힌트 목록에 UPL로 시작하는 함수 이름들을 병합합니다.
      this.hintLists = [...this.hintLists, ...xeliArrAddUpl];
      return;
    },

    /**
     * '물리관제점 (물리관제점 설명)'의 형식으로 자동완성 컴포넌트 표출. 
     * @returns {void}
     */
    // processData() {
    //   this.hintLists = this.pointList.map((item) => {
    //     return `${item.ptAddr} (${item.ptName})`
    //   });
    // },
    
    /**
     * ^트러블슈팅. 기존의 브라켓을 인지하는 알고리즘 => 정규표현식. [ ]내부의 내용을 하이라이팅 시키기 위함 
     */ 
    highlightBrackets() {
      const cm = this.codeMirrorInstance;
      const doc = cm.getDoc();
      const text = doc.getValue();

      /**@type {string} [ ]내부를 하이라이팅하기위한 정규표현식 */
      const regexBigBraket = /\[([^\]]+)\]/g;
      let match;

      while ((match = regexBigBraket.exec(text)) !== null) {
        const start = cm.posFromIndex(match.index + 1);
        const end = cm.posFromIndex(match.index + match[0].length - 1);
        cm.markText(start, end, { className: "highlight" });

      }
    },
    
    /** UPL함수토큰을 하이라이팅 시키는 함수 */
    highlightUPLfunc(){
      const cm=this.codeMirrorInstance;
      const doc=cm.getDoc();
      const text=doc.getValue();

      const regex = /UPL\.\w+/g;
      let match;
      for(let i=0;(match=regex.exec(text))!==null;i++){
       const start = cm.posFromIndex(match.index);
        const end = cm.posFromIndex(match.index + match[0].length);
        cm.markText(start, end, { className: "highlightUPL" });
     }
  
    }
,
    /** 
     * 마운트 시 코드미러를 초기화 시키기 위함
    */
    initializeCodeMirror() {
     
      this.codeMirrorInstance = CodeMirror(this.$refs.codeMirrorContainer, {
        value: this.value||"",
        ...this.options,
      });
      
     setTimeout(()=>{
     this.codeMirrorInstance.refresh();
     },10)
      
      this.codeMirrorInstance.on("inputRead", (cm,change) => {

    /** ^트러블슈팅. 의도치 않은 자동완성 컴포넌트 렌더링 이슈*/
        if (change.text[0] && /[a-zA-Z0-9.가-힣]/.test(change.text[0])) {
          cm.showHint();
        }
      });

      this.codeMirrorInstance.on("changes", () => {
        this.$emit("input", this.codeMirrorInstance.getValue());
        this.highlightBrackets();
        this.highlightUPLfunc();
      });

      this.highlightBrackets();
      this.highlightUPLfunc();
    },
    
    /** 
     * 자동완성 목록을 커스텀 하기위한 함수
     * @param {{editor:object}} editor codemirror editor의 객체
     * 
    */
    handleInput(editor) {
      editor.showHint({
        hint: this.customHint.bind(this),
      });
    },

    /**
     * 자동완성 목록을 커스텀 하기위한 로직이 있는 함수
     * @param {{editor:object}} editor - codemirror editor의 객체
     * @returns {object} 자동완성 라스트,자동완성 후 덮어쓰기 정보
     */
    customHint(editor) {

      /**@type {Pos:object} - 커서의 위치에 대한 객체. 현재의 라인, 현재의 라인에서 커서의 위치에대한 정보*/
      const cursor = editor.getCursor();

      /**@type {string} 코드미러에서 현재 라인의 문자열  */
      const stringPerLine = editor.getLine(cursor.line);
     
     /**@type {number} 초기화는 현재커서의 위치이며 추후 반복문을 통해 앞으로 이동하며 시작인덱스로 재할당 예정이기에 let */
      let start = cursor.ch;

      /**@type {number} 자동완성하여 덮어쓸 위치의 끝. 재할당이 되지않기에 const*/
      const end = cursor.ch;

      /**@type {string} 사용자가 입력한 문자열*/
      let word = "";
      
       /** 입력한 문자에서 공백과 [이 아니면 앞으로 이동하며 start위치 조정 */
      while (start > 0 && /[^([\s]/.test(stringPerLine.charAt(start - 1))) {
        start--;
        word = stringPerLine.charAt(start) + word;
      }
  
     /** 자동완성 전체 목록중에 사용자가 입력한 내용을 바탕으로 필터링하기 위함 */
      const list = this.hintLists.filter((item) => item.includes(word));

      if (list.length === 0) {
        return null;
      }

      return {
        list: list.map(item=>({displayText:item,text:this.inputHint(item)})),
        from: CodeMirror.Pos(cursor.line, start),
        to: CodeMirror.Pos(cursor.line, end), 
      };
    },
  },
};
</script>

<style scoped>


</style>
