/*********************************************
 *          입력필드 메세지 정의                 *
 *        (postfix : VALID_MESSAGE)            *
 **********************************************/

/** COMMON */
export const COMMON_NAME_VALID_MESSAGE = "한글, 영문, 숫자를 사용해 80자 이내로 작성하세요."
export const COMMON_CODE_VALID_MESSAGE = "한글을 제외하고 80자 이내로 작성하세요."

/** point */
export const POINT_ADDRESS_VALID_MESSAGE = "특수문자를 필수로 포함해 8 ~ 32자로 작성하세요."

/** equiptype */
export const EQUIP_TYPE_CATEGORY_VALID_MESSAGE = "설비 분류 선택은 필수입니다."

/** common code */
export const COMMON_CODE_PUBLIC_VALID_MESSAGE = "영문을 사용해 80자 이내로 작성하세요."
// codeitem
export const CODEITEM_VALID_MESSAGE = "영문, 숫자를 사용해 80자 이내로 작성하세요."

// #region 공통 (prefix : FAILEDRULES)\
/** requied */
export const FAILEDRULES_REQUIRED_VALID_MESSAGE = (fieldName) => `${global.xe.$mt(fieldName,'을(를) 입력하세요.')}`;

/** non_korean */
export const FAILEDRULES_NON_KOREAN_VALID_MESSAGE = (fieldName) => `${global.xe.$mt("이(가) 올바르지 않습니다.", fieldName)}`;

/** non_special */
export const FAILEDRULES_NON_SPECIAL_VALID_MESSAGE = (fieldName) => `${global.xe.$mt("이(가) 올바르지 않습니다.", fieldName)}`;

/** min */
export const FAILEDRULES_MIN_VALID_MESSAGE = (fieldName) => `${global.xe.$mt("이(가) 올바르지 않습니다.", fieldName)}`;

/** max */
export const FAILEDRULES_MAX_VALID_MESSAGE = (fieldName) => `${global.xe.$mt("이(가) 올바르지 않습니다.", fieldName)}`;

/** minmax */
export const FAILEDRULES_MIN__MAX_VALID_MESSAGE = (fieldName) => `${global.xe.$mt("이(가) 올바르지 않습니다.", fieldName)}`;

/** min_value */
export const FAILEDRULES_MIN_VALUE_VALID_MESSAGE = (fieldName) => `${global.xe.$mt("이(가) 올바르지 않습니다.", fieldName)}`;

/** max_value */
export const FAILEDRULES_MAX_VALUE_VALID_MESSAGE = (fieldName) => `${global.xe.$mt("이(가) 올바르지 않습니다.", fieldName)}`;

/** phone */
export const FAILEDRULES_PHONE_VALID_MESSAGE = (fieldName) => `${global.xe.$mt("이(가) 올바르지 않습니다.", fieldName)}`;

/** both-decimal */
export const FAILEDRULES_BOTH_DECIMAL_VALID_MESSAGE = (fieldName) => `${global.xe.$mt("이(가) 올바르지 않습니다.", fieldName)}`;

/** integer-decimal */
export const FAILEDRULES_INTEGER_DECIMAL_VALID_MESSAGE = (fieldName) => `${global.xe.$mt("이(가) 올바르지 않습니다.", fieldName)}`;

// #region 공통 (prefix : COMMON)

/** 서버 호출 오류 메시지 */
export const COMMON_API_CALL_FAIL_VALID_MESSAGE = "서버 호출 오류가 발생하였습니다. 다시 시도하세요.";

/** 글자수 범위 제한 메시지(이하) */
export const COMMON_OVER_LENGTH_VALID_MESSAGE = (length) => `${global.xe.$mt('자 이하로 작성하세요.', length, 'length')}`;

/** 글자수 범위 제한 메시지(이상) */
export const COMMON_UNDER_LENGTH_VALID_MESSAGE = (length) => `${global.xe.$mt('자 이상으로 작성하세요.', length, 'length')}`;

/** 글자수 범위 제한 메시지 */
export const COMMON_BOTH_LENGTH_VALID_MESSAGE = (min, max) => `${global.xe.$mt(min.toString() + ' ~ ' + max.toString(), '자 이내로 작성하세요.')}`;

/** 숫자 입력 범위 제한 메시지 */
export const COMMON_NUMBER_LENGTH_VALID_MESSAGE = (min, max) => `${global.xe.$mt('까지의 숫자를 입력하세요.', min.toString() + ' ~ ' + max.toString())}`;

/** 제한 숫자 이상 입력 메시지 */
export const COMMON_MIN_NUMBER_LENGTH_VALID_MESSAGE = (min) => `${global.xe.$mt('이상의 숫자를 입력하세요.', min)}`;
export const COMMON_MAX_NUMBER_LENGTH_VALID_MESSAGE = (max) => `${global.xe.$mt('이하의 숫자를 입력하세요.', max)}`;

/** 영문, 숫자, 특수문자 글자수 제한 메시지 */
export const COMMON_NONKOREAN_VALID_MESSAGE = (min, max) => `${global.xe.$mt('영문, 숫자, 특수문자를 사용해 자 이내로 작성하세요.', min.toString() + ' ~ ' + max.toString())}`;

/** 영문, 숫자 글자수 제한 메시지 */
export const COMMON_ALPHA_NUM_LENGTH_VALID_MESSAGE = (min, max) => `${global.xe.$mt('영문, 숫자를 사용해 자 이내로 작성하세요.', min.toString() + ' ~ ' + max.toString())}`;

/** 실수 자리수 제한 메시지 */
export const COMMON_DECIMAL_NUMBER_LENGTH_VALID_MESSAGE = (length) => `${global.xe.$mt('소수점 둘째 자리까지 포함하여 자 이내로 입력하세요.', length, 'length')}`;

// #region 공통 (prefix : PASSWORD)

/** 비밀번호 입력 오류 메시지 */
export const PASSWORD_CHANGE_VALID_MESSAGE = "영문, 숫자, 특수문자를 모두 포함하여 9 ~ 20 자 이내로 작성하세요.";

/** 비밀번호 불일치 메시지 */
export const PASSWORD_CHANGE_CONFIRM_VALID_MESSAGE = "비밀번호가 일치하지 않습니다.";

// #region 로그인 (prefix : LOGIN)

/** 아이디, 비밀번호 불일치 메시지 */
export const LOGIN_FAILURE_VALID_MESSAGE = "아이디 혹은 비밀번호가 올바르지 않습니다.";

/** 비밀번호 입력 조건 불일치 메시지 */
export const LOGIN_CHANGE_PASSWORD_VALID_MESSAGE = () => global.xe.$mt("비밀번호는 영대문자, 영소문자, 숫자, 특수문자를 3종류 이상으로 조합하고 9 ~ 20자리로 설정해야 합니다.");

/** 비밀번호 불일치 메시지 */
export const LOGIN_PASSWORD_CONFIRM_VALID_MESSAGE = "비밀번호가 일치하지 않습니다. 다시 확인하세요.";

/** 아이디, 비밀번호 일치 제한 메시지 */
export const LOGIN_NONE_SAME_ID_VALID_MESSAGE = "비밀번호는 아이디와 같을 수 없습니다.";

/** 사용할 수 없는 아이디 메세지 */
export const LOGIN_NOT_ALLOW_ID_VALID_MESSAGE = "사용할 수 없는 아이디입니다.";

// #region 로그인 (prefix : WIDGET)

/** 위젯 소수점자리 범위, 위젯 폰트크기 범위 */
export const WIDGET_DECIMAL_AND_FONTSIZE_LENGTH_VALID_MESSAGE = "1 ~ 99 까지의 숫자를 입력하세요.";

// #region 코드 (prefix : COMMON_LARGE_CODE)

/** 공통 대분류 코드 */
export const COMMON_LARGE_CODE_VALID_MESSAGE = "영문, 숫자를 사용해 1 ~ 20 자 이내로 작성하세요.";

/** 공통 대분류 코드명 */
export const COMMON_LARGE_CODE_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 정렬 순서 */
export const COMMON_LARGE_SORT_ORDER_VALID_MESSAGE = "9999 이하의 숫자를 입력해주세요.";

// #region 코드 (prefix : COMMON_SMALL_CODE)

/** 공통 소분류 코드 */
export const COMMON_SMALL_CODE_VALID_MESSAGE = "영문, 숫자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 공통 소분류 코드명 */
export const COMMON_SMALL_CODE_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

// #region 코드 (prefix : EQUIP_TYPE_CODE)

/** 계측기 유형 코드 */
export const EQUIP_TYPE_CODE_VALID_MESSAGE = "영문, 숫자를 사용해 1 ~ 10 자 이내로 작성하세요.";

/** 계측기 유형명 */
export const EQUIP_TYPE_CODE_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

// #region 코드 (prefix : UNIT_LARGE_CODE)

/** 단위 대분류 코드 */
export const UNIT_LARGE_CODE_VALID_MESSAGE = "영문, 숫자를 사용해 1 ~ 10 자 이내로 작성하세요.";

/** 단위 대분류 코드명 */
export const UNIT_LARGE_CODE_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

// #region 코드 (prefix : UNIT_SMALL_CODE)

/** 단위 소분류 코드 */
export const UNIT_SMALL_CODE_VALID_MESSAGE = "영문, 숫자를 사용해 1 ~ 10 자 이내로 작성하세요.";

/** 단위 소분류 코드명 */
export const UNIT_SMALL_CODE_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 20 자 이내로 작성하세요.";

// #region 알람 (prefix : REGION_CODE)

/** 지역 코드 */
export const REGION_CODE_VALID_MESSAGE = "영문, 숫자, 특수문자를 사용해 1 ~ 10 자 이내로 작성하세요.";

/** 지역 코드명 */
export const REGION_CODE_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

// #region 알람 (prefix : DASHBOARD_MANAGE)

/** 대시보드 제목 */
export const DASHBOARD_MANAGE_CODE_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 대시보드 다른 이른 저장 */
export const DASHBOARD_MANAGE_NEW_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 대시보드 분석 템플릿 저장 */
export const DASHBOARD_MANAGE_TEMPLATE_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

// #region 알람 (prefix : MENU)

/** 메뉴 명 */
export const MENU_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 표시순서 */
export const MENU_VIEW_ORDER_VALID_MESSAGE = "0 ~ 9999 까지의 숫자를 입력하세요.";

/** 그룹명 */
export const MENU_GROUP_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 사용자 아이디 */
export const MENU_USER_ID_VALID_MESSAGE = "영문, 숫자, 특수문자를 사용해 5 ~ 30 자 이내로 작성하세요.";

/** 비밀번호 */
export const MENU_PASSWORD_VALID_MESSAGE = () => global.xe.$t("비밀번호는 영대문자, 영소문자, 숫자, 특수문자를 3종류 이상으로 조합하고 9~20자리로 설정해야 합니다.");

/** 사용자명 */
export const MENU_USER_NAME_VALID_MESSAGE = "한글, 영문, 숫자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 이메일 */
export const MENU_EMAIL_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

// #region 요금 (prefix : EQUIP_SYSTEM)

/** 설비 계통 코드 */
export const EQUIP_SYSTEM_CODE_VALID_MESSAGE = "영문, 숫자, 특수문자를 사용해 1 ~ 10 자 이내로 작성하세요.";

/** 설비 계통명 */
export const EQUIP_SYSTEM_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 설비 유형 코드 */
export const EQUIP_SYSTEM_TYPE_CODE_VALID_MESSAGE = "영문, 숫자, 특수문자를 사용해 1 ~ 10 자 이내로 작성하세요.";

/** 설비 유형 명 */
export const EQUIP_SYSTEM_TYPE_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 설비 수집 항목 코드 */
export const EQUIP_SYSTEM_COLLECT_CODE_VALID_MESSAGE = "영문, 숫자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 설비 수집 제어항목 */
export const EQUIP_SYSTEM_COLLECT_CTRL_OPTION_VALID_MESSAGE = "제어항목을 선택하세요.";

/** 설비 수집 사용여부 */
export const EQUIP_SYSTEM_COLLECT_IS_USE_VALID_MESSAGE = "제어항목을 선택하세요.";

/** 설비 수집 항목 명*/
export const EQUIP_SYSTEM_COLLECT_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 설비 제어 항목명 */
export const EQUIP_SYSTEM_CONTROL_NAME_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

// #region 요금 (prefix : ALARM)

/** 알람 그룹 */
export const ALARM_BASE_GROUP_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자를 사용해 1 ~ 30 자 이내로 작성하세요.";

/** 알람 L & 알람 H */
export const ALARM_BASE_VALUE_L_VALUE_H_VALID_MESSAGE = "숫자를 1 ~ 16 자 이내로 작성하세요. (정수 부분 최대 12자리, 소수 부분 최대 2자리, 소수점 1자리, 음수부호 1자리)";


/** 서브 모듈명  */
export const SUB_MODULE_NAME_VALID_MESSAGE = "영문, 숫자를 사용해 8자 이내로 작성하세요."

/** 모듈 표시명 */

export const MODULE_ALIAS_VALID_MESSAGE = "한글, 영문, 숫자, 특수문자(/ 제외) 를 사용해 20자 이내로 작성하세요."