import axios from "axios";
import EventEmitter from "events";
// import FormData from "form-data";
//import config from "../../config.js";
import config from "@libs/config.js";
import { StatusCodes } from "http-status-codes";

const { isEmpty } = global.xe;

const _xemsHost = config.backend.apiUrl;

// let callCnt = 0;
function xemsHost() {
    // 더이상 Port 라운드로빈을 사용하지 않아도 됨 (PM2 클러스터링 적용)
    // let port = (config.backend.apiPort+1) + (++callCnt % config.backend.expressWorkers);
    // let port = config.backend.apiPort*1 + (++callCnt % config.backend.expressWorkers);
    // let url = `http://${config.backend.host}:${port}`;
    return config.backend.apiUrl;
}

const apiClient = axios.create({
    baseURL: _xemsHost,
    cancelToken: axios.CancelToken.source().token,
});

// function sendLogoutTimerKey(){

// }

const apiErrorEvent = new EventEmitter();
// const cancelToken = apiClient.CancelToken;

apiClient.interceptors.request.use(
    (req) => {
        // console.log(`★★★ Axios Request Interceptor => ${req.headers}`);

        return req;
    },
    (err) => {
        return Promise.reject(err);
    }
);

apiClient.interceptors.response.use(
    (res) => {
        // console.log(`★★★ Axios Response Interceptor => ${res.headers}`);
        return res;
    },
    (err) => {
        // let res = err.response;

        // console.log(err.response);

        if (err.response != undefined && err.response.status === StatusCodes.UNAUTHORIZED) {
            apiErrorEvent.emit("Unauthorized");
            apiClient.defaults.headers.common["Authorization"] = null;
        }

        // return Promise.reject(err);
        return err.response;
    }
);

function setAccessToken(accessToken) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}

function getAccessToken() {
    const accessToken = apiClient.defaults.headers.common["Authorization"];

    console.log(`★★ getAccessToken ==> ${accessToken}`);
    return accessToken;
}

// 캐시있는 부분은 전부 9200으로 붙어야 함..
export default {
    cancelSource: null,
    engineering: {
        getConfig() {
            return apiClient.get(`${_xemsHost}/engineering/getConfig`);
        },
        getRetentions() {
            return apiClient.get(`${_xemsHost}/engineering/getRetentions`);
        },
        getDumpInfos(tableName, sDt, eDt) {
            return apiClient.get(`${_xemsHost}/engineering/getDumpInfos`, { params: { tableName, sDt, eDt } });
        },
        clearCache() {
            return apiClient.get(`${_xemsHost}/engineering/clearCache`);
        },

        backup(tableName, filePath, isOverwrite) {
            return apiClient.post(`${_xemsHost}/engineering/backup`, { tableName, filePath, isOverwrite });
        },
        differential(tableName, timeType, startDt, endDt, isOverwrite) {
            return apiClient.post(`${_xemsHost}/engineering/differential`, {
                tableName,
                timeType,
                startDt,
                endDt,
                isOverwrite,
            });
        },
        restore(tableName, filePath) {
            return apiClient.post(`${_xemsHost}/engineering/restore`, { tableName, filePath });
        },
        removeBackupFile(tableName, filePath) {
            return apiClient.post(`${_xemsHost}/engineering/removeBackupFile`, { tableName, filePath });
        },

        // 관제점 테스트 - 특수용도..
        upsertTotalReal(totalReal) {
            return apiClient.post(xemsHost() + `/engineering/upsertTotalReal`, totalReal);
        },
        upsertRandomLog(ptAddr, ptType, min, max, sDt, eDt, intervalMin, isSort, isAllPoint) {
            return apiClient.get(xemsHost() + `/engineering/upsertRandomLog`, {
                params: { ptAddr, ptType, min, max, sDt, eDt, intervalMin, isSort, isAllPoint },
            });
        },

        searchStrings() {
            return apiClient.get(`${_xemsHost}/engineering/searchStrings`);
        },
        getStringSet(stringKey) {
            return apiClient.get(`${_xemsHost}/engineering/getStringSet`, { params: { stringKey } });
        },
        updateStrings(data) {
            return apiClient.post(`${_xemsHost}/engineering/updateStrings`, data);
        },

        syncGit(dir, master, prj, uid, pwd) {
            return apiClient.get(`${_xemsHost}/engineering/syncGit`, { params: { dir, master, prj, uid, pwd } });
        },
        buildFrontEnd(dir, master, prj) {
            return apiClient.get(`${_xemsHost}/engineering/buildFrontEnd`, { params: { dir, master, prj } });
        },
        startBackEnd(dir, master) {
            return apiClient.get(`${_xemsHost}/engineering/startBackEnd`, { params: { dir, master } });
        },

        syncDb(conn) {
            return apiClient.post(`${_xemsHost}/engineering/syncDb`, conn);
        },
        initGroup(masterCode, isClear) {
            return apiClient.post(`${_xemsHost}/engineering/initGroup`, { masterCode, isClear });
        },
        initDriver(isClear) {
            return apiClient.post(`${_xemsHost}/engineering/initDriver`, { isClear });
        },
        initPoint(masterCode, isClear) {
            return apiClient.post(`${_xemsHost}/engineering/initPoint`, { masterCode, isClear });
        },
        getDbVariables() {
            return apiClient.get(`${_xemsHost}/engineering/getDbVariables`);
        },
        getDbStatus() {
            return apiClient.get(`${_xemsHost}/engineering/getDbStatus`);
        },
        setMaxConnection(maxConnection) {
            return apiClient.post(`${_xemsHost}/engineering/setMaxConnection`, { maxConnection });
        },
        setLockTimeout(lockTimeout) {
            return apiClient.post(`${_xemsHost}/engineering/setLockTimeout`, { lockTimeout });
        },
        setWaitTimeout(waitTimeout) {
            return apiClient.post(`${_xemsHost}/engineering/setWaitTimeout`, { waitTimeout });
        },
    },
    client: {
        restartAgent(clientIdx) {
            return apiClient.post(`${_xemsHost}/client/restartAgent`, { clientIdx });
        },
        searchClient() {
            return apiClient.get(`${_xemsHost}/client/searchClient`);
        },
        getClientByServerIdx(serverIdx) {
            return apiClient.get(`${_xemsHost}/client/getClientByServerIdx`, { params: { serverIdx } });
        },
        applyClient(data) {
            return apiClient.post(`${_xemsHost}/client/applyClient`, data); // { serverIdx, oldClientIdx, newClientIdx }
        },
    },
    // board
    // bbs: {
    //   searchBbs(searchText) {
    //     return apiClient.get(xemsHost() + `/bbs/searchBbs`, { params: { searchText } });
    //   },
    //   getBbs(bbsIdx) {
    //     return apiClient.get(xemsHost() + `/bbs/getBbs`, { params: { bbsIdx } });
    //   },
    //   insertBbs(bbs) {
    //     return apiClient.post(xemsHost() + `/bbs/insertBbs`, bbs);
    //   },
    //   updateBbs(bbs) {
    //     return apiClient.post(xemsHost() + `/bbs/updateBbs`, bbs);
    //   },
    //   deleteBbs(bbsIdx) {
    //     return apiClient.get(xemsHost() + `/bbs/deleteBbs`, { params: { bbsIdx } });
    //   },
    // },
    // board: {
    //   searchBoard(bbsIdx, subject, content) {
    //     return apiClient.get(xemsHost() + `/board/searchBoard`, { params: { bbsIdx, subject, content } });
    //   },
    //   getBoard(bbsIdx, boardIdx) {
    //     return apiClient.get(xemsHost() + `/board/getBoard`, { params: { bbsIdx, boardIdx } });
    //   },
    //   deleteBoard(bbsIdx, boardIdx) {
    //     return apiClient.get(xemsHost() + `/board/deleteBoard`, { params: { bbsIdx, boardIdx } });
    //   },
    //   insertBoardWithFiles(board, files) {
    //     let formData = new FormData();
    //     formData.append("board", JSON.stringify(board));

    //     for (let file of files) {
    //       formData.append(`attachments`, file);
    //     }

    //     return apiClient.post(xemsHost() + `/board/insertBoardWithFiles`, formData, { headers: { "Content-Type": "multipart/form-data" } });
    //   },
    //   updateBoardWithFiles(board, files) {
    //     let formData = new FormData();
    //     formData.append("board", JSON.stringify(board));

    //     for (let file of files) {
    //       formData.append("attachments", file);
    //     }

    //     return apiClient.post(xemsHost() + `/board/updateBoardWithFiles`, formData, { headers: { "Content-Type": "multipart/form-data" } });
    //   },
    //   insertComment(comment) {
    //     return apiClient.post(xemsHost() + `/board/insertComment`, comment);
    //   },
    //   updateComment(comment) {
    //     return apiClient.post(xemsHost() + `/board/updateComment`, comment);
    //   },
    //   deleteComment(boardIdx, commentIdx) {
    //     return apiClient.get(xemsHost() + `/board/deleteComment`, { params: { boardIdx, commentIdx } });
    //   },
    //   deleteFile(boardIdx, fileIdx) {
    //     return apiClient.get(xemsHost() + `/board/deleteFile`, { params: { boardIdx, fileIdx } });
    //   },
    //   downloadFile(boardIdx, fileIdx, fileName) {
    //     return axios({ url: xemsHost() + `/board/downloadFile?boardIdx=${boardIdx}&fileIdx=${fileIdx}`, method: "GET", responseType: "blob" }).then((response) => {
    //       const url = window.URL.createObjectURL(new Blob([response.data]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", fileName);
    //       document.body.appendChild(link);
    //       link.click();
    //     });
    //   },
    // },
    // Facility
    server: {
        // searchServer(searchText) {
        //   return apiClient.get(xemsHost() + `/server/searchServer`, { params: { searchText } });
        // },
        getServer(serverIdx) {
            return apiClient.get(xemsHost() + `/server/getServer`, { params: { serverIdx } });
        },
        insertServer(server) {
            return apiClient.post(xemsHost() + `/server/insertServer`, server);
        },
        updateServer(server) {
            return apiClient.post(xemsHost() + `/server/updateServer`, server);
        },
        deleteServer(serverIdx) {
            return apiClient.get(xemsHost() + `/server/deleteServer`, { params: { serverIdx } });
        },
        // searchServerLogStatus(serverIdx, sDt, eDt, max) {
        // 사용중인곳 없음
        //   return apiClient.get(xemsHost() + `/server/searchServerLogStatus`, { params: { serverIdx, sDt, eDt, max } });
        // },
        // deleteServerLog(serverIdx, sDt, eDt) {
        // 사용중인곳 없음
        // return apiClient.get(xemsHost() + `/server/deleteServerLog`, { params: { serverIdx, sDt, eDt } });
        // },
        // deleteServerLogByPeriod(sDt, eDt) {
        //   return apiClient.get(xemsHost() + `/server/deleteServerLogByPeriod`, { params: { sDt, eDt } });
        // },
    },
    serverLog: {},
    driver: {
        searchDriver(driverName) {
            return apiClient.get(xemsHost() + `/driver/searchDriver`, { params: { driverName } });
        },
        getDriver(driverType, version) {
            return apiClient.get(xemsHost() + `/driver/getDriver`, { params: { driverType, version } });
        },
        getDriverProp(driverType, version) {
            return apiClient.get(xemsHost() + `/driver/getDriverProp`, { params: { driverType, version } });
        },
        registDriver(isSync) {
            return apiClient.get(xemsHost() + `/driver/registDriver`, { params: { isSync } });
        },
        updateDriverProp(driverProp) {
            return apiClient.post(xemsHost() + `/driver/updateDriverProp`, driverProp);
        },
    },
    channel: {
        // searchChannel(channelName) {
        //   return apiClient.get(xemsHost() + `/channel/searchChannel`, { params: { channelName } });
        // },
        // searchChannelByServerIdx(serverIdx) {
        //   return apiClient.get(xemsHost() + `/channel/searchChannelByServerIdx`, { params: { serverIdx } });
        // },
        getChannel(channelIdx) {
            return apiClient.get(xemsHost() + `/channel/getChannel`, { params: { channelIdx } });
        },
        insertChannel(channel) {
            return apiClient.post(xemsHost() + `/channel/insertChannel`, channel);
        },
        updateChannel(channel) {
            return apiClient.post(xemsHost() + `/channel/updateChannel`, channel);
        },
        deleteChannel(channelIdx) {
            return apiClient.delete(xemsHost() + `/channel/deleteChannel`, { params: { channelIdx } });
        },
    },
    facility: {
        searchFacility(facilityName) {
            return apiClient.get(xemsHost() + `/facility/searchFacility`, { params: { facilityName } });
        },
        // searchFacilityForChannel(serverIdx, channelIdx) {
        //   return apiClient.get(xemsHost() + `/facility/searchFacilityForChannel`, { params: { serverIdx, channelIdx } });
        // },
        getFacility(facilityIdx) {
            return apiClient.get(xemsHost() + `/facility/getFacility`, { params: { facilityIdx } });
        },
        getFacilityByPtAddr(ptAddr) {
            return apiClient.get(xemsHost() + `/facility/getFacilityByPtAddr`, { params: { ptAddr } });
        },
        insertFacility(facility) {
            return apiClient.post(xemsHost() + `/facility/insertFacility`, facility);
        },
        updateFacility(facility) {
            return apiClient.post(xemsHost() + `/facility/updateFacility`, facility);
        },
        deleteFacility(facilityIdx) {
            return apiClient.delete(xemsHost() + `/facility/deleteFacility`, { params: { facilityIdx } });
        },
        searchFacilityTree() {
            return apiClient.get(xemsHost() + `/facility/searchFacilityTree`);
        },
    },
    facilityMap: {
        searchFacilityMap(facilityIdx) {
            return apiClient.get(xemsHost() + `/facilityMap/searchFacilityMap`, { params: { facilityIdx } });
        },
        searchFacilityMapWithPtAddr(facilityIdx) {
            return apiClient.get(xemsHost() + `/facilityMap/searchFacilityMapWithPtAddr`, { params: { facilityIdx } });
        },
        getFacilityMapByPtAddr(ptAddr) {
            return apiClient.get(xemsHost() + `/facilityMap/getFacilityMapByPtAddr`, { params: { ptAddr } });
        },
        // insertFacilityMap(facilityMap) {
        //   return apiClient.post(xemsHost() + `/facilityMap/insertFacilityMap`, facilityMap);
        // },
        // deleteFacilityMapList(facilityMap) {
        //   return apiClient.delete(xemsHost() + `/facilityMap/deleteFacilityMapList`, facilityMap);
        // },
    },
    // analysis
    visual: {
        // 대시보드 설정 가져오기 - 뷰셋 목록을 조회 - 20.03.11 man
        searchViewSet() {
            // 결과 예시
            // [{"name":"homeplus-absorption-chiller02.json","size":8595,"birth":"2020-2-11 16:14:19","modefied":"2020-2-11 16:14:19"}]
            // name: 뷰셋 이름
            // size: 파일 크기
            // birth: 생성일자
            // modefied: 마지막 수정일자
            return apiClient.get(`${_xemsHost}/visual/searchViewSet`);
        },
        getViewSet(viewCode) {
            return apiClient.get(`${_xemsHost}/visual/getViewSet`, { params: { viewCode } });
        },
        saveViewSet(data) {
            return apiClient.post(`${_xemsHost}/visual/saveViewSet`, data);
        },
        saveAsViewSet(data) {
            return apiClient.post(`${_xemsHost}/visual/saveAsViewSet`, data);
        },
        deleteViewSet(data) {
            return apiClient.post(`${_xemsHost}/visual/deleteViewSet`, data);
        },
        // 분석템플릿
        searchVisualTemplate() {
            // TODO: this.cancelSource 코딩 이상함. 첫번째 할당하고 완료되지 않은 상태에서 두번째 호출이 된다면?
            // if (this.cancelSource != null) this.cancelSource.cancel("Operation canceled by the user.");
            // else this.cancelSource = cancelToken;
            return apiClient.get(`${_xemsHost}/visual/searchVisualTemplate`);
        },
        getVisualTemplate(templateCode) {
            return apiClient.get(`${_xemsHost}/visual/getVisualTemplate`, { params: { templateCode } });
        },
        saveVisualTemplate(data) {
            //console.log("saveVisualTemplate");
            return apiClient.post(`${_xemsHost}/visual/saveVisualTemplate`, data);
        },
        saveTemplateToVisual(data) {
            return apiClient.post(`${_xemsHost}/visual/saveTemplateToVisual`, data);
        },
        deleteVisualTemplate(data) {
            return apiClient.post(`${_xemsHost}/visual/deleteVisualTemplate`, data);
        },
        // Realtime html
        getRealtime(fileName) {
            return apiClient.get(`${_xemsHost}/visual/getRealtime`, { params: { fileName } });
        },
        // getRealtimeList() {
        //   return apiClient.get(`${_xemsHost}/visual/getRealtimeList`);
        // },
    },
    // point
    point: {
        searchPoint(searchText) {
            return apiClient.get(xemsHost() + `/point/searchPoint`, { params: { searchText } });
        },
        searchPointGroup() {
            return apiClient.get(xemsHost() + `/point/searchPointGroup`);
        },
        searchPointAM() {
            return apiClient.get(xemsHost() + `/point/searchPointAM`);
        },
        searchPointIM() {
            return apiClient.get(xemsHost() + `/point/searchPointIM`);
        },
        getPoint(ptAddr) {
            return apiClient.get(xemsHost() + `/point/getPoint`, { params: { ptAddr } });
        },
        insertPoint(data) {
            return apiClient.post(xemsHost() + `/point/insertPoint`, data);
        },
        bulkInsertPoint(data) {
            return apiClient.post(xemsHost() + `/point/bulkInsertPoint`, data);
        },
        insertPointAndFacilityMap(data) {
            //console.log("backEndApi insertPointAndFacilityMap", data);

            return apiClient.post(xemsHost() + `/point/insertPointAndFacilityMap`, data);
        },
        updatePoint(data) {
            return apiClient.post(xemsHost() + `/point/updatePoint`, data);
        },
        updatePointAndFacilityMap(data) {
            return apiClient.post(xemsHost() + `/point/updatePointAndFacilityMap`, data);
        },
        deletePoint(ptAddr) {
            return apiClient.get(xemsHost() + `/point/deletePoint`, { params: { ptAddr } });
        },
        // syncPoint() {
        //   return apiClient.get(xemsHost() + `/point/syncPoint`);
        // },
        // clearPoint() {
        //   return apiClient.get(xemsHost() + `/point/clearPoint`);
        // },
        // total real
        searchTotalReal(ptAddr) {
            return apiClient.get(xemsHost() + `/point/searchTotalReal`, { params: { ptAddr } });
        },
        // total real
        searchTotalRealList() {
            return apiClient.get(xemsHost() + `/point/searchTotalRealList`);
        },
        // Network.vue point remove
        deletePointAndFacilityMap(data) {
            return apiClient.post(xemsHost() + `/point/deletePointAndFacilityMap`, data);
        },
        getPointList() {
            return apiClient.get(xemsHost() + `/point/getPointList`);
        },

        getPointLog(data) {
            let param =
                "?ptAddr=" +
                data.ptAddr +
                "&timeDsvn=" +
                data.timeDsvn +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate;

            return apiClient.get(xemsHost() + `/point/getPointLog` + param);
        },
    },
    alarm: {
        // 알람
        searchAlarm() {
            return apiClient.get(xemsHost() + `/alarm/searchAlarm`);
        },
        getAlarm(alarmGroup, ptAddr) {
            return apiClient.get(xemsHost() + `/alarm/getAlarm`, { params: { alarmGroup, ptAddr } });
        },
        getAlarmList(ptAddr) {
            return apiClient.get(xemsHost() + `/alarm/getAlarmList`, { params: { ptAddr } });
        },
        getAlarmGroups() {
            return apiClient.get(xemsHost() + `/alarm/getAlarmGroups`);
        },
        isDuplicateAlarm(alarmGroup, ptAddr) {
            return apiClient.get(xemsHost() + `/alarm/isDuplicateAlarm`, { params: { alarmGroup, ptAddr } });
        },
        insertAlarm(alarm) {
            return apiClient.post(xemsHost() + `/alarm/insertAlarm`, alarm);
        },
        updateAlarm(alarm) {
            return apiClient.post(xemsHost() + `/alarm/updateAlarm`, alarm);
        },
        updateAlarmHold(alarmGroup, ptAddr, isHold) {
            return apiClient.get(xemsHost() + `/alarm/updateAlarmHold`, { params: { alarmGroup, ptAddr, isHold } });
        },
        deleteAlarm(alarmGroup, ptAddr) {
            return apiClient.delete(xemsHost() + `/alarm/deleteAlarm`, { params: { alarmGroup, ptAddr } });
        },
        // 알람로그 - 메인화면 - 알람위젯 + 헤더 - 알람공지
        searchAlarmLog(sDt, eDt, alarmGroup, searchText, max) {
            return apiClient.get(xemsHost() + `/alarm/searchAlarmLog`, {
                params: { sDt, eDt, alarmGroup, searchText, max },
            });
        },
        getAlarmLog(alarmLogIdx) {
            return apiClient.get(xemsHost() + `/alarm/getAlarmLog`, { params: { alarmLogIdx } });
        },
        insertAlarmTest(alarmGroup, ptAddr, ptVal) {
            return apiClient.get(xemsHost() + `/alarm/insertAlarmTest`, { params: { alarmGroup, ptAddr, ptVal } });
        },
    },
    notification: {
        searchAlarmNotification(max) {
            return apiClient.get(xemsHost() + `/notification/searchAlarmNotification`, { params: { max } });
        },
    },
    live: {
        start() {
            return apiClient.get(`${_xemsHost}/live/start`);
        },
        stop() {
            return apiClient.get(`${_xemsHost}/live/stop`);
        },
        interpolationLast(sDt, eDt, points) {
            return apiClient.get(`${_xemsHost}/live/interpolationLast`, { params: { sDt, eDt, points } });
        },
        reSampling(sDt, eDt, points, options) {
            return apiClient.post(`${_xemsHost}/live/reSampling`, { sDt, eDt, points, options });
        },
        runSummary(timeType, sDt, eDt, points, options) {
            return apiClient.post(xemsHost() + `/live/runSummary`, { timeType, sDt, eDt, points, options });
        },
        runSummaryQuery(timeType, sDt, eDt, points) {
            return apiClient.get(xemsHost() + `/live/runSummaryQuery`, { params: { timeType, sDt, eDt, points } });
        },
    },
    workflow: {
        searchWorkgroups() {
            return apiClient.get(`${_xemsHost}/workflow/searchWorkgroups`);
        },
        searchAllJobs() {
            return apiClient.get(`${_xemsHost}/workflow/searchAllJobs`);
        },
        saveWorkgroups(saveWorkflow) {
            return apiClient.post(`${_xemsHost}/workflow/saveWorkgroups`, saveWorkflow);
        },
        createWorkflow(workflow) {
            return apiClient.post(`${_xemsHost}/workflow/createWorkflow`, workflow);
        },
        saveWorkflow(workflow) {
            return apiClient.post(`${_xemsHost}/workflow/saveWorkflow`, workflow);
        },
        saveAsWorkflow(workflowName, newWorkflowName) {
            return apiClient.post(`${_xemsHost}/workflow/saveAsWorkflow`, { workflowName, newWorkflowName });
        },
        deleteWorkflow(workflowName) {
            return apiClient.post(`${_xemsHost}/workflow/deleteWorkflow`, { workflowName });
        },
        recommenWorkflow(workflowName) {
            return apiClient.get(`${_xemsHost}/workflow/recommenWorkflow?ptAddr=${workflowName}`);
        },
    },
    totalLog: {
        getSamplingLog(liveDate) {
            liveDate = liveDate.format("yyyyMMddHHmm");
            return apiClient.get(xemsHost() + `/totalLog/getSamplingLog`, { params: { liveDate } });
        },
        searchTotalLog(ptAddr, sDt, eDt, max) {
            return apiClient.get(xemsHost() + `/totalLog/searchTotalLog`, { params: { ptAddr, sDt, eDt, max } });
        },
        // upsertTotalLogs(data) {
        //   return apiClient.post(xemsHost() + `/totalLog/upsertTotalLogs`, data);
        // },
    },
    summary: {
        // 대시보드 데이터 조회하기
        searchData(conds, ignoreCache) {
            if (!Array.isArray(conds)) conds = [conds];

            return apiClient
                .post(`${_xemsHost}/summary/searchData?ignoreCache=${ignoreCache || ""}`, conds)
                .then((res) => {
                    return global.xe.PtLib.changeDateFormat(res);
                });
        },
        searchDataWithToken(conds, ignoreCache, cancelSource) {
            if (!Array.isArray(conds)) conds = [conds];

            // TODO : searchData, searchDataWithToken 둘중하나 정리해야 함.

            return apiClient
                .post(`${_xemsHost}/summary/searchData?ignoreCache=${ignoreCache}`, conds, {
                    cancelToken: cancelSource.token,
                })
                .then((res) => {
                    return global.xe.PtLib.changeDateFormat(res);
                });
        },
        // WorkglowMgr.run History
        searchSummary1MinNow(now) {
            now = now.format("yyyyMMddHHmm");
            return apiClient.get(xemsHost() + `/summary/searchSummary1MinNow`, { params: { now } });
        },
        // PointLog
        searchSummaryByTimeUnit(ptIdx, type, sDt, eDt) {
            return apiClient.get(xemsHost() + `/summary/searchSummaryByTimeUnit`, {
                params: { ptIdx, type, sDt, eDt },
            });
        },
        // summary 1min
        searchSummary1Min(ptIdx, sDt, eDt) {
            return apiClient.get(xemsHost() + `/summary/searchSummary1Min`, { params: { ptIdx, sDt, eDt } });
        },
        // searchSummaryWatch
        searchSummaryWatch(regDt) {
            return apiClient.get(`${_xemsHost}/summary/searchSummaryWatch`, { params: { regDt } });
        },
        searchSummaryWatchTimes(ptAddr, sDt, eDt) {
            return apiClient.get(`${_xemsHost}/summary/searchSummaryWatchTimes`, { params: { ptAddr, sDt, eDt } });
        },
        // getSummaryWatch
        // getSummaryWatch(regDt, ptIdx) {
        //   return apiClient.get(`${_xemsHost}/summary/getSummaryWatch`, { params: { regDt, ptIdx } });
        // },
        // setSummaryWatch
        // setSummaryWatch(summaryWatch) {
        //   return apiClient.get(`${_xemsHost}/summary/setSummaryWatch`, { params: { summaryWatch } });
        // },

        getLogTableStatus(sDt, eDt, tableName) {
            return apiClient.get(xemsHost() + `/summary/getLogTableStatus`, { params: { sDt, eDt, tableName } });
        },
        updateLogTableStatus(sDt, eDt, tableName) {
            return apiClient.get(xemsHost() + `/summary/updateLogTableStatus`, { params: { sDt, eDt, tableName } });
        },
        clearSummary(sDt, eDt, tableName) {
            return apiClient.post(xemsHost() + `/summary/clearSummary`, { sDt, eDt, tableName });
        },
    },
    outputLog: {
        insertOutputLog(outputLog) {
            return apiClient.post(xemsHost() + `/outputLog/insertOutputLog `, outputLog);
        },
        equipContrl(data) {
            return apiClient.post(xemsHost() + `/outputLog/equipContrl`, data);
        },
    },
    // operation
    sync: {
        searchSync() {
            return apiClient.get(`${_xemsHost}/sync/searchSync`);
        },
        getSyncMap(syncCode, category) {
            return apiClient.get(`${_xemsHost}/sync/getSyncMap`, { params: { syncCode, category } });
        },
    },
    goal: {
        searchGoals() {
            return apiClient.get(xemsHost() + `/goal/searchGoals`);
        },
        getGoals(goalType, year) {
            return apiClient.get(xemsHost() + `/goal/getGoals`, { params: { goalType, year } });
        },
        upsertGoals(goals) {
            return apiClient.post(xemsHost() + `/goal/upsertGoals`, goals);
        },
        syncGoal(goals) {
            return apiClient.post(xemsHost() + `/goal/syncGoal`, goals);
        },
    },
    baseline: {
        searchBaselines(baselineType) {
            return apiClient.get(xemsHost() + `/baseline/searchBaselines`, { params: { baselineType } });
        },
        // getBaselines(baselineType, year) {
        //   return apiClient.get(xemsHost() + `/baseline/getBaselines`, { params: { baselineType, year } });
        // },
        upsertBaselines(org, baselines) {
            return apiClient.post(xemsHost() + `/baseline/upsertBaselines`, { org, baselines });
        },
        deleteBaseline(baselineType, ptIdx, year) {
            return apiClient.get(xemsHost() + `/baseline/deleteBaseline`, { params: { baselineType, ptIdx, year } });
        },
        syncBaseline(baselines) {
            return apiClient.post(xemsHost() + `/baseline/syncBaseline`, baselines);
        },
    },
    // charge: {
    //   searchCharges() {
    //     return apiClient.get(xemsHost() + `/charge/searchCharges`);
    //   },
    //   searchChargesByChargeType(chargeType) {
    //     return apiClient.get(xemsHost() + `/charge/searchChargesByChargeType`, { params: { chargeType } });
    //   },
    //   getCharge(chargeIdx) {
    //     return apiClient.get(xemsHost() + `/charge/getCharge`, { params: { chargeIdx } });
    //   },
    //   searchChargeBasis(chargeIdx) {
    //     return apiClient.get(xemsHost() + `/charge/searchChargeBasis`, { params: { chargeIdx } });
    //   },
    //   getChargeBasis(chargeBasisIdx) {
    //     return apiClient.get(xemsHost() + `/charge/getChargeBasis`, { params: { chargeBasisIdx } });
    //   },
    //   getChargePrices(chargeIdx, chargeBasisIdx) {
    //     return apiClient.get(xemsHost() + `/charge/getChargePrices`, { params: { chargeIdx, chargeBasisIdx } });
    //   },
    //   insertCharge(charge) {
    //     return apiClient.post(xemsHost() + `/charge/insertCharge`, charge);
    //   },
    //   deleteCharge(chargeIdx) {
    //     return apiClient.post(xemsHost() + `/charge/deleteCharge`, chargeIdx);
    //   },
    //   bulkInsertChargeTime(data) {
    //     return apiClient.post(xemsHost() + `/charge/bulkInsertChargeTime`, data);
    //   },
    //   getChargeTime(chargeIdx, chargeBasisIdx) {
    //     return apiClient.get(xemsHost() + `/charge/getChargeTime`, { params: { chargeIdx, chargeBasisIdx } });
    //   },
    //   insertChargeBasis(basis) {
    //     return apiClient.post(xemsHost() + `/charge/insertChargeBasis`, basis);
    //   },
    //   updateChargeBasis(basis) {
    //     return apiClient.post(xemsHost() + `/charge/updateChargeBasis`, basis);
    //   },
    //   deleteChargeBasis(chargeBasisIdx) {
    //     return apiClient.post(xemsHost() + `/charge/deleteChargeBasis`, chargeBasisIdx);
    //   },
    //   updateChargePrice(price) {
    //     return apiClient.post(xemsHost() + `/charge/updateChargePrice`, price);
    //   },
    //   bulkInsertChargePrices(prices) {
    //     return apiClient.post(xemsHost() + `/charge/bulkInsertChargePrices`, prices);
    //   },
    //   isDuplicateDates(basis) {
    //     return apiClient.post(xemsHost() + `/charge/isDuplicateDates`, basis);
    //   },
    //   syncCharge(chargeIdx, chargeBasisIdx) {
    //     return apiClient.post(xemsHost() + `/charge/syncCharge`, { chargeIdx, chargeBasisIdx });
    //   },
    // },
    payment: {
        searchPayments() {
            return apiClient.get(xemsHost() + `/payment/searchPayments`);
        },
        getPayments(paymentType, year) {
            return apiClient.get(xemsHost() + `/payment/getPayments`, { params: { paymentType, year } });
        },
        insertPayments(payments) {
            return apiClient.post(xemsHost() + `/payment/insertPayments`, payments);
        },
        syncPayment(payments) {
            return apiClient.post(xemsHost() + `/payment/syncPayment`, payments);
        },
    },
    weather: {
        searchWeather(forecastDt, countryCode, cityCode) {
            return apiClient.get(xemsHost() + `/weather/searchWeatherByPeriod`, {
                params: { forecastDt, countryCode, cityCode },
            });
        },
        searchWeatherByPeriod(countryCode, cityCode, sDt, eDt) {
            return apiClient.get(xemsHost() + `/weather/searchWeatherByPeriod`, {
                params: { countryCode, cityCode, sDt, eDt },
            });
        },
        syncWeather(forecastDt, countryCode, cityCode) {
            //console.log("backEndApi.weather.syncWeather = ", forecastDt, countryCode, cityCode );
            return apiClient.get(`${_xemsHost}/weather/syncWeather`, { params: { forecastDt, countryCode, cityCode } });
        },
    },
    simulation: {
        requestSimulationTrain(trainData) {
            return apiClient.post(xemsHost() + `/simulation/requestSimulationTrain`, trainData);
        },
        // requestSimulationTask(task) {
        //   return apiClient.post(xemsHost() + `/simulation/requestSimulationTask`, task);
        // },
        // requestRunningTasks() {
        //   return apiClient.get(xemsHost() + `/simulation/requestRunningTasks`);
        // },
        // requestKillTask(simulationTaskIdx) {
        //   return apiClient.get(xemsHost() + `/simulation/requestKillTask`, { params: { simulationTaskIdx } });
        // },
        searchSimulation() {
            return apiClient.get(xemsHost() + `/simulation/searchSimulation`);
        },
        insertSimulation(simulation) {
            return apiClient.post(xemsHost() + `/simulation/insertSimulation`, simulation);
        },
    },
    // management
    master: {
        getMaster() {
            // const response = apiClient.get(xemsHost() + `/master/getMaster`);
            // response.then((res) => {
            //   console.log(`Get Master Response Status => ${res.status}, Message: ${JSON.stringify(res.data, null, 2)}`);
            // }, (err) => {
            //   console.log(`Get Master Error => ${err.status}`);
            // });
            return apiClient.get(xemsHost() + `/master/getMaster`);
        },
        updateMaster(master) {
            return apiClient.post(xemsHost() + `/master/updateMaster`, master);
        },
    },
    group: {
        searchGroup() {
            return apiClient.get(xemsHost() + `/group/searchGroup`);
        },
        getGroup(groupIdx) {
            return apiClient.get(xemsHost() + `/group/getGroup`, { params: { groupIdx } });
        },
        insertGroup(group) {
            return apiClient.post(xemsHost() + `/group/insertGroup`, group);
        },
        updateGroup(group) {
            return apiClient.post(xemsHost() + `/group/updateGroup`, group);
        },
        deleteGroup(groupIdx, menuIdx) {
            return apiClient.delete(xemsHost() + `/group/deleteGroup`, { params: { groupIdx, menuIdx } });
        },

        // 그룹유저
        searchGroupUser(groupIdx) {
            return apiClient.get(xemsHost() + `/group/searchGroupUser`, { params: { groupIdx } });
        },
        insertGroupUser(groupUser) {
            return apiClient.post(xemsHost() + `/group/insertGroupUser`, groupUser);
        },
        deleteGroupUser(groupUser) {
            console.log(groupUser);
            return apiClient.delete(xemsHost() + `/group/deleteGroupUser`, {
                params: { groupIdx: groupUser.groupIdx, userId: groupUser.userId },
            });
        },
        // saveGroupUsers(groupIdx, selectedGroupUsers) {
        //   return apiClient.post(xemsHost() + `/group/saveGroupUsers`, { groupIdx, selectedGroupUsers });
        // },

        // 그룹메뉴
        searchGroupMenu(groupIdx) {
            return apiClient.get(xemsHost() + `/group/searchGroupMenu`, { params: { groupIdx } });
        },
        insertGroupMenu(groupMenu) {
            return apiClient.post(xemsHost() + `/group/insertGroupMenu`, groupMenu);
        },
        deleteGroupMenu(groupMenu) {
            return apiClient.post(xemsHost() + `/group/deleteGroupMenu`, groupMenu);
        },
        // saveGroupMenus(groupIdx, selectedGroupMenus) {
        //   return apiClient.post(xemsHost() + `/group/saveGroupMenus`, { groupIdx, selectedGroupMenus });
        // },
    },
    menu: {
        searchMenuTree() {
            return apiClient.get(xemsHost() + `/menu/searchMenuTree`);
        },
        searchMenuLoginUser(userId, targetClient) {
            return apiClient.get(xemsHost() + `/menu/searchMenuLoginUser`, { params: { userId, targetClient } });
        },
        insertMenu(menu) {
            return apiClient.post(xemsHost() + `/menu/insertMenu`, menu);
        },
        updateMenu(menu) {
            return apiClient.post(xemsHost() + `/menu/updateMenu`, menu);
        },

        upsertMenus(menus) {
            return apiClient.post(xemsHost() + `/menu/upsertMenus`, menus);
        },
        deleteMenu(menuIdx) {
            return apiClient.delete(xemsHost() + `/menu/deleteMenu`, { params: { menuIdx } });
        },
    },
    user: {
        searchUser(userId, userName) {
            return apiClient.get(xemsHost() + `/user/searchUser`, { params: { userId, userName } });
        },
        getUser(userId) {
            return apiClient.get(xemsHost() + `/user/getUser`, { params: { userId } });
        },
        getLoginInfo(userId, targetClient) {
            return apiClient.post(xemsHost() + `/user/getLoginInfo`, { userId, targetClient });
        },
        async insertUser(user) {
            user = {
                enc: global.xe.encryptObj(user),
            };

            return apiClient.post(xemsHost() + `/user/insertUser`, user);
        },
        updateUser(user) {
            return apiClient.post(xemsHost() + `/user/updateUser`, user);
        },
        async changeUserPwd(changePwd) {
            // console.log(changePwd);
            changePwd.masterCode = process.env.VUE_APP_masterCode;

            changePwd = {
                enc: global.xe.encryptObj(changePwd),
            };

            return apiClient.post(xemsHost() + `/user/changeUserPwd`, changePwd);
        },
        deleteUser(userId) {
            return apiClient.get(xemsHost() + `/user/deleteUser`, { params: { userId } });
        },
    },
    // userRecommend: {
    //   searchUserRecommend(userId, userName) {
    //     return apiClient.get(xemsHost() + `/userRecommend/searchUserRecommend`, { params: { userId, userName } });
    //   },
    //   getUserRecommend(userId) {
    //     return apiClient.get(xemsHost() + `/userRecommend/getUserRecommend`, { params: { userId } });
    //   },
    //   deleteUserRecommend(userId) {
    //     return apiClient.get(xemsHost() + `/userRecommend/deleteUserRecommend`, { params: { userId } });
    //   },
    //   confirmUser(userRecommend) {
    //     return apiClient.post(xemsHost() + `/userRecommend/confirmUser`, userRecommend);
    //   },
    // },
    login: {
        async getMasterGroup() {
            try {
                const result = await apiClient.get(xemsHost() + `/login/getMasterGroup`);
                return result;
            } catch (err) {
                console.log(`getMasterGroup => ${err}`);
            }
        },
        isDuplicateUerId(userId) {
            return apiClient.get(xemsHost() + `/login/isDuplicateUserId`, { params: { userId } });
        },
        isDuplicateEmail(email) {
            return apiClient.get(xemsHost() + `/login/isDuplicateEmail`, { params: { email } });
        },
        insertUserRecommend(userRecommend) {
            userRecommend = {
                enc: global.xe.encryptObj(userRecommend),
            };
            return apiClient.post(xemsHost() + `/login/insertUserRecommend`, userRecommend);
        },
        signin(user) {
            user = {
                enc: global.xe.encryptObj(user),
            };

            const response = apiClient.post(`${_xemsHost}/login/signin`, user);

            response.then(
                (res) => {
                    console.log(`$★★★ signin Data => ${res.data} ★★★`);
                    setAccessToken(res.data.accessToken);
                },
                (err) => {
                    console.info("Signin Error Message => ", err.response.data.error);
                    apiClient.defaults.headers.common["Authorization"] = null;
                }
            );

            return response;
        },
        signout(userId) {
            return apiClient.post(xemsHost() + `/login/signout`, { userId });
        },
        searchTrans(searchText) {
            return apiClient.get(xemsHost() + `/login/searchTrans`, { params: { searchText } });
        },
    },
    scheduleJob: {
        getJobs() {
            return apiClient.get(`${_xemsHost}/scheduleJob/getJobs`);
        },
        getJob(scheduleName) {
            return apiClient.get(`${_xemsHost}/scheduleJob/getJob`, { params: { scheduleName } });
        },
        runJob(scheduleName) {
            return apiClient.get(`${_xemsHost}/scheduleJob/runJob`, { params: { scheduleName } });
        },
        saveJob(scheduleJob) {
            return apiClient.post(`${_xemsHost}/scheduleJob/saveJob`, { scheduleJob });
        },
    },
    eventLog: {
        searchEventLog(sDt, eDt, eventType, appId, searchText, max) {
            return apiClient.get(xemsHost() + `/eventLog/searchEventLog`, {
                params: { sDt, eDt, eventType, appId, searchText, max },
            });
        },
        getEventLog(eventLogIdx) {
            return apiClient.get(xemsHost() + `/eventLog/getEventLog`, { params: { eventLogIdx } });
        },
        getEventTypes(sDt, eDt) {
            return apiClient.get(xemsHost() + `/eventLog/getEventTypes`, { params: { sDt, eDt } });
        },
        getAppIds(sDt, eDt) {
            return apiClient.get(xemsHost() + `/eventLog/getAppIds`, { params: { sDt, eDt } });
        },
        deleteEventLog(eventLogIdx) {
            return apiClient.delete(xemsHost() + `/eventLog/deleteEventLog`, { eventLogIdx });
        },
        deleteEventLogByPeriod(sDt, eDt) {
            return apiClient.delete(xemsHost() + `/eventLog/deleteEventLogByPeriod`, { params: { sDt, eDt } });
        },
    },
    trans: {
        getTrans(transKey, locale) {
            return apiClient.get(xemsHost() + `/trans/getTrans`, { params: { transKey, locale } });
        },
        insertTrans(trans) {
            return apiClient.post(xemsHost() + `/trans/insertTrans`, trans);
        },
        updateTrans(trans) {
            return apiClient.post(xemsHost() + `/trans/updateTrans`, trans);
        },
        deleteTrans(transKey, locale) {
            return apiClient.get(xemsHost() + `/trans/deleteTrans`, { params: { transKey, locale } });
        },

        searchTransByResourceKey(resourceType, resourceKey) {
            return apiClient.get(xemsHost() + `/trans/searchTransByResourceKey`, {
                params: { resourceType, resourceKey },
            });
        },
        upsertTransByResourceKey(resourceType, resourceKey, transArr) {
            return apiClient.post(xemsHost() + `/trans/upsertTransByResourceKey`, {
                resourceType,
                resourceKey,
                transArr,
            });
        },
        deleteTransByResourceKey(resourceType, resourceKey) {
            return apiClient.get(xemsHost() + `/trans/deleteTransByResourceKey`, {
                params: { resourceType, resourceKey },
            });
        },
    },
    widgetExtend: {
        targetConsumpt() {
            return apiClient.get(xemsHost() + "/widgetExtend/targetConsumpt");
        },
    },

    widget: {
        widgetPeakData(zoneIdx) {
            let result = null;

            // zoneIdx 전체일시 null 변경
            // 구역이 포함 안될 시 전체로 조회
            if (zoneIdx == 1) {
                zoneIdx = null;
            }

            if (!zoneIdx) {
                result = apiClient.get(xemsHost() + `/widgetExtend/powerPeak`);
            } else {
                result = apiClient.get(xemsHost() + `/widgetExtend/powerPeak?zoneIdx=` + zoneIdx);
            }
            return result;
        },
        widgetConsumptData(zoneIdx) {
            let result = null;

            // zoneIdx 전체일시 null 변경
            // 구역이 포함 안될 시 전체로 조회
            if (zoneIdx == 1) {
                zoneIdx = null;
            }

            if (!zoneIdx) {
                result = apiClient.get(xemsHost() + `/widgetExtend/compareConsumpt`);
            } else {
                result = apiClient.get(xemsHost() + `/widgetExtend/compareConsumpt?zoneIdx=` + zoneIdx);
            }
            return result;
        },
        selfRenceRate(zoneIdx) {
            let result = null;

            // zoneIdx 전체일시 null 변경
            // 구역이 포함 안될 시 전체로 조회
            if (zoneIdx == 1) {
                zoneIdx = null;
            }

            if (!zoneIdx) {
                result = apiClient.get(xemsHost() + `/widgetExtend/selfRenceRate`);
            } else {
                result = apiClient.get(xemsHost() + `/widgetExtend/selfRenceRate?zoneIdx=` + zoneIdx);
            }
            return result;
        },
        selfRenceRateMonth(zoneIdx) {
            let result = null;

            // zoneIdx 전체일시 null 변경
            // 구역이 포함 안될 시 전체로 조회
            if (zoneIdx == 1) {
                zoneIdx = null;
            }

            if (!zoneIdx) {
                result = apiClient.get(xemsHost() + `/widgetExtend/selfRenceRateMonth`);
            } else {
                result = apiClient.get(xemsHost() + `/widgetExtend/selfRenceRateMonth?zoneIdx=` + zoneIdx);
            }
            return result;
        },
        selfRateByRegion() {
            let result = apiClient.get(xemsHost() + `/widgetExtend/selfRateByRegion`);

            return result;
        },
        irEvmnStatusInfo(equipIdx, equipTypeCode) {
            let result = apiClient.get(
                xemsHost() + `/widgetExtend/irEvmnStatusInfo?equipIdx=` + equipIdx + "&equipTypeCode=" + equipTypeCode
            );

            return result;
        },
        getWeatherCast() {
            let result = apiClient.get(xemsHost() + `/widgetExtend/getWeatherCast`);

            return result;
        },

        getCurrentWeather() {
            let result = apiClient.get(xemsHost() + `/weather/searchWeatherByDateTime`);

            return result;
        },

        /* 최대 전력 수요 추가 2023.02.14 권철규 */
        widgetPowerLoad(ptaddr) {
            return apiClient.get(xemsHost() + `/widgetExtend/powerLoad?ptAddr=` + ptaddr);
        },
    },

    equipInfo: {
        //설비 기기 정보 조회
        getEquipInfoList({ systemCode, itemUsage, zoneIdx, equipTypeCode, equipName, enabled }) {
            let querys = "";

            if (systemCode || itemUsage || zoneIdx || equipTypeCode || equipName || enabled) {
                querys = "?";
            }

            if (systemCode) {
                querys += "systemCode=" + systemCode + "&";
            }
            if (itemUsage) {
                querys += "equipUsageCode=" + itemUsage + "&";
            }
            if (zoneIdx) {
                querys += "zoneIdx=" + zoneIdx + "&";
            }
            if (equipTypeCode) {
                querys += "equipTypeCode=" + equipTypeCode + "&";
            }
            if (equipName) {
                querys += "searchStr=" + equipName + "&";
            }
            if (enabled) {
                querys += "enabled=" + enabled + "&";
            }

            let result = apiClient.get(xemsHost() + `/equipInfo/getEquipInfoList${querys}`);

            return result;
        },
        // made by woonsik 설비 기기 조회
        getEquipList(equipType, zoneIdx) {
            let params = { equipType, zoneIdx };
            let filtered = JSON.parse(JSON.stringify(params, (key, value) => value ?? undefined));

            let result = apiClient.get(xemsHost() + `/new/equip/getEquipList`, { params: { filtered } });

            return result;
        },

        // 설비 기기 상제 조회
        getEquipDetailInfo(equipIdx) {
            let result = apiClient.get(xemsHost() + `/equipInfo/getEquipInfo?equipIdx=${equipIdx}`);

            return result;
        },
        // 상위 설비 기기 조회
        getUpperEquipList(upperEquipTypeCode) {
            let result = apiClient.get(
                xemsHost() + `/equipInfo/getUpperEquipList?upperEquipTypeCode=${upperEquipTypeCode}`
            );

            return result;
        },
        // 설비 기기 그룹 조회
        getEquipGroupList(equipTypeCode) {
            let result = apiClient.get(xemsHost() + `/equipInfo/getEquipGroupList`, { params: { equipTypeCode } });

            return result;
        },
        getEquipGrpList(equipTypeCode, zoneIdx) {
            let result = apiClient.get(xemsHost() + `/equipInfo/getEquipGroupList`, {
                params: { equipTypeCode, zoneIdx },
            });

            return result;
        },
        // 설비 기기 등록
        insertEquipInfo(equipInfo) {
            // systemCode 계통코드
            // equipName 설비명
            // equipUsageCode 설비용도코드
            // equipTypeCode 설비유형코드
            // equipGrpIdx 설비그룹 일련번호
            // upperEquipIdx 상위설비 일련번호
            // equipProp 기타정보
            // zoneIdx 구역
            // equipLctn 설치좌표 (x좌표,y좌표)
            // enabled 사용여부

            let result = apiClient.post(xemsHost() + `/equipInfo/insertEquipInfo`, equipInfo);

            return result;
        },
        // 설비 기기 수정
        updateEquipInfo(equipInfo) {
            // equipIdx 설비 일련번호
            // systemCode 계통코드
            // equipName 설비명
            // equipUsageCode 설비용도코드
            // equipTypeCode 설비유형코드
            // equipGrpIdx 설비그룹 일련번호
            // upperEquipIdx 상위설비 일련번호
            // equipProp 기타정보
            // zoneIdx 구역
            // equipLctn 설치좌표 (x좌표,y좌표)
            // enabled 사용여부

            let result = apiClient.post(xemsHost() + `/equipInfo/updateEquipInfo`, equipInfo);

            return result;
        },
        // 설비 기기 삭제
        deleteEquipInfo(equipIdx) {
            let result = apiClient.delete(xemsHost() + `/equipInfo/deleteEquipInfo?equipIdx=${equipIdx}`);

            return result;
        },
        //설비 그룹 등록
        insertEquipGroup(groupInfo) {
            let result = apiClient.post(xemsHost() + `/equipInfo/insertEquipGroup`, groupInfo);

            return result;
        },
    },
    equipCtrl: {
        getEquipCtrlList(equipTypeCode) {
            let result = apiClient.get(xemsHost() + `/equipCtrl/getEquipCtrlList?equipTypeCode=${equipTypeCode}`);

            return result;
        },
        getEquipCtrl(equipTypeCode, ctrlCode) {
            let result = apiClient.get(
                xemsHost() + `/equipCtrl/getEquipCtrl?equipTypeCode=${equipTypeCode}&ctrlCode=${ctrlCode}`
            );

            return result;
        },
        insertEquipCtrl(param) {
            let result = apiClient.post(xemsHost() + `/equipCtrl/insertEquipCtrl`, param);

            return result;
        },
        updateEquipCtrl(param) {
            let result = apiClient.post(xemsHost() + `/equipCtrl/updateEquipCtrl`, param);

            return result;
        },

        deleteEquipCtrl(equipTypeCode, ctrlCode) {
            let query = "";

            if (equipTypeCode || ctrlCode) {
                query = "?";
            }

            if (equipTypeCode) {
                query += `equipTypeCode=${equipTypeCode}&`;
            }

            if (ctrlCode) {
                query += `ctrlCode=${ctrlCode}&`;
            }

            let result = apiClient.delete(xemsHost() + `/equipCtrl/deleteEquipCtrl${query}`);

            return result;
        },
    },
    equipMap: {
        // 계통-설비 계층 구조 조회
        getEquipSystemList() {
            let result = apiClient.get(xemsHost() + "/equipMap/getEquipSystemList");

            return result;
        },
        // 표준관제점 내역 주회
        getEquipMapList(equipIdx, dvsnCode) {
            let result = apiClient.get(
                xemsHost() + `/equipMap/getEquipMapList?equipIdx=${equipIdx}&dvsnCode=${dvsnCode}`
            );

            return result;
        },
        //설비 매핑 관제점 조회
        getPointListByMap() {
            let result = apiClient.get(xemsHost() + `/equipMap/getPointListByMap`);

            return result;
        },
        //표준 관제 매핑 내역 등록/수정
        insertEquipMap(equipInfo) {
            /**
             * NOTE - equipInfo 정보
             * equipIdx 설비기기(그룹) 번호
             * dvsnCode 설비구분 코드
             * equipPropCode 항목 코드
             * ptAddr 관제점 주소
             * ptIdx 관제점 번호
             * mapDetail 매핑 상세설명
             */

            let result = apiClient.post(xemsHost() + `/equipMap/insertEquipMap`, equipInfo);

            return result;
        },
        deleteEquipMap(equipInfo) {
            /**
             * NOTE - equipInfo 정보
             * equipIdx 설비기기(그룹) 번호
             * dvsnCode 설비구분 코드
             * equipPropCode 항목 코드
             * ptAddr 관제점 주소
             * ptIdx 관제점 번호
             * mapDetail 매핑 상세설명
             */

            let result = apiClient.post(xemsHost() + `/equipMap/deleteEquipMap`, equipInfo);

            return result;
        },
    },
    cstnPredic: {
        async itemSelectBox(energySourceCode) {
            let query = "";
            if (energySourceCode) {
                query = "?";
            }

            if (energySourceCode) {
                query += `energySourceCode=${energySourceCode}`;
            }

            let result = await apiClient.get(xemsHost() + `/cstnPredic/itemSelectBox${query}`);

            return result;
        },

        async basic({ timeDsvn, energySourceCode, fromDate, toDate }) {
            let query = "";

            if (timeDsvn || energySourceCode || fromDate || toDate) {
                query = "?";
            }

            if (timeDsvn) {
                query += `timeDsvn=${timeDsvn}&`;
            }

            if (energySourceCode) {
                query += `energySourceCode=${energySourceCode}&`;
            }

            if (fromDate) {
                query += `fromDate=${fromDate}&`;
            }

            if (toDate) {
                query += `toDate=${toDate}&`;
            }

            let result = await apiClient.get(xemsHost() + `/cstnPredic/basic${query}`);

            return result;
        },
    },
    analysis: {
        async analysisConsump(data) {
            let param =
                "?analType=" +
                data.analType +
                "&sourceType=" +
                data.sourceType +
                "&alysDivn=" +
                data.alysDivn +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate +
                "&sysGroupCd=" +
                data.sysGroupCd +
                "&sysCd=" +
                data.sysCd +
                "&equipCd=" +
                data.equipCd +
                "&stdCd=" +
                data.stdCd +
                "&compFromDate=" +
                data.compFromDate +
                "&compToDate=" +
                data.compToDate;

            let result = await apiClient.get(xemsHost() + `/analysis/analysisConsump` + param);

            return result;
        },

        async analysisTotal(data) {
            let param =
                "?analType=" +
                data.analType +
                "&sourceType=" +
                data.sourceType +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate +
                "&compFromDate=" +
                data.compFromDate +
                "&compToDate=" +
                data.compToDate;

            let result = await apiClient.get(xemsHost() + `/analysis/analysisTotal` + param);

            return result;
        },

        async searchSystemGroupCode() {
            let result = await apiClient.get(xemsHost() + `/analysis/searchSystemGroupCode`);

            return result;
        },
        async searchSystemCode(sysGroupCd) {
            let result = await apiClient.get(xemsHost() + `/analysis/searchSystemCode?sysGroupCd=` + sysGroupCd);

            return result;
        },
        async analysisPeak(data) {
            let param =
                "?analType=" +
                data.analType +
                "&sourceType=" +
                data.sourceType +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate +
                "&compFromDate=" +
                data.compFromDate +
                "&compToDate=" +
                data.compToDate +
                "&peakIdx=" +
                data.peakIdx;

            let result = await apiClient.get(xemsHost() + `/analysis/analysisPeak` + param);

            return result;
        },
        async analysisGoal(data) {
            let param =
                "?analType=" +
                data.analType +
                "&sourceType=" +
                data.sourceType +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate +
                "&pointIdx=" +
                data.pointIdx;

            let result = await apiClient.get(xemsHost() + `/analysis/analysisGoal` + param);

            return result;
        },
        async analysisProdCost(data) {
            // console.log(data);
            let param =
                "?categoryType=" +
                data.categoryType +
                "&analType=" +
                data.analType +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate +
                "&compFromDate=" +
                data.compFromDate +
                "&compToDate=" +
                data.compToDate;

            let result = await apiClient.get(xemsHost() + `/analysis/analysisProdCost` + param);

            return result;
        },

        async analysisEnergyCost(data) {
            let param =
                "?categoryType=" +
                data.categoryType +
                "&analType=" +
                data.analType +
                "&sourceType=" +
                data.sourceType +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate +
                "&compFromDate=" +
                data.compFromDate +
                "&compToDate=" +
                data.compToDate;

            // console.log(xemsHost() + `/analysis/analysisEnergyCost` + param);
            let result = await apiClient.get(xemsHost() + `/analysis/analysisEnergyCost` + param);

            return result;
        },

        async searchCharge() {
            // console.log(xemsHost() + `/analysis/searchCharge`);
            let result = await apiClient.get(xemsHost() + `/analysis/searchCharge`);

            return result;
        },

        async searChargeBasis(chargeIdx) {
            let result = await apiClient.get(xemsHost() + `/analysis/searChargeBasis?chargeIdx=` + chargeIdx);

            return result;
        },

        async analysisEnergyCostCompare(data) {
            let param =
                "?analType=" +
                data.analType +
                "&sourceType=" +
                data.sourceType +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate +
                "&pChargeIdx=" +
                data.pChargeIdx +
                "&pChargeBasisIdx=" +
                data.pChargeBasisIdx;

            // console.log(xemsHost() + `/analysis/analysisEnergyCostCompare` + param);
            let result = await apiClient.get(xemsHost() + `/analysis/analysisEnergyCostCompare` + param);

            return result;
        },

        randomData() {
            return Math.floor(Math.random() * 100);
        },
    },
    cspnAnal: {
        zoneUsage(data) {
            let param =
                "?timeDsvn=" +
                data.timeDsvn +
                "&timeDsvnOpt=" +
                data.timeDsvnOpt +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate +
                "&compFromDate=" +
                data.compFromDate +
                "&compToDate=" +
                data.compToDate +
                "&zoneIdx=" +
                data.zoneIdx +
                "&energySourceCode=" +
                data.energySourceCode;

            let result = apiClient.get(xemsHost() + `/cspnAnal/zoneUsage` + param);

            return result;
        },
        zoneEnergySource(data) {
            let param = "?";

            let dataKeys = Object.keys(data);

            dataKeys.forEach((keyName, i) => {
                param += `${keyName}=${data[keyName]}`;
                if (i !== dataKeys.length - 1) param += "&";
            });

            // if(data.timeDsvn)         param += `timeDsvn=${data.timeDsvn}`;
            // else if(data.timeDsvnOpt) param += ``

            // "?timeDsvn=" + data.timeDsvn +
            // "&timeDsvnOpt=" + data.timeDsvnOpt +
            // "&fromDate=" + data.fromDate +
            // "&toDate=" + data.toDate +
            // "&compFromDate=" + data.compFromDate +
            // "&compToDate=" + data.compToDate +
            // "&zoneIdx=" + data.zoneIdx;

            let result = apiClient.get(xemsHost() + `/cspnAnal/zoneEnergySource` + param);

            return result;
        },
        energyConsumptByZone(data) {
            let param = "?";

            let dataKeys = Object.keys(data);

            dataKeys.forEach((keyName, i) => {
                param += `${keyName}=${data[keyName]}`;
                if (i !== dataKeys.length - 1) param += "&";
            });

            // if(data.timeDsvn)         param += `timeDsvn=${data.timeDsvn}`;
            // else if(data.timeDsvnOpt) param += ``

            // "?timeDsvn=" + data.timeDsvn +
            // "&timeDsvnOpt=" + data.timeDsvnOpt +
            // "&fromDate=" + data.fromDate +
            // "&toDate=" + data.toDate +
            // "&compFromDate=" + data.compFromDate +
            // "&compToDate=" + data.compToDate +
            // "&zoneIdx=" + data.zoneIdx;

            let result = apiClient.get(xemsHost() + `/cspnAnal/energyConsumptByZone` + param);

            return result;
        },
        energySupplyBySource(data) {
            let param = "?";

            let dataKeys = Object.keys(data);

            dataKeys.forEach((keyName, i) => {
                param += `${keyName}=${data[keyName]}`;
                if (i !== dataKeys.length - 1) param += "&";
            });

            // if(data.timeDsvn)         param += `timeDsvn=${data.timeDsvn}`;
            // else if(data.timeDsvnOpt) param += ``

            // "?timeDsvn=" + data.timeDsvn +
            // "&timeDsvnOpt=" + data.timeDsvnOpt +
            // "&fromDate=" + data.fromDate +
            // "&toDate=" + data.toDate +
            // "&compFromDate=" + data.compFromDate +
            // "&compToDate=" + data.compToDate +
            // "&zoneIdx=" + data.zoneIdx;

            let result = apiClient.get(xemsHost() + `/cspnAnal/energySupplyBySource` + param);

            return result;
        },
        useTarget(data) {
            let param =
                "?timeDsvn=" +
                data.timeDsvn +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate +
                "&ptAddr=" +
                data.ptAddr;

            let result = apiClient.get(xemsHost() + `/cspnAnal/useTarget` + param);

            return result;
        },
        yearUseTarget(data) {
            let param = "?fromDate=" + data.fromDate + "&toDate=" + data.toDate + "&ptAddr=" + data.ptAddr;

            let result = apiClient.get(xemsHost() + `/cspnAnal/yearUseTarget` + param);

            return result;
        },
    },
    etc: {
        getEnvFactor(param) {
            let timeDsvn = param.timeDsvn;
            let dataDsvn = param.dataDsvn;
            let fromDate = param.fromDate;
            let toDate = param.toDate;
            let zoneIdx = param.zoneIdx;

            let result = apiClient.get(
                xemsHost() +
                    `/etc/getEnvFactor?timeDsvn=${timeDsvn}&dataDsvn=${dataDsvn}&fromDate=${fromDate}&toDate=${toDate}&zoneIdx=${zoneIdx} `
            );

            return result;
        },
    },
    zoneInfo: {
        getZoneSelectBox() {
            return apiClient.get(xemsHost() + `/zoneInfo/getZoneSelectBox`);
        },
        getZonechildrenList(zoneIdx) {
            return apiClient.get(xemsHost() + `/zoneInfo/getZonechildrenList` + "?zoneIdx=" + zoneIdx);
        },
        getZoneInfoList(enabled) {
            let result = null;

            if (enabled == "" || enabled == null) {
                result = apiClient.get(xemsHost() + `/zoneInfo/getZoneInfoList`);
            } else {
                result = apiClient.get(xemsHost() + `/zoneInfo/getZoneInfoList` + "?enabled=" + enabled);
            }

            return result;
        },
        getZoneListHierarchi() {
            return apiClient.get(xemsHost() + `/zoneInfo/getZoneListHierarchi`);
        },
        getZoneInfo(zoneIdx) {
            return apiClient.get(xemsHost() + `/zoneInfo/getZoneInfo` + "?zoneIdx=" + zoneIdx);
        },
        insertZoneInfo(param) {
            return apiClient.post(xemsHost() + `/zoneInfo/insertZoneInfo`, param);
        },
        updateZoneInfo(param) {
            return apiClient.post(xemsHost() + `/zoneInfo/updateZoneInfo`, param);
        },
        deleteZoneInfo(zoneIdx) {
            return apiClient.delete(xemsHost() + `/zoneInfo/deleteZoneInfo` + "?zoneIdx=" + zoneIdx);
        },
        getZoneDsnFile(zoneIdx) {
            return axios({
                method: "get",
                url: xemsHost() + "/zoneInfo/getZoneDsnFile?zoneIdx=" + zoneIdx,
                responseType: "arraybuffer",
            });

            // return apiClient.get(xemsHost() + `/zoneInfo/getZoneDsnFile`+"?zoneIdx="+zoneIdx);
        },
    },
    largeCode: {},
    smallCode: {},

    target: {
        getTargetDataList(data) {
            let param = "?energySourceCode=" + data.energySourceCode + "&baseYear=" + data.baseYear;

            let result = apiClient.get(xemsHost() + `/target/getTargetDataList` + param);
            return result;
        },

        insertgetTargetData(data) {
            return apiClient.post(xemsHost() + `/target/insertgetTargetData`, data);
        },
    },

    renewable: {
        summaryAnalsis(data) {
            let param = null;

            if (data.timeDsvn == "Yearly") {
                param = "?timeDsvn=" + data.timeDsvn + "&fromDate=" + data.fromDate + "&toDate=" + data.toDate;
            } else {
                param =
                    "?timeDsvn=" +
                    data.timeDsvn +
                    "&fromDate=" +
                    data.fromDate +
                    "&toDate=" +
                    data.toDate +
                    "&compFromDate=" +
                    data.compFromDate +
                    "&compToDate=" +
                    data.compToDate +
                    "&timeDsvnOpt=" +
                    data.timeDsvnOpt;
            }

            let result = apiClient.get(xemsHost() + `/renewable/summaryAnalsis` + param);

            return result;
        },
        itemAnalsis(data) {
            let param = null;

            if (data.timeDsvn == "Yearly") {
                param =
                    "?timeDsvn=" +
                    data.timeDsvn +
                    "&fromDate=" +
                    data.fromDate +
                    "&toDate=" +
                    data.toDate +
                    "&renewTypeCode=" +
                    data.renewTypeCode;
            } else {
                param =
                    "?timeDsvn=" +
                    data.timeDsvn +
                    "&fromDate=" +
                    data.fromDate +
                    "&toDate=" +
                    data.toDate +
                    "&compFromDate=" +
                    data.compFromDate +
                    "&compToDate=" +
                    data.compToDate +
                    "&timeDsvnOpt=" +
                    data.timeDsvnOpt +
                    "&renewTypeCode=" +
                    data.renewTypeCode;
            }

            let result = apiClient.get(xemsHost() + `/renewable/itemAnalsis` + param);

            return result;
        },
    },
    orgUnit: {
        itemSelectBox() {
            return apiClient.get(xemsHost() + `/orgUnit/itemSelectBox`);
        },
        async itemAnalsis(data) {
            let param =
                "?timeDsvn=" +
                data.timeDsvn +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate +
                "&orgUnitDvsnCode=" +
                data.orgUnitDvsnCode;

            let result = await apiClient.get(xemsHost() + `/orgUnit/itemAnalsis` + param);
            console.log("result in backendApi", result);
            return result && JSON.parse(JSON.stringify(result));
            //return result;
        },
    },
    usageCost: {
        async totAnalsis({ timeDsvn, fromDate, toDate, compFromDate, compToDate, timeDsvnOpt }) {
            let query = "";

            if (timeDsvn || fromDate || toDate || compFromDate || compToDate || timeDsvnOpt) {
                query = "?";
            }

            if (timeDsvn) {
                query += `timeDsvn=${timeDsvn}&`;
            }
            if (fromDate) {
                query += `fromDate=${fromDate}&`;
            }
            if (toDate) {
                query += `toDate=${toDate}&`;
            }
            if (compFromDate) {
                query += `compFromDate=${compFromDate}&`;
            }
            if (compToDate) {
                query += `compToDate=${compToDate}&`;
            }
            if (timeDsvnOpt) {
                query += `timeDsvnOpt=${timeDsvnOpt}&`;
            }

            let result = await apiClient.get(xemsHost() + `/usageCost/totAnalsis${query}`);
            return result;
        },
        itemSelectBox(energySourceCode) {
            return apiClient.get(xemsHost() + `/usageCost/itemSelectBox?energySourceCode=` + energySourceCode);
        },
        // async analysisConsump(data) {
        //   let param = "?analType=" + data.analType + "&sourceType=" + data.sourceType + "&alysDivn=" + data.alysDivn + "&fromDate=" + data.fromDate + "&toDate=" + data.toDate + "&sysGroupCd=" + data.sysGroupCd + "&sysCd=" + data.sysCd + "&equipCd=" + data.equipCd + "&stdCd=" + data.stdCd + "&compFromDate=" + data.compFromDate + "&compToDate=" + data.compToDate;

        //   let result = await apiClient.get(xemsHost() + `/analysis/analysisConsump` + param);

        //   return result;
        // },
        elecAnalsis(data) {
            let param = null;

            if (data.timeDsvn == "Yearly") {
                param =
                    "?timeDsvn=" +
                    data.timeDsvn +
                    "&ptAddr=" +
                    data.ptAddr +
                    "&fromDate=" +
                    data.fromDate +
                    "&toDate=" +
                    data.toDate;
            } else {
                param =
                    "?timeDsvn=" +
                    data.timeDsvn +
                    "&ptAddr=" +
                    data.ptAddr +
                    "&costType=" +
                    data.costType +
                    "&fromDate=" +
                    data.fromDate +
                    "&toDate=" +
                    data.toDate +
                    "&compFromDate=" +
                    data.compFromDate +
                    "&compToDate=" +
                    data.compToDate +
                    "&timeDsvnOpt=" +
                    data.timeDsvnOpt;
            }

            let result = apiClient.get(xemsHost() + `/usageCost/elecAnalsis` + param);

            return result;
        },
    },
    costContract: {
        getCostContractList(data) {
            let param = "?ptAddr=" + data.ptAddr + "&baseYear=" + data.baseYear;

            let result = apiClient.get(xemsHost() + `/costContract/getCostContractList` + param);

            return result;
        },
    },
    // 2.0 검교정이력관리 호출 api
    // clbrtnHist:{
    //   getChannelClbrnList(){
    //     return apiClient.get(xemsHost() + `/clbrtnHist/getChannelClbrnList`);
    //   },
    //   getClbrnList(data){
    //     let param =   "?equipIdx=" + data.equipIdx;

    //     let result = apiClient.get(xemsHost() + `/clbrtnHist/getClbrnList` + param);

    //     return result;
    //   },
    //   getClbrn(clbrtnIdx){
    //     return apiClient.get(xemsHost() + `/clbrtnHist/getClbrn?clbrtnIdx=` + clbrtnIdx);
    //   },
    //   insertClbrn(param){
    //     return apiClient.post(xemsHost() + `/clbrtnHist/insertClbrn`,param);
    //   },
    //   updateClbrn(param){
    //     return apiClient.post(xemsHost() + `/clbrtnHist/updateClbrn`,param);
    //   },
    //   deleteClbrn(clbrtnIdx){
    //     return apiClient.delete(xemsHost() + `/clbrtnHist/deleteClbrn?clbrtnIdx=` + clbrtnIdx);
    //   }
    // },
    // 1.5 검교정이력관리 호출 api
    clbrtnHist: {
        getFacilityClbrnList() {
            return apiClient.get(xemsHost() + `/clbrtnHist/getFacilityClbrnList`);
        },
        getClbrnHistList(data) {
            let queryString = "";
            if (data) {
                queryString = `?facilityIdx=${data.facilityIdx}`;
            }

            // let param =   "?serverIdx=" + data.serverIdx +
            // "&channelIdx=" + data.channelIdx;

            let result = apiClient.get(xemsHost() + `/clbrtnHist/getClbrnHistList${queryString}`);

            return result;
        },
        getClbrnHistInfo(clbrtnIdx) {
            return apiClient.get(xemsHost() + `/clbrtnHist/getClbrnHistInfo?clbrtnIdx=` + clbrtnIdx);
        },
        insertClbrn(param) {
            console.log(param);
            return apiClient.post(xemsHost() + `/clbrtnHist/insertClbrn`, param);
        },
        updateClbrn(param) {
            return apiClient.post(xemsHost() + `/clbrtnHist/updateClbrn`, param);
        },
        deleteClbrn(clbrtnIdx) {
            return apiClient.get(xemsHost() + `/clbrtnHist/deleteClbrn?clbrtnIdx=` + clbrtnIdx);
        },
    },
    cost: {
        getRegList(energySourceCode) {
            return apiClient.get(xemsHost() + `/cost/getRegList?energySourceCode=` + energySourceCode);
        },
        getBasicList(energySourceCode) {
            return apiClient.get(xemsHost() + `/cost/getBasicList?energySourceCode=` + energySourceCode);
        },
        getDvsnList(costBasicCode) {
            return apiClient.get(xemsHost() + `/cost/getDvsnList?costBasicCode=` + costBasicCode);
        },
        getCostData(regIdx) {
            return apiClient.get(xemsHost() + `/cost/getCostData?regIdx=` + regIdx);
        },
        getCostMapList(data) {
            let param =
                "?energySourceCode=" +
                data.energySourceCode +
                "&costBasicCode=" +
                data.costBasicCode +
                "&costDvsnCode=" +
                data.costDvsnCode;

            return apiClient.get(xemsHost() + `/cost/getCostMapList` + param);
        },
        insertRegist(param) {
            return apiClient.post(xemsHost() + `/cost/insertRegist`, param);
        },
        getTargetPointList() {
            return apiClient.get(xemsHost() + `/cost/getTargetPointList`);
        },
        checkDuplCostMap(ptAddr) {
            return apiClient.get(xemsHost() + `/cost/checkDuplCostMap?ptAddr=` + ptAddr);
        },
        insertCostMap(param) {
            return apiClient.post(xemsHost() + `/cost/insertCostMap`, param);
        },
        updateCostMap(param) {
            return apiClient.post(xemsHost() + `/cost/updateCostMap`, param);
        },
        getCostTableDetail(data) {
            let param =
                "?costBasicCode=" +
                data.costBasicCode +
                "&costDvsnCode=" +
                data.costDvsnCode +
                "&applyStartDate=" +
                data.applyStartDate +
                "&applyEndDate=" +
                data.applyEndDate;

            return apiClient.get(xemsHost() + `/cost/getCostTableDetail` + param);
        },
        insertCalendar(param) {
            return apiClient.post(xemsHost() + `/cost/insertCalendar`, param);
        },
        insertCostDetail(param) {
            return apiClient.post(xemsHost() + `/cost/insertCostDetail`, param);
        },
        deleteCostData(regIdx) {
            return apiClient.delete(xemsHost() + `/cost/deleteCostData?regIdx=` + regIdx);
        },
        deleteCostMap(ptAddr) {
            return apiClient.delete(xemsHost() + `/cost/deleteCostMap?ptAddr=` + ptAddr);
        },
        insertCostTable(param) {
            return apiClient.post(xemsHost() + `/cost/insertCostTable`, param);
        },
        deleteCostTable(data) {
            let param =
                "?costBasicCode=" +
                data.costBasicCode +
                "&costDvsnCode=" +
                data.costDvsnCode +
                "&applyStartDate=" +
                data.applyStartDate +
                "&applyEndDate=" +
                data.applyEndDate;

            return apiClient.delete(xemsHost() + `/cost/deleteCostTable` + param);
        },
        checkDuplCostTable(data) {
            let param =
                "?costBasicCode=" +
                data.costBasicCode +
                "&costDvsnCode=" +
                data.costDvsnCode +
                "&applyStartDate=" +
                data.applyStartDate +
                "&applyEndDate=" +
                data.applyEndDate;

            return apiClient.get(xemsHost() + `/cost/checkDuplCostTable` + param);
        },
    },
    calendar: {
        getHolidayList(data) {
            let param = "?baseDate=" + data.baseDate + "&holiTypeCode=" + data.holiTypeCode;

            return apiClient.get(xemsHost() + `/calendar/getHolidayList` + param);
        },
        insertHoliday(param) {
            // holiIdx	int	휴일 일련번호
            // baseDate	varchar(10)	기준일자
            // holiTypeCode	varchar(10)	휴일유형
            // holiName	varchar(50)	휴일명
            // holiDesc	varchar(100)	비고

            return apiClient.post(xemsHost() + `/calendar/insertHoliday`, param);
        },
        deleteHoliday(param) {
            // "holiIdxs" : [4]
            return apiClient.post(xemsHost() + `/calendar/deleteHoliday`, { holiIdxs: param });
        },
    },
    custom: {
        getBaseYearList() {
            return apiClient.get(xemsHost() + `/custom/getBaseYearList`);
        },
        getCustPointList(baseYear) {
            return apiClient.get(xemsHost() + `/custom/getCustPointList?baseYear=` + baseYear);
        },
        insertCustomPoint(param) {
            return apiClient.post(xemsHost() + `/custom/insertCustomPoint`, param);
        },
        deleteCustomPoint(data) {
            // let param = "?baseYear=" + data.baseYear +
            // "&ptAddr=" + data.ptAddr;

            let query = "?";

            if (data.baseYear) {
                query += `baseYear=${data.baseYear}&`;
            }

            if (data.ptAddr) {
                query += `ptAddr=${data.ptAddr}`;
            }

            return apiClient.delete(xemsHost() + `/custom/deleteCustomPoint${query}`);
        },
    },
    codes: {
        getCommonCodeList(enabled) {
            //전체 코드 조회

            // enabled	String(5)	사용여부	N

            // equipUsage : return 시 사용하지않음
            let result = null;

            if (enabled == "") {
                result = apiClient.get(xemsHost() + `/codes/getCommonCodeList`);
            } else {
                result = apiClient.get(xemsHost() + `/codes/getCommonCodeList` + "?enabled=" + enabled);
            }

            return result;
        },
        getBuildUsageCodeList(searchStr, enabled) {
            //건물 용도 대분류 코드 조회

            // searchStr	String(50)	구역 용도 코드명	N
            // enabled	String(5)	사용여부	N
            let result = null;

            if (!searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/codes/getBuildUsageCodeList`);
            } else if (searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/codes/getBuildUsageCodeList` + "?searchStr=" + searchStr);
            } else if (!searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/codes/getBuildUsageCodeList` + "?enabled=" + enabled);
            } else {
                result = apiClient.get(
                    xemsHost() + `/codes/getBuildUsageCodeList ` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            }

            return result;
        },
        getBuildUsageCode(buildusageCode) {
            //건물 용도 대분류 코드 상세 조회
            return apiClient.get(xemsHost() + `/codes/getBuildUsageCode` + "?buildusageCode=" + buildusageCode);
        },
        getZoneusageCodeList(searchStr, enabled) {
            //구역 용도 코드 조회

            // searchStr	String(50)	구역 용도 코드명	N
            // enabled	String(5)	사용여부	N
            let result = null;

            if (!searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/codes/getZoneusageCodeList`);
            } else if (searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/codes/getZoneusageCodeList` + "?searchStr=" + searchStr);
            } else if (!searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/codes/getZoneusageCodeList` + "?enabled=" + enabled);
            } else {
                result = apiClient.get(
                    xemsHost() + `/codes/getZoneusageCodeList` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            }

            return result;
        },
        getZoneusageCode(zoneUseCode) {
            //구역 용도 코드 상세 조회
            return apiClient.get(xemsHost() + `/codes/getZoneusageCode?zoneUseCode=` + zoneUseCode);
        },
        getSidoCodeList(searchStr, enabled) {
            //지역(광역시도) 코드 조회

            // searchStr	String(50)	구역 용도 코드명	N
            // enabled	String(5)	사용여부	N
            let result = null;

            if (!searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/codes/getSidoCodeList`);
            } else if (searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/codes/getSidoCodeList` + "?searchStr=" + searchStr);
            } else if (!searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/codes/getSidoCodeList` + "?enabled=" + enabled);
            } else {
                result = apiClient.get(
                    xemsHost() + `/codes/getSidoCodeList` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            }

            return result;
        },
        getSidoCode(sidoCode) {
            //지역(광역시도) 코드 상세 조회

            return apiClient.get(xemsHost() + `/codes/getSidoCode` + "?sidoCode=" + sidoCode);
        },
    },
    publicCode: {
        getLargeCodeList(searchStr, enabled) {
            let result = null;
            console.log(`${searchStr}, ${enabled}`);

            // (!searchStr && !enabled)

            if (!searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/publicCode/getLargeCodeList`);
            } else if (searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/publicCode/getLargeCodeList` + "?searchStr=" + searchStr);
            } else if (!searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/publicCode/getLargeCodeList` + "?enabled=" + enabled);
            } else {
                result = apiClient.get(
                    xemsHost() + `/publicCode/getLargeCodeList` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            }

            //result = apiClient.get(xemsHost() + `/publicCode/getLargeCodeList`+ "?searchStr='");

            return result;
        },
        getLargeCode(largeCode) {
            return apiClient.get(xemsHost() + `/publicCode/getLargeCode` + "?largeCode=" + largeCode);
        },
        insertLargeCode(param) {
            //공통 대분류 코드 등록

            // largeCode	String(20)	대분류 코드	Y
            // largeCodeName	String(50)	대분류 코드명	Y
            // etcInfo1	varchar(100)	기타정보1
            // etcInfo2	varchar(100)	기타정보2
            // etcInfo3	varchar(100)	기타정보3
            // etcInfo4	varchar(100)	기타정보4
            // etcInfo5	varchar(100)	기타정보5
            // oprtnCodeYn	String(10)	운영코드 사용여부
            // codeDesc	varchar(50)	코드 상세
            // rmk	varchar(500)	비고
            // enabled	String(10)	사용여부

            return apiClient.post(xemsHost() + `/publicCode/insertLargeCode`, param);
        },
        /**
         * 공통 대분류 코드 등록 및 수정
         * @param {Array<Object>} params
         * @returns
         */
        saveLargeCode(params) {
            //공통 대분류 코드 등록 및 수정

            // largeCode	String(20)	대분류 코드
            // largeCodeName	String(50)	대분류 코드명
            // etcInfo1	varchar(100)	기타정보1
            // etcInfo2	varchar(100)	기타정보2
            // etcInfo3	varchar(100)	기타정보3
            // etcInfo4	varchar(100)	기타정보4
            // etcInfo5	varchar(100)	기타정보5
            // oprtnCodeYn	String(10)	운영코드 사용여부
            // codeDesc	varchar(50)	코드 상세
            // rmk	varchar(500)	비고
            // enabled	String(10)	사용여부

            return apiClient.post(xemsHost() + `/publicCode/saveLargeCode`, params);
        },

        /**
         * 공통 대분류 코드 일괄삭제
         * @param {Array<String>} params
         * @returns
         */
        removeLargeCode(params) {
            // params = largeCode 문자 배열

            return apiClient.post(xemsHost() + `/publicCode/removeLargeCode`, params);
        },
        updateLargeCode(param) {
            //공통 대분류 코드 수정

            // largeCode	String(20)	대분류 코드
            // largeCodeName	String(50)	대분류 코드명
            // etcInfo1	varchar(100)	기타정보1
            // etcInfo2	varchar(100)	기타정보2
            // etcInfo3	varchar(100)	기타정보3
            // etcInfo4	varchar(100)	기타정보4
            // etcInfo5	varchar(100)	기타정보5
            // oprtnCodeYn	String(10)	운영코드 사용여부
            // codeDesc	varchar(50)	코드 상세
            // rmk	varchar(500)	비고
            // enabled	String(10)	사용여부
            return apiClient.post(xemsHost() + `/publicCode/updateLargeCode`, param);
        },
        deleteLargeCode(largeCode) {
            //공통 대분류 코드 삭제

            // largeCode String(20)	대분류 코드	Y
            return apiClient.delete(xemsHost() + `/publicCode/deleteLargeCode?largeCode=` + largeCode);
        },
        getSmallCodeList(largeCode, searchStr, enabled) {
            //공통 소분류 코드 조회
            let query = "";

            if (largeCode || searchStr || enabled) {
                query = "?";
            }

            if (!isEmpty(largeCode)) {
                query += `largeCode=${largeCode}&`;
            }

            if (!isEmpty(searchStr)) {
                query += `searchStr=${searchStr}&`;
            }

            if (!isEmpty(enabled)) {
                query += `enabled=${enabled}&`;
            }

            let result = null;

            result = apiClient.get(xemsHost() + `/publicCode/getSmallCodeList${query}`);

            // if(!searchStr && !enabled){
            //   result = apiClient.get(xemsHost() + `/publicCode/getSmallCodeList`+ "?largeCode="+largeCode);
            // }else if(searchStr && !enabled){
            //   result = apiClient.get(xemsHost() + `/publicCode/getSmallCodeList`+ "?largeCode="+largeCode+ "&searchStr="+searchStr);
            // }else if(!searchStr && enabled){
            //   result = apiClient.get(xemsHost() + `/publicCode/getSmallCodeList`+ "?largeCode="+largeCode+ "&enabled="+enabled);
            // }else{
            //   result = apiClient.get(xemsHost() + `/publicCode/getSmallCodeList`+ "?largeCode="+largeCode+ "&searchStr="+searchStr+"&enabled="+enabled);
            // }
            return result;
        },
        getSmallCode(data) {
            //공통 소분류 코드 상세 조회

            let param = "?largeCode=" + data.largeCode + "&smallCode=" + data.smallCode;

            return apiClient.get(xemsHost() + `/publicCode/getSmallCode` + param);
        },
        insertSmallCode(param) {
            //공통 소분류 코드 등록

            // largeCode	String(20)	대분류 코드	Y
            // smallCode	String(20)	소분류 코드	Y
            // smallCodeName	String(50)	소분류 코드명	Y
            // etcInfo1	varchar(500)	기타정보1
            // etcInfo2	varchar(500)	기타정보2
            // etcInfo3	varchar(500)	기타정보3
            // etcInfo4	varchar(500)	기타정보4
            // etcInfo5	varchar(500)	기타정보5
            // codeDesc	String(100)	코드 상세
            // rmk	varchar(500)	비고
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/publicCode/insertSmallCode`, param);
        },
        /**
         * 공통 소분류 코드 등록 및 수정
         * @param {Array<Object>} params
         * @returns
         */
        saveSmallCode(params) {
            //공통 소분류 코드 및 수정

            // largeCode	String(20)	대분류 코드	Y
            // smallCode	String(20)	소분류 코드	Y
            // smallCodeName	String(50)	소분류 코드명	Y
            // etcInfo1	varchar(500)	기타정보1
            // etcInfo2	varchar(500)	기타정보2
            // etcInfo3	varchar(500)	기타정보3
            // etcInfo4	varchar(500)	기타정보4
            // etcInfo5	varchar(500)	기타정보5
            // codeDesc	String(100)	코드 상세
            // rmk	varchar(500)	비고
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/publicCode/saveSmallCode`, params);
        },
        /**
         * 공통 소분류 코드 일괄 삭제
         * @param {Object} params
         * @returns
         */
        removeSmallCode(params) {
            // 공통 소분류 코드 일괄 삭제

            // largeCode String 대분류 코드
            // smallCode Array 소분류 코드 리스트

            return apiClient.post(xemsHost() + `/publicCode/removeSmallCode`, params);
        },

        updateSmallCode(param) {
            //공통 소분류 코드 수정

            // largeCode	String(20)	대분류 코드	Y
            // smallCode	String(20)	소분류 코드	Y
            // smallCodeName	String(50)	소분류 코드명
            // etcInfo1	varchar(500)	기타정보1
            // etcInfo2	varchar(500)	기타정보2
            // etcInfo3	varchar(500)	기타정보3
            // etcInfo4	varchar(500)	기타정보4
            // etcInfo5	varchar(500)	기타정보5
            // codeDesc	String(100)	코드 상세
            // rmk	varchar(500)	비고
            // enabled	String(10)	사용여부

            return apiClient.post(xemsHost() + `/publicCode/updateSmallCode`, param);
        },
        deleteSmallCode(largeCode, smallCode) {
            //공통 소분류 코드 삭제
            let param = "?largeCode=" + largeCode + "&smallCode=" + smallCode;

            // largeCode	String(20)	공통 대분류 코드	Y
            // smallCode	String(20)	공통 소분류 코드	Y
            return apiClient.delete(xemsHost() + `/publicCode/deleteSmallCode` + param);
        },
        async getOprtnCodeList() {
            //운영코드 목록 조회

            let result = null;

            result = await apiClient.get(xemsHost() + `/publicCode/getOprtnCodeList`);

            return result;
        },
        async getOprtnDetailCodeList(largeCode) {
            let query = "?";

            if (largeCode) {
                query += `largeCode=${largeCode}`;
            }

            return await apiClient.get(xemsHost() + `/publicCode/getOprtnDetailCodeList${query}`);
        },

        getOprtnSmallCodeList(largeCode, searchStr, enabled) {
            //공통 소분류 코드 조회

            let result = null;

            // 운영코드 제거중. 향후 getOprtnSmallCodeList 를 getSmallCodeList로 대체해야 함!! 2024.04.04
            enabled = "Y";

            if (!searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/publicCode/getSmallCodeList` + "largeCode=" + largeCode);
            } else if (searchStr && !enabled) {
                result = apiClient.get(
                    xemsHost() + `/publicCode/getSmallCodeList` + "?largeCode=" + largeCode + "&searchStr=" + searchStr
                );
            } else if (!searchStr && enabled) {
                result = apiClient.get(
                    xemsHost() + `/publicCode/getSmallCodeList` + "?largeCode=" + largeCode + "&enabled=" + enabled
                );
            } else {
                result = apiClient.get(
                    xemsHost() +
                        `/publicCode/getSmallCodeList` +
                        "?largeCode=" +
                        largeCode +
                        "&searchStr=" +
                        searchStr +
                        "&enabled=" +
                        enabled
                );
            }
            return result;
        },
        insertOprtnSmallCode(param) {
            //운영 소분류 코드 등록
            return apiClient.post(xemsHost() + `/publicCode/insertOprtnSmallCode`, param);
        },
        updateOprtnSmallCode(param) {
            //운영 소분류 코드 수정
            return apiClient.post(xemsHost() + `/publicCode/updateOprtnSmallCode`, param);
        },
        /**
         * 운영코드 사용여부 일괄수정
         * @param {Object} param
         * @returns
         */
        updateOprtnCodeUseYn(param) {
            //운영코드 사용여부 수정

            //largeCode String 대분류 코드
            //smallCodeList Array<String> 소분류 코드 리스트
            return apiClient.post(xemsHost() + `/publicCode/updateOprtnCodeUseYn`, param);
        },
        deleteOprtnSmallCode(largeCode, smallCode) {
            //운영 소분류 코드 삭제
            let param = "?largeCode=" + largeCode + "&smallCode=" + smallCode;

            return apiClient.delete(xemsHost() + `/publicCode/deleteOprtnSmallCode` + param);
        },
        getSmallCodeByPoint() {
            return apiClient.get(xemsHost() + `/publicCode/getSmallCodeByPoint`);
        },
        // getSmallCodeList(largeCode){
        //   return apiClient.get(xemsHost() + `/publicCode/getSmallCodeList`+"?largeCode="+largeCode);
        // },
        getSmallCodeByChannel() {
            return apiClient.get(xemsHost() + `/publicCode/getSmallCodeByChannel`);
        },
    },
    commonCode: {
        // 공통 코드 관리 목록 조회
        getCommonCodeList() {
            let result = apiClient.get(xemsHost() + `/new/commonCode/getCommonCodeList`);
            return result;
        },
        getCommonCodeList2() {
            let result = apiClient.get(xemsHost() + `/new/commonCode/getCommonCodeList2`);
            return result;
        },
        // 공통 코드 관리 상세 내용 조회
        getCommonCode(commonCode) {
            let result = apiClient.get(xemsHost() + `/new/commonCode/getCommonCode?commonCode=${commonCode}`);
            return result;
        },
        // 공통 코드 관리 저장
        saveCommonCode(commonCode) {
            let result = apiClient.post(xemsHost() + `/new/commonCode/saveCommonCode`, commonCode);
            return result;
        },
        // 운영코드YN 변경
        setOprtnCodeYn(data) {
            let result = apiClient.put(xemsHost() + `/new/commonCode/setOprtnCodeYn`, data);
            return result;
        },
        // 사용여부 변경
        setEnabled(data) {
            let result = apiClient.put(xemsHost() + `/new/commonCode/setEnabled`, data);
            return result;
        },
        // 공통 코드 목록 삭제
        deleteCommonCode(commonCode) {
            let result = apiClient.delete(xemsHost() + `/new/commonCode/deleteCommonCode?commonCode=${commonCode}`);
            return result;
        },
    },
    newUnitCode: {
        // 단위 코드 관리 목록 조회
        getUnitCodeList() {
            let result = apiClient.get(xemsHost() + `/new/unitCode/getUnitCodeList`);
            return result;
        },
        // 단위 코드 관리 상세 내용 조회
        getUnitCode(unitCode) {
            let result = apiClient.get(xemsHost() + `/new/unitCode/getUnitCode?unitCode=${unitCode}`);
            return result;
        },
        // 단위 코드 관리 저장
        saveUnitCode(unitCode) {
            let result = apiClient.post(xemsHost() + `/new/unitCode/saveUnitCode`, unitCode);
            return result;
        },
        // 사용여부 변경
        setEnabled(data) {
            // let result = apiClient.put(xemsHost() + `/new/unitCode/setEnabled?enabled=${data.enabled}&unitCode=${data.unitCode}`);
            let result = apiClient.put(xemsHost() + `/new/unitCode/setEnabled?enabled`, data);
            return result;
        },
        // 단위 코드 목록 삭제
        deleteUnitCode(unitCode) {
            let result = apiClient.delete(xemsHost() + `/new/unitCode/deleteUnitCode?unitCode=${unitCode}`);
            return result;
        },
    },
    unitCode: {
        getUnitLargeCodeList(searchStr, enabled) {
            let result = null;

            if (!searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/unitCode/getUnitLargeCodeList`);
            } else if (searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/unitCode/getUnitLargeCodeList` + "?searchStr=" + searchStr);
            } else if (!searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/unitCode/getUnitLargeCodeList` + "?enabled=" + enabled);
            } else {
                result = apiClient.get(
                    xemsHost() + `/unitCode/getUnitLargeCodeList` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            }

            return result;
        },
        getUnitLargeCode(unitLargeCode) {
            //단위 대분류 코드 상세 조회
            return apiClient.get(xemsHost() + `/unitCode/getUnitLargeCode` + "?unitLargeCode=" + unitLargeCode);
        },
        insertUnitLargeCode(param) {
            //단위 대분류 코드 등록

            // unitLargeCode	String(20)	단위 대분류 코드	Y
            // unitLargeCodeName	String(50)	단위 대분류 코드명	Y
            // codeDesc	String(100)	코드 상세
            // enabled	String(10)	사용여부	Y
            return apiClient.post(xemsHost() + `/unitCode/insertUnitLargeCode`, param);
        },
        updateUnitLargeCode(param) {
            //단위 대분류 코드 수정

            // unitLargeCode	String(20)	단위 대분류 코드	Y
            // unitLargeCodeName	String(50)	단위 대분류 코드명	Y
            // codeDesc	String(100)	코드 상세
            // enabled	String(10)	사용여부	Y
            return apiClient.post(xemsHost() + `/unitCode/updateUnitLargeCode`, param);
        },
        /**
         * 단위 대분류 코드 등록 및 수정
         * @param {Array<Object>} param
         * @returns
         */
        saveUnitLargeCode(param) {
            //단위 대분류 코드 등록 및 수정

            // unitLargeCode	String(20)	단위 대분류 코드	Y
            // unitLargeCodeName	String(50)	단위 대분류 코드명	Y
            // codeDesc	String(100)	코드 상세
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/unitCode/saveUnitLargeCode`, param);
        },
        /**
         * 단위 대분류 코드 일괄 삭제
         * @param {Array<String>} param
         * @returns
         */
        removeUnitLargeCode(param) {
            //단위 대분류 코드 일괄 삭제

            // unitLargeCode Array<String> 단위 대분류 코드 리스트

            return apiClient.post(xemsHost() + `/unitCode/removeUnitLargeCode`, param);
        },

        deleteUnitLargeCode(unitLargeCode) {
            //단위 대분류 코드 삭제

            // unitLargeCode	String(20)	단위 대분류 코드	Y
            return apiClient.delete(xemsHost() + `/unitCode/deleteUnitLargeCode?unitLargeCode=` + unitLargeCode);
        },
        getUnitSmallCodeList(unitLargeCode) {
            //단위 소분류 코드 조회
            var query = "?";

            if (unitLargeCode) {
                query += `unitLargeCode=${unitLargeCode}`;
            }

            // let result = null;

            // if(!searchStr && !enabled){
            //   result = apiClient.get(xemsHost() + `/unitCode/getUnitSmallCodeList`+ "?unitLargeCode="+unitLargeCode);
            // }else if(searchStr && !enabled){
            //   result = apiClient.get(xemsHost() + `/unitCode/getUnitSmallCodeList`+ "?unitLargeCode="+unitLargeCode+ "&searchStr="+searchStr);
            // }else if(!searchStr && enabled){
            //   result = apiClient.get(xemsHost() + `/unitCode/getUnitSmallCodeList`+ "?unitLargeCode="+unitLargeCode+ "&enabled="+enabled);
            // }else{
            //   result = apiClient.get(xemsHost() + `/unitCode/getUnitSmallCodeList`+ "?unitLargeCode="+unitLargeCode+ "&searchStr="+searchStr+"&enabled="+enabled);
            // }

            return apiClient.get(xemsHost() + `/unitCode/getUnitSmallCodeList${query}`);

            // return result;
        },
        getUnitSmallCode(data) {
            // unitLargeCode	String(20)	단위 대분류 코드	Y
            // unitSmallCode	String(20)	단위 소분류 코드	Y
            let param = "?unitLargeCode=" + data.unitLargeCode + "&unitSmallCode=" + data.unitSmallCode;

            return apiClient.get(xemsHost() + `/unitCode/getUnitSmallCode` + param);
        },
        insertUnitSmallCode(param) {
            //단위 소분류 코드 등록

            // unitLargeCode	String(20)	단위 대분류 코드	Y
            // unitSmallCode	String(20)	단위 소분류 코드	Y
            // unitSmallCodeName	String(50)	단위 소분류 코드명	Y
            // codeDesc	String(100)	코드 상세
            // enabled	String(10)	사용여부	Y
            return apiClient.post(xemsHost() + `/unitCode/insertUnitSmallCode`, param);
        },
        updateUnitSmallCode(param) {
            //단위 소분류 코드 수정

            // unitLargeCode	String(20)	단위 대분류 코드	Y
            // unitSmallCode	String(20)	단위 소분류 코드	Y
            // unitSmallCodeName	String(50)	단위 소분류 코드명	Y
            // codeDesc	String(100)	코드 상세
            // enabled	String(10)	사용여부	Y
            return apiClient.post(xemsHost() + `/unitCode/updateUnitSmallCode`, param);
        },
        /**
         * 단위 소분류 코드 등록 및 수정
         * @param {Array<Object>} param
         * @returns
         */
        saveUnitSmallCode(param) {
            // 단위 소분류 코드 등록 및 수정

            // unitLargeCode	String(20)	단위 대분류 코드	Y
            // unitSmallCode	String(20)	단위 소분류 코드	Y
            // unitSmallCodeName	String(50)	단위 소분류 코드명	Y
            // codeDesc	String(100)	코드 상세
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/unitCode/saveUnitSmallCode`, param);
        },
        /**
         *
         * @param {Object} param
         * @returns
         */
        removeUnitSmallCode(param) {
            // unitLargeCode	String(20)	단위 대분류 코드	Y
            // unitSmallCode Array<String> 단위 소분류 코드 리스트

            return apiClient.post(xemsHost() + `/unitCode/removeUnitSmallCode`, param);
        },
        deleteUnitSmallCode(unitLargeCode, unitSmallCode) {
            //단위 소분류 코드 삭제

            // unitLargeCode	String(20)	단위 대분류 코드	Y
            // unitSmallCode	String(20)	단위 소분류 코드	Y
            let param = "?unitLargeCode=" + unitLargeCode + "&unitSmallCode=" + unitSmallCode;
            return apiClient.delete(xemsHost() + `/unitCode/deleteUnitSmallCode` + param);
        },

        getUnitCodeList(enabled) {
            let result = null;

            if (enabled) {
                result = apiClient.get(xemsHost() + `/unitCode/getUnitCodeList` + "?enabled=" + enabled);
            } else {
                result = apiClient.get(xemsHost() + `/unitCode/getUnitCodeList`);
            }

            return result;
        },
    },
    systeminfo: {
        getSystemInfoList(searchStr, enabled, systemTypeCode) {
            //계통 정보 조회

            let result = null;

            if (!searchStr && !enabled) {
                result = apiClient.get(
                    xemsHost() + `/systeminfo/getSystemInfoList` + "?systemTypeCode=" + systemTypeCode
                );
            } else if (searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/systeminfo/getSystemInfoList` + "?searchStr=" + searchStr);
            } else if (!searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/systeminfo/getSystemInfoList` + "?enabled=" + enabled);
            } else {
                result = apiClient.get(
                    xemsHost() + `/systeminfo/getSystemInfoList` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            }

            return result;
        },
        getSystemInfo(systemCode) {
            //계통 정보 상세 조회
            return apiClient.get(xemsHost() + `/systeminfo/getSystemInfo` + "?systemCode=" + systemCode);
        },
        insertSystemInfo(param) {
            //계통 정보 등록

            // systemCode	String(20)	계통 코드	Y
            // systemName	String(50)	계통명	Y
            // systemDetailDsc	String(100)	계통 상세설명
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/systeminfo/insertSystemInfo`, param);
        },
        updateSystemInfo(param) {
            //단위 소분류 코드 수정

            // systemCode	String(20)	계통 코드	Y
            // systemName	String(50)	계통명	Y
            // systemDetailDsc	String(100)	계통 상세설명
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/systeminfo/updateSystemInfo`, param);
        },
        deleteSystemInfo(systemCode) {
            //계통 정보 삭제

            // systemCode	String(20)	계통 코드	Y
            return apiClient.delete(xemsHost() + `/systeminfo/deleteSystemInfo?systemCode=` + systemCode);
        },
    },
    sysType: {
        // 계통 기준정보 관리 목록 조회
        getSysTypeList(enabled) {
            let result = apiClient.get(xemsHost() + `/new/sysTypeCode/getSysTypeCodeList?enabled=${enabled}`);
            return result;
        },
        // 계통 기준정보 관리 상세 내용 조회
        getSysType(sysType) {
            let result = apiClient.get(xemsHost() + `/new/sysTypeCode/getSysTypeCode?sysType=${sysType}`);
            return result;
        },
        // 계통 기준정보 관리 저장
        saveSysType(sysInfo) {
            let result = apiClient.post(xemsHost() + `/new/sysTypeCode/saveSysTypeCode`, sysInfo);
            return result;
        },
        // 계통 기준정보 관리 사용여부 변경
        setSysTypeEnabled(sysTypeEnabled) {
            let result = apiClient.put(xemsHost() + `/new/sysTypeCode/setEnabled`, sysTypeEnabled);
            return result;
        },
        // 계통 기준정보 관리 목록 삭제
        deleteSysType(sysType) {
            let result = apiClient.delete(xemsHost() + `/new/sysTypeCode/deleteSysTypeCode?sysType=${sysType}`);
            return result;
        },
    },
    equipTypes: {
        // 설비 유형 목록 조회
        getEquipTypeList(enabled) {
            if (enabled) {
                let result = apiClient.get(xemsHost() + `/new/equipTypeCode/getEquipTypeCodeList?enabled=${enabled}`);
                return result;
            } else {
                let result = apiClient.get(xemsHost() + `/new/equipTypeCode/getEquipTypeCodeList`);
                return result;
            }
        },
        // 설비 유형 상세 조회
        getEquipType(equipType, enabled) {
            let params = { equipType, enabled };
            let filtered = JSON.parse(JSON.stringify(params, (key, value) => value ?? undefined));
            let result = apiClient.get(xemsHost() + `/new/equipTypeCode/getEquipTypeCode`, { params: filtered });
            return result;
        },
        // 설비 유형 저장
        saveEquipType(equipTypeItem) {
            let result = apiClient.post(xemsHost() + `/new/equipTypeCode/saveEquipTypeCode`, equipTypeItem);
            return result;
        },
        // 설비 유형 사용 여부 변경
        setEquipTypeEnabled(statusItem) {
            let result = apiClient.put(xemsHost() + `/new/equipTypeCode/setEnabled`, statusItem);
            return result;
        },
        // 설비 유형 삭제
        deleteEquipType(equipType) {
            let result = apiClient.delete(xemsHost() + `/new/equipTypeCode/deleteEquipTypeCode?equipType=${equipType}`);
            return result;
        },
        // 드라이버 목록 조회
        searchDriver() {
            let result = apiClient.get(xemsHost() + `/driver/searchDriver`);
            return result;
        },
        getDriverProp(driverType, version) {
            let result = apiClient.get(
                xemsHost() + `/driver/getDriverProp?driverType=${driverType}&version=${version}&ct=1`
            );
            return result;
        },
    },
    equips: {
        // 설비 목록 조회
        getEquipList(equipType, zoneIdx, equipUseType) {
            let params = { equipType, zoneIdx, equipUseType };
            let filtered = JSON.parse(JSON.stringify(params, (key, value) => value ?? undefined));
            let result = apiClient.get(xemsHost() + `/new/equip/getEquipList`, { params: filtered });
            return result;
        },
        // 설비 상세 조회
        getEquipDetailList(equipIdx, enabled) {
            let params = { equipIdx, enabled };
            let filtered = JSON.parse(JSON.stringify(params, (key, value) => value ?? undefined));
            // let result = apiClient.get(xemsHost() + `/new/equip/getEquip?enabled=${enabled}&equipIdx=${equipIdx}`);
            let result = apiClient.get(xemsHost() + `/new/equip/getEquip`, { params: filtered });
            return result;
        },
        // 설비 저장
        saveEquip(equip) {
            let result = apiClient.post(xemsHost() + `/new/equip/saveEquip`, equip);
            return result;
        },
        // 설비 사용 여부 변경
        setEquipEnabled(statusItem) {
            let result = apiClient.put(xemsHost() + `/new/equip/setEnabled`, statusItem);
            return result;
        },
        // 설비 삭제
        deleteEquipInfo(equipIdx) {
            let result = apiClient.delete(xemsHost() + `/new/equip/deleteEquip?equipIdx=${equipIdx}`);
            return result;
        },
        // 연동장치 조회
        getDeviceList(equipType, showUnusedModules) {
            let result = apiClient.get(
                xemsHost() + `/new/equip/getDeviceList?equipType=${equipType}&showUnusedModules=${showUnusedModules}`
            );
            return result;
        },
        // 연동모듈 조회
        getModuleList(equipType, showOccufiedEquip, moduleIdx, deviceIdx) {
            // 권장코드 (API Parameter 전처리)
            let params = { equipType, deviceIdx, moduleIdx, showOccufiedEquip };
            let filtered = JSON.parse(JSON.stringify(params, (key, value) => value ?? undefined));

            let result = apiClient.get(xemsHost() + `/new/equip/getModuleList`, { params: filtered });
            return result;
        },
        // 연동모듈 프로퍼티 조회
        getModulePropList(moduleIdx) {
            let result = apiClient.get(xemsHost() + `/new/equip/getModulePropList?moduleIdx=${moduleIdx}`);
            return result;
        },
        // 연동모듈 저장
        saveModule(module) {
            let result = apiClient.post(xemsHost() + `/new/equip/saveModule`, module);
            return result;
        },
        // 연동모듈 연결 설정
        prepareModuleConnect(connModule) {
            let result = apiClient.put(xemsHost() + `/new/equip/prepareModuleConnect`, connModule);
            return result;
        },
        // 연동모듈 삭제
        deleteModule(moduleIdx, isDeletePoint) {
            let result = apiClient.delete(
                xemsHost() + `/new/equip/deleteModule?moduleIdx=${moduleIdx}&isDeletePoint=${isDeletePoint}`
            );
            return result;
        },
        // 설비 개별 제어
        sendEquipCtrlCmd(ctrlCmd) {
            let result = apiClient.put(xemsHost() + `/new/equip/sendEquipCtrlCmd`, ctrlCmd);
            return result;
        },
        // 설비 멀티 제어
        sendMultiCtrlCmd(ctrlCmd) {
            let result = apiClient.put(xemsHost() + `/new/equip/sendMultiCtrlCmd`, ctrlCmd);
            return result;
        },
        // 설비 그룹 제어
        sendGroupCtrlCmd(ctrlCmd) {
            let result = apiClient.put(xemsHost() + `/new/equipSys/sendGroupCtrlCmd`, ctrlCmd);
            return result;
        },
        // 설비 실시간 상태 조회
        getEquipStatus(equipIdx) {
            let result = apiClient.get(xemsHost() + `/new/equip/getEquipStatus?equipIdx=${equipIdx}&enabled=Y`);
            return result;
        },
    },
    equip: {
        getEquipList(systemCode) {
            let result = apiClient.get(xemsHost() + `/equip/getEquipListBySystem?systemCode=${systemCode}`);
            return result;
        },

        equipStatus(equipTypeCode, zoneIdx, ctrlCode) {
            return apiClient.get(
                xemsHost() + `/equip/equipStatus?equipTypeCode=${equipTypeCode}&zoneIdx=${zoneIdx}&ctrlCode=${ctrlCode}`
            );
        },

        getEquipListZone(zoneIdx, equipTypeCode) {
            let result = apiClient.get(
                xemsHost() + `/equip/getEquipListZone?zoneIdx=${zoneIdx}&equipTypeCode=${equipTypeCode}`
            );

            return result;
        },
        equipPerform(param) {
            // 설비 성능 분석

            let timeDsvn = param.timeDsvn;
            let fromDate = param.fromDate;
            let toDate = param.toDate;
            let dvsnCode = param.dvsnCode;
            let equipTypeCode = param.equipTypeCode;
            let equipIdx = param.equipIdx;

            let result = apiClient.get(
                xemsHost() +
                    `/equip/equipPerform?timeDsvn=${timeDsvn}&fromDate=${fromDate}&toDate=${toDate}&dvsnCode=${dvsnCode}&equipTypeCode=${equipTypeCode}&equipIdx=${equipIdx}`
            );

            return result;
        },

        systemPerform(param) {
            // 설비 성능 분석

            let timeDsvn = param.timeDsvn;
            let fromDate = param.fromDate;
            let toDate = param.toDate;
            // let dvsnCode = param.dvsnCode;
            // let equipTypeCode = param.equipTypeCode;
            let systemCode = param.systemCode;
            let equipIdx = param.equipIdx;

            let result = apiClient.get(
                xemsHost() +
                    `/equip/systemPerform?timeDsvn=${timeDsvn}&fromDate=${fromDate}&toDate=${toDate}&systemCode=${systemCode}&equipIdx=${equipIdx}`
            );

            return result;
        },
    },
    equipUsage: {
        getEquipUsageList(searchStr, enabled) {
            //설비 용도 조회

            let result = null;

            if (!searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/equipUsage/getEquipUsageList`);
            } else if (searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/equipUsage/getEquipUsageList` + "?searchStr=" + searchStr);
            } else if (!searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/equipUsage/getEquipUsageList` + "?enabled=" + enabled);
            } else {
                result = apiClient.get(
                    xemsHost() + `/equipUsage/getEquipUsageList` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            }

            return result;
        },
        getEquipUsage(equipUsageCode) {
            //설비 용도 상세 조회
            return apiClient.get(xemsHost() + `/equipUsage/getEquipUsage` + "?equipUsageCode=" + equipUsageCode);
        },
        insertEquipUsage(param) {
            //설비 용도 등록

            // equipUsageCode	String(20)	설비 용도 코드	Y
            // equipUsageCodeName	String(50)	설비 용도 코드명	Y
            // equipUsageDetailDsc	String(100)	설비 용도 상세
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/equipUsage/insertEquipUsage`, param);
        },
        updateEquipUsage(param) {
            //설비 용도 수정

            // equipUsageCode	String(20)	설비 용도 코드	Y
            // equipUsageCodeName	String(50)	설비 용도 코드명	Y
            // equipUsageDetailDsc	String(100)	설비 용도 상세
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/equipUsage/updateEquipUsage`, param);
        },
        deleteEquipUsage(equipUsageCode) {
            //설비 용도 삭제

            //  equipUsageCode	String(20)	설비 용도 코드	Y
            return apiClient.delete(xemsHost() + `/equipUsage/deleteEquipUsage?equipUsageCode=` + equipUsageCode);
        },
    },
    equipType: {
        getEquipPropList(equipTypeCode, enabled, ctrlOptCode) {
            //설비 설정값 조회

            let query = "";

            if (equipTypeCode || enabled || ctrlOptCode) {
                query = "?";
            }

            if (equipTypeCode) {
                query += `equipTypeCode=${equipTypeCode}&`;
            }

            if (enabled) {
                query += `enabled=${enabled}&`;
            }

            if (ctrlOptCode) {
                query += `ctrlOptCode=${ctrlOptCode}&`;
            }

            return apiClient.get(xemsHost() + `/equipProp/getPropList${query}`);
        },
        insertEquipTypeProps(param) {
            //설비 설정값 등록

            //equipTypeCode : 설비 유형코드 - Hidden
            //equipPropCode : 설정값 코드
            //equipPropName : 설정값명
            //unitLargeCode : 공백처리
            //unitSmallCode : 공백처리
            //propDesc : 비고
            //enabled : 사용여부

            return apiClient.post(xemsHost() + `/equipProp/insertEquipProp`, param);
        },
        removeEquipTypeProps(param) {
            //설비 설정값 삭제

            //equipTypeCode : 설비 유형코드 - Hidden
            //equipPropCode : 설정값 코드
            //equipPropName : 설정값명
            //unitLargeCode : 공백처리
            //unitSmallCode : 공백처리
            //propDesc : 비고
            //enabled : 사용여부

            return apiClient.post(xemsHost() + `/equipProp/deleteEquipProp`, param);
        },

        getEquipTypeList(searchStr, enabled) {
            //설비 유형 조회

            let result = null;

            if (!searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/equipType/getEquipTypeList`);
            } else if (searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/equipType/getEquipTypeList` + "?searchStr=" + searchStr);
            } else if (!searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/equipType/getEquipTypeList` + "?enabled=" + enabled);
            } else {
                result = apiClient.get(
                    xemsHost() + `/equipType/getEquipTypeList` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            }

            return result;
        },
        getEquipType(equipTypeCode) {
            //설비 유형 상세 조회
            return apiClient.get(xemsHost() + `/equipType/getEquipType` + "?equipTypeCode=" + equipTypeCode);
        },
        /**
         * NOTE - 조회 시 상위 설비유형 목록 조회
         * @param {String} equipTypeCode
         * @returns
         */
        getUpperEquipType(equipTypeCode) {
            //설비 유형 상세 조회
            return apiClient.get(
                xemsHost() +
                    `/equipType/getEquipTypeList` +
                    "?equipTypeCode=" +
                    equipTypeCode +
                    "&exTypeCode=" +
                    equipTypeCode
            );
        },
        /**
         * NOTE - 신규 등록시 상위 설비유형 목록 조회
         * @returns
         */
        getNewUpperEquipType() {
            //설비 유형 상세 조회
            return apiClient.get(xemsHost() + `/equipType/getEquipTypeList`);
        },
        insertEquipType(param) {
            //설비 유형 등록

            // equipTypeCode	String(20)	설비 용도 코드	Y
            // equipTypeCodeName	String(50)	설비 용도 코드명	Y
            // equipTypemDetailDsc	String(100)	설비 용도 상세
            // iconFilePath	String(100)	설비 아이콘 파일 경로
            // upperTypeCode String(20) 상위 설비유형 코드
            // enabled	String(10)	사용여부	Y

            /**
             *  NOTE - 설비 유형 수정 Form-Data
             *
             */
            let form = new FormData();
            form.append("equipTypeCode", param.equipTypeCode);
            form.append("equipTypeCodeName", param.equipTypeCodeName);
            if (param.equipTypemDetailDsc !== null) form.append("equipTypemDetailDsc", param.equipTypemDetailDsc);
            form.append("iconFile", param.iconFilePath);
            form.append("enabled", param.enabled);
            form.append("upperTypeCode", param.upperTypeCode);
            console.log(form);

            return apiClient.post(xemsHost() + `/equipType/insertEquipType`, form);
        },
        updateEquipType(param) {
            //설비 유형 수정

            // equipTypeCode	String(20)	설비 용도 코드	Y
            // equipTypeCodeName	String(50)	설비 용도 코드명	Y
            // equipTypemDetailDsc	String(100)	설비 용도 상세
            // upperTypeCode String(20) 상위 설비유형 코드
            // iconFilePath	String(100)	설비 아이콘 파일 경로
            // enabled	String(10)	사용여부	Y

            /**
             *  NOTE - 설비 유형 수정 Form-Data
             *
             */
            let form = new FormData();
            form.append("equipTypeCode", param.equipTypeCode);
            form.append("equipTypeCodeName", param.equipTypeCodeName);
            form.append("equipTypemDetailDsc", param.equipTypemDetailDsc);
            form.append("iconFile", param.iconFilePath);
            form.append("enabled", param.enabled);
            form.append("upperTypeCode", param.upperTypeCode);

            return apiClient.post(xemsHost() + `/equipType/updateEquipType`, form);
        },
        deleteEquipType(equipTypeCode) {
            //설비 용도 삭제

            // equipTypeCode	String(20)	설비 유형 코드	Y
            return apiClient.delete(xemsHost() + `/equipType/deleteEquipType?equipTypeCode=` + equipTypeCode);
        },
    },
    equipControl: {
        // 설비 제어 스케줄 목록 조회
        getSchdCtrlList() {
            return apiClient.get(xemsHost() + `/new/schdCtrl/getSchdCtrlList`);
        },
        // 설비 제어 스케줄 상세정보 조회
        getEquipCtrlSchDetail(index) {
            return apiClient.get(xemsHost() + `/new/schdCtrl/getSchdCtrl?schdCtrlIdx=` + index);
        },
        // 설비 제어 스케줄 적용 여부 전체 ON/OFF
        // updateEquipCtrlSch(enabled) {
        // 	return apiClient.post(xemsHost() + `/equipCtrlSch/updateEquipCtrlSch?enabled=` + enabled);
        // },
        // 설비 제어 스케줄 추가
        saveEquipCtrlSch(schdItem) {
            let result = apiClient.post(xemsHost() + `/new/schdCtrl/saveSchdCtrl`, schdItem);
            return result;
        },
        // 설비 제어 스케줄 삭제
        deleteEquipCtrlSch(index) {
            return apiClient.delete(xemsHost() + `/new/schdCtrl/deleteSchdCtrl?schdCtrlIdx=` + index);
        },
        getEquipCtrlList(equipTypeCode) {
            return apiClient.get(xemsHost() + `/equipCtrl/getEquipCtrlList?equipTypeCode=` + equipTypeCode);
        },
        // 스케줄 이벤트 이력 조회
        getSchdEventHistList(schdCtrlIdx, searchDate) {
            let result = apiClient.get(
                xemsHost() + `/new/schdCtrl/getSchdEventHistList?schdCtrlIdx=${schdCtrlIdx}&eventDate=${searchDate}`
            );
            return result;
        },
        // 스케줄 이벤트 로그 조회
        getSchdEventLogList(eventExecIdx) {
            return apiClient.get(xemsHost() + `/new/schdCtrl/getSchdEventLogList?eventExecIdx=${eventExecIdx}`);
        },
        // 스케줄 이벤트 로그 상세 조회
        getSchdEventLogDetail(eventExecIdx, logDate) {
            return apiClient.get(
                xemsHost() + `/new/schdCtrl/getSchdEventLogDetail?eventExecIdx=${eventExecIdx}&logDate=${logDate}`
            );
        },
        // 스케줄 상태 설정
        setSchdCtrlEnabled(enabledCtrl) {
            return apiClient.put(xemsHost() + `/new/schdCtrl/setSchdCtrlEnabled`, enabledCtrl);
        },
        // 스케줄 최종 이벤트 재실행 설정
        setSchdCtrlLastEventRetry(retryItem) {
            return apiClient.put(xemsHost() + `/new/schdCtrl/setSchdCtrlLastEventRetry`, retryItem);
        },
        getEquipGroupList(nodeType, equipType, zoneIdx) {
            let params = { nodeType, equipType, zoneIdx };
            let filtered = JSON.parse(JSON.stringify(params, (key, value) => value ?? undefined));
            let result = apiClient.get(xemsHost() + `/new/equipSys/getSysNodeList`, { params: filtered });
            return result;
        },
    },
    meterType: {
        getMeterTypeList(searchStr, enabled) {
            //계측기 유형 조회

            let result = null;

            if (!searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/meterType/getMeterTypeList`);
            } else if (searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/meterType/getMeterTypeList` + "?searchStr=" + searchStr);
            } else if (!searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/meterType/getMeterTypeList` + "?enabled=" + enabled);
            } else {
                result = apiClient.get(
                    xemsHost() + `/meterType/getMeterTypeList` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            }
            return result;
        },
        getMeterType(meterTypeCode) {
            //계측기 유형 상세 조회
            return apiClient.get(xemsHost() + `/meterType/getMeterType` + "?meterTypeCode=" + meterTypeCode);
        },
        insertMeterType(param) {
            //계측기 유형 등록

            // meterTypeCode	String(20)	계측기 용도 코드	Y
            // meterTypeCodeName	String(50)	계측기 용도 코드명	Y
            // meterTypemDetailDsc	String(100)	계측기 용도 상세
            // iconFilePath	String(100)	계측기 아이콘 파일 경로
            // enabled	String(10)	사용여부	Y

            /**
             *  NOTE - 계측기 유형 수정 Form-Data
             *
             */
            let form = new FormData();
            form.append("meterTypeCode", param.meterTypeCode);
            form.append("meterTypeCodeName", param.meterTypeCodeName);
            form.append("meterTypemDetailDsc", param.meterTypemDetailDsc);
            form.append("iconFile", param.iconFilePath);
            form.append("enabled", param.enabled);

            return apiClient.post(xemsHost() + `/meterType/insertMeterType`, form);
        },
        updateMeterType(param) {
            //계측기 유형 수정

            // meterTypeCode	String(20)	계측기 용도 코드	Y
            // meterTypeCodeName	String(50)	계측기 용도 코드명	Y
            // meterTypemDetailDsc	String(100)	계측기 용도 상세
            // iconFilePath	String(100)	계측기 아이콘 파일 경로
            // enabled	String(10)	사용여부	Y

            let form = new FormData();
            form.append("meterTypeCode", param.meterTypeCode);
            form.append("meterTypeCodeName", param.meterTypeCodeName);
            form.append("meterTypemDetailDsc", param.meterTypemDetailDsc);
            form.append("iconFile", param.iconFilePath);
            form.append("enabled", param.enabled);

            return apiClient.post(xemsHost() + `/meterType/updateMeterType`, form);
        },
        deleteMeterType(meterTypeCode) {
            //계측기 유형 삭제

            // meterTypeCode	String(20)	계측기 유형 코드	Y
            // console.log(result);
            return apiClient.delete(xemsHost() + `/meterType/deleteMeterType?meterTypeCode=${meterTypeCode}`);
        },
    },
    equipModel: {
        getEquipModelList(cmnctnPrtclCode, searchStr, enabled) {
            //설비 모델 조회

            let result = null;

            if (!cmnctnPrtclCode && !searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/equipModel/getEquipModelList`);
            } else if (cmnctnPrtclCode && !searchStr && !enabled) {
                result = apiClient.get(
                    xemsHost() + `/equipModel/getEquipModelList` + "?cmnctnPrtclCode=" + cmnctnPrtclCode
                );
            } else if (!cmnctnPrtclCode && searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/equipModel/getEquipModelList` + "?searchStr=" + searchStr);
            } else if (!cmnctnPrtclCode && !searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/equipModel/getEquipModelList` + "?enabled=" + enabled);
            } else if (cmnctnPrtclCode && searchStr && !enabled) {
                result = apiClient.get(
                    xemsHost() +
                        `/equipModel/getEquipModelList` +
                        "?cmnctnPrtclCode=" +
                        cmnctnPrtclCode +
                        "&searchStr=" +
                        searchStr
                );
            } else if (cmnctnPrtclCode && !searchStr && enabled) {
                result = apiClient.get(
                    xemsHost() +
                        `/equipModel/getEquipModelList` +
                        "?cmnctnPrtclCode=" +
                        cmnctnPrtclCode +
                        "&enabled=" +
                        enabled
                );
            } else if (!cmnctnPrtclCode && searchStr && enabled) {
                result = apiClient.get(
                    xemsHost() + `/equipModel/getEquipModelList` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            } else {
                result = apiClient.get(
                    xemsHost() +
                        `/equipModel/getEquipModelList` +
                        "?cmnctnPrtclCode=" +
                        cmnctnPrtclCode +
                        "&searchStr=" +
                        searchStr +
                        "&enabled=" +
                        enabled
                );
            }

            return result;
        },
        getEquipModelCodeList() {
            //설비 모델 코드 리스트
            return apiClient.get(xemsHost() + `/equipModel/getEquipModelCodeList`);
        },
        getEquipModel(data) {
            //설비 모델 상세 조회
            let param = "?modelCode=" + data.modelCode + "&modelVrsn=" + data.modelVrsn;

            return apiClient.get(xemsHost() + `/equipModel/getEquipModel` + param);
        },
        insertEquipModel(param) {
            //설비 모델 등록

            // modelCode	String(30)	모델 코드	Y
            // modelVrsn	String(10)	모델 버전	Y
            // modelName	varchar(100)	모델명	Y
            // equipTypeCode	String(10)	설비유형 코드	Y
            // cmnctnPrtclCode	varchar(10)	통신 프로토콜 코드	Y
            // prtclConnOption	varchar(1000)	프로토콜 연결 옵션
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/equipModel/insertEquipModel`, param);
        },
        updateEquipModel(param) {
            //설비 유형 수정

            // modelCode	String(30)	모델 코드	Y
            // modelVrsn	String(10)	모델 버전	Y
            // modelName	varchar(100)	모델명	Y
            // equipTypeCode	String(10)	설비유형 코드	Y
            // cmnctnPrtclCode	varchar(10)	통신 프로토콜 코드	Y
            // prtclConnOption	varchar(1000)	프로토콜 연결 옵션
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/equipModel/updateEquipModel`, param);
        },
        deleteEquipModel(data) {
            //설비 모델 삭제

            // modelCode	String(30)	모델 코드	Y
            // modelVrsn	String(10)	모델 버전	Y
            let param = "?modelCode=" + data.modelCode + "&modelVrsn=" + data.modelVrsn;

            return apiClient.delete(xemsHost() + `/equipModel/deleteEquipModel` + param);
        },
    },
    equipGather: {
        getEquipGatherList(data) {
            //설비 수집항목 조회
            let result = null;
            // modelCode	varchar(30)	모델 코드	N
            // modelVrsn	varchar(10)	모델 버전	N
            // gatherItemTypeCode	String(20)	수집 항목 유형 코드	N
            // searchStr	String(50)	수집 항목명	N
            // enabled	String(5)	사용여부	N

            // data.modelCode && data.modelVrsn && data.gatherItemTypeCode && data.searchStr && data.enabled

            let param = "";

            if (!data.modelCode && !data.modelVrsn && !data.gatherItemTypeCode && !data.searchStr && !data.enabled) {
                param = "";
            } else if (
                data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?modelCode=" + data.modelCode;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?modelVrsn=" + data.modelVrsn;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?gatherItemTypeCode=" + data.gatherItemTypeCode;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param = "?searchStr=" + data.searchStr;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param = "?enabled=" + data.enabled;
            } else if (
                data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?modelCode=" + data.modelCode + "&modelVrsn=" + data.modelVrsn;
            } else if (
                data.modelCode &&
                !data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?modelCode=" + data.modelCode + "&gatherItemTypeCode=" + data.gatherItemTypeCode;
            } else if (
                data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param = "?modelCode=" + data.modelCode + "&searchStr=" + data.searchStr;
            } else if (
                data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param = "?modelCode=" + data.modelCode + "&enabled=" + data.enabled;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?modelVrsn=" + data.modelVrsn + "&gatherItemTypeCode=" + data.gatherItemTypeCode;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param = "?modelVrsn=" + data.modelVrsn + "&searchStr=" + data.searchStr;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param = "?modelVrsn=" + data.modelVrsn + "&enabled=" + data.enabled;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param = "?gatherItemTypeCode=" + data.gatherItemTypeCode + "&searchStr=" + data.searchStr;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param = "?gatherItemTypeCode=" + data.gatherItemTypeCode + "&enabled=" + data.enabled;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                data.searchStr &&
                data.enabled
            ) {
                param = "?searchStr=" + data.searchStr + "&enabled=" + data.enabled;
            } else if (
                data.modelCode &&
                data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param =
                    "?modelCode=" +
                    data.modelCode +
                    "&modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode;
            } else if (
                data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param =
                    "?modelCode=" + data.modelCode + "&modelVrsn=" + data.modelVrsn + "&searchStr=" + data.searchStr;
            } else if (
                data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param = "?modelCode=" + data.modelCode + "&modelVrsn=" + data.modelVrsn + "&enabled=" + data.enabled;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param =
                    "?modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&searchStr=" +
                    data.searchStr;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param =
                    "?modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&enabled=" +
                    data.enabled;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                data.gatherItemTypeCode &&
                data.searchStr &&
                data.enabled
            ) {
                param =
                    "?gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&searchStr=" +
                    data.searchStr +
                    "&enabled=" +
                    data.enabled;
            } else if (data.modelCode && data.modelVrsn && data.gatherItemTypeCode && data.searchStr && !data.enabled) {
                param =
                    "?modelCode=" +
                    data.modelCode +
                    "&modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&searchStr=" +
                    data.searchStr;
            } else if (data.modelCode && data.modelVrsn && data.gatherItemTypeCode && !data.searchStr && data.enabled) {
                param =
                    "?modelCode=" +
                    data.modelCode +
                    "&modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&enabled=" +
                    data.enabled;
            } else {
                param =
                    "?modelCode=" +
                    data.modelCode +
                    "&modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&searchStr=" +
                    data.searchStr +
                    "&enabled=" +
                    data.enabled;
            }

            result = apiClient.get(xemsHost() + `/equipGather/getEquipGatherList` + param);

            return result;
        },
        getEquipGather(data) {
            //설비 수집항목 상세 조회
            let param =
                "?modelCode=" +
                data.modelCode +
                "&modelVrsn=" +
                data.modelVrsn +
                "&gatherItemCode=" +
                data.gatherItemCode;

            return apiClient.get(xemsHost() + `/equipGather/getEquipGather` + param);
        },
        insertEquipGather(param) {
            //설비 수집항목 등록

            // modelCode	varchar(30)	모델 코드	Y
            // modelVrsn	varchar(10)	모델 버전	Y
            // gatherItemCode	varchar(20)	수집 항목 코드	Y
            // gatherItemName	varchar(50)	항목명	Y
            // ioDrctnCode	varchar(10)	입출력 방향 코드	Y
            // gatherItemTypeCode	varchar(10)	수집 항목 유형 코드	Y
            // dataScale	int(10)	자료 스케일
            // convFactor	float	환산계수
            // optionProp	varchar(1000)	계측 옵션 속성
            // trgthingCode	varchar(10)	대상 물질 코드
            // itemPropCode	varchar(10)	항목 속성 코드
            // unitLargeCode	varchar(10)	단위 대분류 코드
            // unitSmallCode	varchar(10)	단위 소분류 코드
            // etcInfo	varchar(100)	기타 정보
            // enabled	String(10)	사용여부	Y
            return apiClient.post(xemsHost() + `/equipGather/insertEquipGather`, param);
        },
        updateEquipGather(param) {
            //설비 수집항목 수정

            // modelCode	varchar(30)	모델 코드	Y
            // modelVrsn	varchar(10)	모델 버전	Y
            // gatherItemCode	varchar(20)	수집 항목 코드	Y
            // gatherItemName	varchar(50)	수집 항목명
            // ioDrctnCode	varchar(10)	입출력 방향 코드
            // gatherItemTypeCode	varchar(10)	수집 항목 유형 코드
            // dataScale	int(10)	자료 스케일
            // convFactor	float	환산계수
            // optionProp	varchar(1000)	계측 옵션 속성
            // trgthingCode	varchar(10)	대상 물질 코드
            // itemPropCode	varchar(10)	항목 속성 코드
            // unitLargeCode	varchar(10)	단위 대분류 코드
            // unitSmallCode	varchar(10)	단위 소분류 코드
            // etcInfo	varchar(100)	기타 정보
            // enabled	String(10)	사용여부
            return apiClient.post(xemsHost() + `/equipGather/updateEquipGather`, param);
        },
        deleteEquipGather(data) {
            //설비 수집항목 삭제

            // modelCode	varchar(30)	모델 코드	Y
            // modelVrsn	varchar(10)	모델 버전	Y
            // gatherItemCode	varchar(20)	수집 항목 코드	Y
            let param =
                "?modelCode=" +
                data.modelCode +
                "&modelVrsn=" +
                data.modelVrsn +
                "&gatherItemCode=" +
                data.gatherItemCode;

            return apiClient.delete(xemsHost() + `/equipGather/deleteEquipGather` + param);
        },
    },
    meterModel: {
        getMeterModelList(cmnctnPrtclCode, searchStr, enabled) {
            //계측기 모델 조회

            let result = null;

            if (!cmnctnPrtclCode && !searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/meterModel/getMeterModelList`);
            } else if (cmnctnPrtclCode && !searchStr && !enabled) {
                result = apiClient.get(
                    xemsHost() + `/meterModel/getMeterModelList` + "?cmnctnPrtclCode=" + cmnctnPrtclCode
                );
            } else if (!cmnctnPrtclCode && searchStr && !enabled) {
                result = apiClient.get(xemsHost() + `/meterModel/getMeterModelList` + "?searchStr=" + searchStr);
            } else if (!cmnctnPrtclCode && !searchStr && enabled) {
                result = apiClient.get(xemsHost() + `/meterModel/getMeterModelList` + "?enabled=" + enabled);
            } else if (cmnctnPrtclCode && searchStr && !enabled) {
                result = apiClient.get(
                    xemsHost() +
                        `/meterModel/getMeterModelList` +
                        "?cmnctnPrtclCode=" +
                        cmnctnPrtclCode +
                        "&searchStr=" +
                        searchStr
                );
            } else if (cmnctnPrtclCode && !searchStr && enabled) {
                result = apiClient.get(
                    xemsHost() +
                        `/meterModel/getMeterModelList` +
                        "?cmnctnPrtclCode=" +
                        cmnctnPrtclCode +
                        "&enabled=" +
                        enabled
                );
            } else if (!cmnctnPrtclCode && searchStr && enabled) {
                result = apiClient.get(
                    xemsHost() + `/meterModel/getMeterModelList` + "?searchStr=" + searchStr + "&enabled=" + enabled
                );
            } else {
                result = apiClient.get(
                    xemsHost() +
                        `/meterModel/getMeterModelList` +
                        "?cmnctnPrtclCode=" +
                        cmnctnPrtclCode +
                        "&searchStr=" +
                        searchStr +
                        "&enabled=" +
                        enabled
                );
            }

            return result;
        },
        getMeterModelCodeList() {
            //계측기 모델 코드 리스트
            return apiClient.get(xemsHost() + `/meterModel/getMeterModelCodeList`);
        },
        getMeterModel(data) {
            //계측기 모델 상세 조회
            let param = "?modelCode=" + data.modelCode + "&modelVrsn=" + data.modelVrsn;

            return apiClient.get(xemsHost() + `/meterModel/getMeterModel` + param);
        },
        insertMeterModel(param) {
            //계측기 모델 등록

            // modelCode	String(30)	모델 코드	Y
            // modelVrsn	String(10)	모델 버전	Y
            // modelName	varchar(100)	모델명	Y
            // meterTypeCode	varchar(10)	계측기유형 코드	Y
            // cmnctnPrtclCode	varchar(10)	통신 프로토콜 코드
            // prtclConnOption	varchar(1000)	프로토콜 연결 옵션
            // enabled	String(10)	사용여부	Y
            return apiClient.post(xemsHost() + `/meterModel/insertMeterModel`, param);
        },
        updateMeterModel(param) {
            //계측기 모델 수정

            // modelCode	String(30)	모델 코드	Y
            // modelVrsn	String(10)	모델 버전	Y
            // modelName	varchar(100)	모델명
            // meterTypeCode	varchar(10)	계측기유형 코드
            // cmnctnPrtclCode	varchar(10)	통신 프로토콜 코드
            // prtclConnOption	varchar(1000)	프로토콜 연결 옵션
            // enabled	String(10)	사용여부
            return apiClient.post(xemsHost() + `/meterModel/updateMeterModel`, param);
        },
        deleteMeterModel(data) {
            //계측기 모델 삭제

            // modelCode	String(30)	모델 코드	Y
            // modelVrsn	String(10)	모델 버전	Y
            let param = "?modelCode=" + data.modelCode + "&modelVrsn=" + data.modelVrsn;

            return apiClient.delete(xemsHost() + `/meterModel/deleteMeterModel` + param);
        },
    },
    meterGather: {
        getMeterGatherList(data) {
            //계측기 수집항목 조회
            let result = null;
            // modelCode	varchar(30)	모델 코드	N
            // modelVrsn	varchar(10)	모델 버전	N
            // gatherItemTypeCode	String(20)	수집 항목 유형 코드	N
            // searchStr	String(50)	수집 항목명	N
            // enabled	String(5)	사용여부	N

            // data.modelCode && data.modelVrsn && data.gatherItemTypeCode && data.searchStr && data.enabled

            let param = "";

            if (!data.modelCode && !data.modelVrsn && !data.gatherItemTypeCode && !data.searchStr && !data.enabled) {
                param = "";
            } else if (
                data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?modelCode=" + data.modelCode;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?modelVrsn=" + data.modelVrsn;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?gatherItemTypeCode=" + data.gatherItemTypeCode;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param = "?searchStr=" + data.searchStr;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param = "?enabled=" + data.enabled;
            } else if (
                data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?modelCode=" + data.modelCode + "&modelVrsn=" + data.modelVrsn;
            } else if (
                data.modelCode &&
                !data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?modelCode=" + data.modelCode + "&gatherItemTypeCode=" + data.gatherItemTypeCode;
            } else if (
                data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param = "?modelCode=" + data.modelCode + "&searchStr=" + data.searchStr;
            } else if (
                data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param = "?modelCode=" + data.modelCode + "&enabled=" + data.enabled;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param = "?modelVrsn=" + data.modelVrsn + "&gatherItemTypeCode=" + data.gatherItemTypeCode;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param = "?modelVrsn=" + data.modelVrsn + "&searchStr=" + data.searchStr;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param = "?modelVrsn=" + data.modelVrsn + "&enabled=" + data.enabled;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param = "?gatherItemTypeCode=" + data.gatherItemTypeCode + "&searchStr=" + data.searchStr;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param = "?gatherItemTypeCode=" + data.gatherItemTypeCode + "&enabled=" + data.enabled;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                !data.gatherItemTypeCode &&
                data.searchStr &&
                data.enabled
            ) {
                param = "?searchStr=" + data.searchStr + "&enabled=" + data.enabled;
            } else if (
                data.modelCode &&
                data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                !data.enabled
            ) {
                param =
                    "?modelCode=" +
                    data.modelCode +
                    "&modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode;
            } else if (
                data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param =
                    "?modelCode=" + data.modelCode + "&modelVrsn=" + data.modelVrsn + "&searchStr=" + data.searchStr;
            } else if (
                data.modelCode &&
                data.modelVrsn &&
                !data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param = "?modelCode=" + data.modelCode + "&modelVrsn=" + data.modelVrsn + "&enabled=" + data.enabled;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                data.gatherItemTypeCode &&
                data.searchStr &&
                !data.enabled
            ) {
                param =
                    "?modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&searchStr=" +
                    data.searchStr;
            } else if (
                !data.modelCode &&
                data.modelVrsn &&
                data.gatherItemTypeCode &&
                !data.searchStr &&
                data.enabled
            ) {
                param =
                    "?modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&enabled=" +
                    data.enabled;
            } else if (
                !data.modelCode &&
                !data.modelVrsn &&
                data.gatherItemTypeCode &&
                data.searchStr &&
                data.enabled
            ) {
                param =
                    "?gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&searchStr=" +
                    data.searchStr +
                    "&enabled=" +
                    data.enabled;
            } else if (data.modelCode && data.modelVrsn && data.gatherItemTypeCode && data.searchStr && !data.enabled) {
                param =
                    "?modelCode=" +
                    data.modelCode +
                    "&modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&searchStr=" +
                    data.searchStr;
            } else if (data.modelCode && data.modelVrsn && data.gatherItemTypeCode && !data.searchStr && data.enabled) {
                param =
                    "?modelCode=" +
                    data.modelCode +
                    "&modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&enabled=" +
                    data.enabled;
            } else {
                param =
                    "?modelCode=" +
                    data.modelCode +
                    "&modelVrsn=" +
                    data.modelVrsn +
                    "&gatherItemTypeCode=" +
                    data.gatherItemTypeCode +
                    "&searchStr=" +
                    data.searchStr +
                    "&enabled=" +
                    data.enabled;
            }

            result = apiClient.get(xemsHost() + `/meterGather/getMeterGatherList` + param);

            return result;
        },
        getMeterGather(data) {
            //계측기 수집항목 상세 조회
            let param =
                "?modelCode=" +
                data.modelCode +
                "&modelVrsn=" +
                data.modelVrsn +
                "&gatherItemCode=" +
                data.gatherItemCode;

            return apiClient.get(xemsHost() + `/meterGather/getMeterGather` + param);
        },

        insertMeterGather(param) {
            //계측기 수집항목 등록

            // modelCode	varchar(30)	모델 코드	Y
            // modelVrsn	varchar(10)	모델 버전	Y
            // gatherItemCode	varchar(20)	수집 항목 코드	Y
            // gatherItemName	varchar(50)	항목명	Y
            // ioDrctnCode	varchar(10)	입출력 방향 코드
            // gatherItemTypeCode	varchar(10)	수집 항목 유형 코드
            // dataScale	int(10)	자료 스케일
            // convFactor	float	환산계수
            // optionProp	varchar(1000)	계측 옵션 속성
            // trgthingCode	varchar(10)	대상 물질 코드
            // itemPropCode	varchar(10)	항목 속성 코드
            // unitLargeCode	varchar(10)	단위 대분류 코드
            // unitSmallCode	varchar(10)	단위 소분류 코드
            // etcInfo	varchar(100)	기타 정보
            // enabled	String(10)	사용여부	Y

            return apiClient.post(xemsHost() + `/meterGather/insertMeterGather`, param);
        },
        updateMeterGather(param) {
            //계측기 수집항목 수정

            // modelCode	varchar(30)	모델 코드	Y
            // modelVrsn	varchar(10)	모델 버전	Y
            // gatherItemCode	varchar(20)	수집 항목 코드	Y
            // gatherItemName	varchar(50)	항목명	Y
            // ioDrctnCode	varchar(10)	입출력 방향 코드
            // gatherItemTypeCode	varchar(10)	수집 f항목 유형 코드
            // dataScale	int(10)	자료 스케일
            // convFactor	float	환산계수
            // optionProp	varchar(1000)	계측 옵션 속성
            // trgthingCode	varchar(10)	대상 물질 코드
            // itemPropCode	varchar(10)	항목 속성 코드
            // unitLargeCode	varchar(10)	단위 대분류 코드
            // unitSmallCode	varchar(10)	단위 소분류 코드
            // etcInfo	varchar(100)	기타 정보
            // enabled	String(10)	사용여부	Y
            return apiClient.post(xemsHost() + `/meterGather/updateMeterGather`, param);
        },
        deleteMeterGather(data) {
            //계측기 수집항목 삭제

            // modelCode	varchar(30)	모델 코드	Y
            // modelVrsn	varchar(10)	모델 버전	Y
            // gatherItemCode	varchar(20)	수집 항목 코드	Y
            let param =
                "?modelCode=" +
                data.modelCode +
                "&modelVrsn=" +
                data.modelVrsn +
                "&gatherItemCode=" +
                data.gatherItemCode;

            return apiClient.delete(xemsHost() + `/meterGather/deleteMeterGather` + param);
        },
    },
    pointInfo: {
        getPointList() {
            return apiClient.get(xemsHost() + `/pointInfo/getPointList`);
        },
        getPointListNew() {
            return apiClient.get(xemsHost() + `/pointInfo/getPointInfoList`);
        },
        // made by woonsik, 240426
        getPointInfoList(ptGroup) {
            return apiClient.get(xemsHost() + `/pointInfo/getPointInfoList?ptGroup=${ptGroup}`);
        },
        // made by badblock, 240808
        getPointInfoListById(ptIdxs) {
            const queryString = new URLSearchParams({ ptIdxs }).toString();
            // let queryString;
            // if(Array.isArray(ptIdxs)) queryString = ptIdxs.toString();
            // console.log("queryString", queryString)
            return apiClient.get(xemsHost() + `/pointInfo/getPointInfoListById?${queryString}`);
        },
        // made by woonsik, 240426
        getPtGroupList(type) {
            let ptType = "";
            if (type == 0) ptType = "Physical";
            else ptType = "Logical";
            return apiClient.get(xemsHost() + `/pointInfo/getPointGroupList?type=${ptType}`);
        },
        getPointDetailNew(ptIdx) {
            return apiClient.get(xemsHost() + `/pointInfo/getPointDetail?ptIdx=${ptIdx}`);
        },
        insertPointInfo(data) {
            return apiClient.post(xemsHost() + `/pointInfo/insertPointInfo`, data);
        },
        updatePointInfo(data) {
            return apiClient.post(xemsHost() + `/pointInfo/updatePointInfo`, data);
        },
        deletePointInfo(ptIdx) {
            return apiClient.delete(xemsHost() + `/pointInfo/deletePointInfo?ptIdx=${ptIdx}`);
        },
        getpointByGroup() {
            return apiClient.get(xemsHost() + `/pointInfo/getpointByGroup`);
        },
        bulkInsert(data) {
            return apiClient.get(xemsHost() + "/pointInfo/bulkInsert", data);
        },
        checkDuplPointInfo(ptAddr) {
            return apiClient.get(xemsHost() + `/pointInfo/checkDuplPointInfo?ptAddr=${ptAddr}`);
        },

        getPointLog(data) {
            let param =
                "?ptAddr=" +
                data.ptAddr +
                "&timeDsvn=" +
                data.timeDsvn +
                "&fromDate=" +
                data.fromDate +
                "&toDate=" +
                data.toDate;

            return apiClient.get(xemsHost() + `/pointInfo/getPointLog` + param);
        },
        searchPoint(ptGroup, ptAddr) {
            return apiClient.get(xemsHost() + `/pointInfo/searchPoint`, { params: { ptGroup, ptAddr } });
        },
        searchPointAM() {
            return apiClient.get(xemsHost() + `/pointInfo/searchPointAM`);
        },
        searchPointIM() {
            return apiClient.get(xemsHost() + `/pointInfo/searchPointIM`);
        },
        insertPointAndFacilityMap(data) {
            //console.log("backEndApi insertPointAndFacilityMap", data);

            return apiClient.post(xemsHost() + `/pointInfo/insertPointAndFacilityMap`, data);
        },
        updatePointAndFacilityMap(data) {
            return apiClient.post(xemsHost() + `/pointInfo/updatePointAndFacilityMap`, data);
        },
        deletePointAndFacilityMap(data) {
            return apiClient.post(xemsHost() + `/pointInfo/deletePointAndFacilityMap`, data);
        },

        searchPointGroup() {
            return apiClient.get(xemsHost() + `/pointInfo/searchPointGroup`);
        },
        getPointGroupList(query) {
            let queryString = "";
            if (query) {
                queryString = `?type=${query}`;
            }

            return apiClient.get(xemsHost() + `/pointInfo/getPointGroupList${queryString}`);
        },
        getPointGroupDetailList() {
            return apiClient.get(xemsHost() + `/pointInfo/getPointGroupDetailList`);
        },
        getPoint(ptAddr) {
            return apiClient.get(xemsHost() + `/pointInfo/getPoint`, { params: { ptAddr } });
        },
        insertPoint(data) {
            return apiClient.post(xemsHost() + `/point/insertPoint`, data);
        },
        bulkInsertPoint(data) {
            return apiClient.post(xemsHost() + `/point/bulkInsertPoint`, data);
        },
        updatePoint(data) {
            return apiClient.post(xemsHost() + `/point/updatePoint`, data);
        },
        deletePoint(ptAddr) {
            return apiClient.get(xemsHost() + `/point/deletePoint`, { params: { ptAddr } });
        },
        getPointInfoVw(ptAddr) {
            return apiClient.get(xemsHost() + `/pointInfo/getPointInfoVw?ptAddr=${ptAddr}`);
        },
        // syncPoint() {
        //   return apiClient.get(xemsHost() + `/point/syncPoint`);
        // },
        // clearPoint() {
        //   return apiClient.get(xemsHost() + `/point/clearPoint`);
        // },
        // total real
        searchTotalReal(ptAddr) {
            return apiClient.get(xemsHost() + `/point/searchTotalReal`, { params: { ptAddr } });
        },
        // total real
        searchTotalRealList() {
            return apiClient.get(xemsHost() + `/point/searchTotalRealList`);
        },
        getPointOverview(params) {
            const queryString = new URLSearchParams(params).toString();

            return apiClient.get(xemsHost() + `/pointInfo/getPointOverview?${queryString}`);
        },
    },
    sysNode: {
        raiseException(errcode) {
            return apiClient.get(xemsHost() + `/new/test/raiseException?errcode=${errcode}`);
        },
        // 계통 루트 조희
        getRootNodeList(enabled) {
            return apiClient.get(xemsHost() + `/new/equipSys/getRootNodeList?enabled=${enabled}`);
        },
        // 계통 트리 조희
        getSysNodeTree(sysNodeIdx) {
            return apiClient.get(xemsHost() + `/new/equipSys/getSysNodeTree?sysNodeIdx=${sysNodeIdx}`);
        },
        // (설비그룹)그룹노드 목록 조회
        getSysNodeList(nodeType, equipType) {
            return apiClient.get(
                xemsHost() + `/new/equipSys/getSysNodeList?nodeType=${nodeType}&equipType=${equipType}`
            );
        },
        // (설비그룹)계통내 설비목록 조회
        getIncludedEquipList(sysNodeIdx, enabled) {
            return apiClient.get(
                xemsHost() + `/new/equipSys/getIncludedEquipList?sysNodeIdx=${sysNodeIdx}&enabled=${enabled}`
            );
        },
        // (설비그룹)계통내 설비목록 상태 조회
        getIncludedEquipStatus(sysNodeIdx, equipType) {
            return apiClient.get(
                xemsHost() +
                    `/new/equipSys/getIncludedEquipStatus?sysNodeIdx=${sysNodeIdx}&equipType=${equipType}&enabled=Y&ttlInM=5&expandSearch=Y`
            );
        },
        newGetIncludedEquipStatus(params) {
            return apiClient.get(xemsHost() + `/new/equipSys/getIncludedEquipStatus`, { params: params });
        },
        // 그룹 제어명령 전송
        sendGroupCtrlCmd(ctrlCmd) {
            let result = apiClient.put(xemsHost() + `/new/equipSys/sendGroupCtrlCmd`, ctrlCmd);
            return result;
        },
        // 계통노드 추가
        addSysNode(newNode) {
            return apiClient.post(xemsHost() + `/new/equipSys/addSysNode`, newNode);
        },
        // 계통 노드 삭제
        deleteSysNode(sysNodeIdx) {
            return apiClient.delete(xemsHost() + `/new/equipSys/deleteSysNode?sysNodeIdx=${sysNodeIdx}`);
        },
        // 계통노드 연관관제점 조회
        // getRelatedPointList(sysNodeIdx) {
        // 	return apiClient.get(xemsHost() + `/new/equipSys/getRelatedPointList?sysNodeIdx=${sysNodeIdx}`);
        // },
        // 그룹제어 타임라인 조회
        getGroupCtrlTimeline(searchParams) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            return apiClient.get(xemsHost() + `/new/equipSys/getGroupCtrlTimeline`, { params: filtered });
        },
        // 그룹노드 운전현황 조회
        getGroupOprtStatus(searchParams) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            return apiClient.get(xemsHost() + `/new/equipSys/getGroupOprtStatus`, { params: filtered });
        },
        // 설비노드 운전현황 조회
        getEquipOprtStatus(searchParams) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            return apiClient.get(xemsHost() + `/new/equipSys/getEquipOprtStatus`, { params: filtered });
        },
        getGroupCtrlLog(outputIdx) {
            return apiClient.get(xemsHost() + `/outputLog/getGroupCtrlLog?outputIdx=${outputIdx}`);
        },
        getEquipCtrlLog(outputIdx) {
            return apiClient.get(xemsHost() + `/outputLog/getEquipCtrlLog?outputIdx=${outputIdx}`);
        },
        // 계통 노드 운전 현황 조회
        getSystemOprtStatus(searchParams) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            return apiClient.get(xemsHost() + `/new/equipSys/getSystemOprtStatus`, { params: filtered });
        },
        // 계통 트렌드 데이터 조회
        getSystemTrendData(searchParams) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            return apiClient.get(xemsHost() + `/new/equipSys/getSystemTrendData`, { params: filtered });
        },
        // 그룹 트렌드 데이터 조회
        getGroupTrendData(searchParams) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            return apiClient.get(xemsHost() + `/new/equipSys/getGroupTrendData`, { params: filtered });
        },
        // 설비 트렌드 데이터 조회
        getEquipTrendData(searchParams) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            return apiClient.get(xemsHost() + `/new/equipSys/getEquipTrendData`, { params: filtered });
        },
        getSumNodeTrendData(searchParams) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            return apiClient.get(xemsHost() + `/new/equipSys/getSumNodeTrendData`, { params: filtered });
        },
        getSumNodeTrendAll(searchParams) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            return apiClient.get(xemsHost() + `/new/equipSys/getSumNodeTrendAll`, { params: filtered });
        },
        // 노드 영향인자  트렌드 데이터 조회
        getRelatedPointTrendData(searchParams) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            return apiClient.get(xemsHost() + `/new/equipSys/getRelatedPointTrendData`, { params: filtered });
        },
        getRelatedPointStatus(sysNodeIdx) {
            return apiClient.get(xemsHost() + `/new/equipSys/getRelatedPointStatus?sysNodeIdx=${sysNodeIdx}`);
        },
        getSumNodePatternData(searchParams) {
            // let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => (value ?? undefined)));
            return apiClient.get(xemsHost() + `/new/equipSys/getSumNodePatternData`, { params: searchParams });
        },
        // (노드관리)계통노드 조회
        getSysNode(sysNodeIdx) {
            return apiClient.get(xemsHost() + `/new/equipSys/getSysNode?sysNodeIdx=${sysNodeIdx}`);
        },
        // 계통 초기화
        initSysNodeTree(sysType) {
            return apiClient.get(xemsHost() + `/new/equipSys/initSysNodeTree?sysType=${sysType}`);
        },
        // (노드관리)계통노드 이동
        moveSysNode(moveNode) {
            return apiClient.put(xemsHost() + `/new/equipSys/moveSysNode`, moveNode);
        },
        // (노드관리)계통노드 변경
        saveSysNode(saveNode) {
            return apiClient.put(xemsHost() + `/new/equipSys/saveSysNode`, saveNode);
        },
        // (노드관리)계통노드 자동집계설정 변경
        setSumEnabled(data) {
            return apiClient.put(xemsHost() + `/new/equipSys/setSumEnabled`, data);
        },
        getEnergyFlowData(params) {
            return apiClient.get(xemsHost() + `/new/equipSys/getEnergyFlowData`, { params: params });
        },
        addReltnPoint(newREltnPt) {
            return apiClient.post(xemsHost() + `/new/equipSys/addReltnPoint`, newREltnPt);
        },
        deleteReltnPoint(deleteReltn) {
            let filtered = JSON.parse(JSON.stringify(deleteReltn, (key, value) => value ?? undefined));
            return apiClient.delete(xemsHost() + `/new/equipSys/deleteReltnPoint`, { params: filtered });
        },
    },
    points: {
        // 장치 분류체계 조회
        getFacilityClass() {
            return apiClient.get(xemsHost() + `/facility/getFacilityClass`);
        },
        // 서브모듈별 관제점 조회
        getPointInfoByFacility(searchParams, offset, limit) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            if (limit) {
                return apiClient.get(xemsHost() + `/facility/getPointInfoByFacility?offset=${offset}&limit=${limit}`, {
                    params: filtered,
                });
            } else {
                return apiClient.get(xemsHost() + `/facility/getPointInfoByFacility`, { params: filtered });
            }
        },
        // 관제점정보 분류체계 조회
        getPointClass() {
            return apiClient.get(xemsHost() + `/pointInfo/getPointClass`);
        },
        // 관제점그룹별 관제점 조회
        getPointInfoByGroup(searchParams, offset, limit) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            if (limit) {
                return apiClient.get(xemsHost() + `/pointInfo/getPointInfoByGroup?offset=${offset}&limit=${limit}`, {
                    params: filtered,
                });
            } else {
                return apiClient.get(xemsHost() + `/pointInfo/getPointInfoByGroup`, { params: filtered });
            }
        },
        // 설비 분류체계 조회
        getEquipClass() {
            return apiClient.get(xemsHost() + `/new/equip/getEquipClass`);
        },
        // 설비별 관제점 조회
        getPointInfoByEquip(searchParams, offset, limit) {
            let filtered = JSON.parse(JSON.stringify(searchParams, (key, value) => value ?? undefined));
            if (limit) {
                return apiClient.get(xemsHost() + `/new/equip/getPointInfoByEquip?offset=${offset}&limit=${limit}`, {
                    params: filtered,
                });
            } else {
                return apiClient.get(xemsHost() + `/new/equip/getPointInfoByEquip`, { params: filtered });
            }
        },
        // (관제점검색) ID로 관제점 조회
        getPointInfoById(ptIdx) {
            return apiClient.get(xemsHost() + `/pointInfo/getPointInfoById?ptIdx=${ptIdx}`);
        },
    },
    dataAnalysis: {
        pointSummaryData(params) {
            const queryString = new URLSearchParams(params).toString();

            return apiClient.get(xemsHost() + `/dataAnalysis/pointSummaryData?${queryString}`);
        },
        fetchSummaryData2(params) {
            const queryString = new URLSearchParams(params).toString();

            return apiClient.get(xemsHost() + `/new/dataAnalysis/fetchSummaryData2?${queryString}`);
        },
    },
    analysisModel: {
        // 분석모델 목록 조회
        getAnalysisModelList() {
            return apiClient.get(xemsHost() + `/new/analysisModel/getAnalysisModelList`);
        },
        // 분석모델 정보 조회
        getAnalysisModel(modelIdx) {
            return apiClient.get(xemsHost() + `/new/analysisModel/getAnalysisModel?modelIdx=${modelIdx}`);
        },
        // 분석모델 정보 저장
        saveAnalysisModel(model) {
            return apiClient.post(xemsHost() + `/new/analysisModel/saveAnalysisModel`, model);
        },
        // 분석모델 정보 삭제
        deleteAnalysisModel(modelIdx) {
            return apiClient.delete(xemsHost() + `/new/analysisModel/deleteAnalysisModel?modelIdx=${modelIdx}`);
        },
        // 분석모델 상태 변경
        modelSetEnabled(modelEnabled) {
            return apiClient.put(xemsHost() + `/new/analysisModel/setEnabled`, modelEnabled);
        },
        // 분석기록 목록 조회
        getAnalysisCommentList(modelIdx) {
            return apiClient.get(xemsHost() + `/new/analysisModel/getAnalysisCommentList?modelIdx=${modelIdx}`);
        },
        // 분석기록 상세 조회
        getAnalysisComment(modelIdx, regDate) {
            return apiClient.get(
                xemsHost() + `/new/analysisModel/getAnalysisComment?modelIdx=${modelIdx}&regDate=${regDate}`
            );
        },
        // 분석기록 저장
        saveAnalysisComment(comment) {
            return apiClient.post(xemsHost() + `/new/analysisModel/saveAnalysisComment`, comment);
        },
        // 분석기록 삭제
        deleteAnalysisComment(modelIdx, regDate) {
            return apiClient.delete(
                xemsHost() + `/new/analysisModel/deleteAnalysisComment?modelIdx=${modelIdx}&regDate=${regDate}`
            );
        },
        //트랜드 분석 결과 조회
        getAnalyzeTrend(data) {
            return apiClient.post(xemsHost() + "/new/dataAnalysis/analyzeTrend", data, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        },
    },
};

export { apiErrorEvent, setAccessToken, getAccessToken };
