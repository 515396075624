import { extend } from "vee-validate";
// const { $t } = global.xe;

// TODO: locale에 따라 문자열리소스 변경하기... 적용해야 함.
// https://github.com/logaretm/vee-validate/issues/1695

export default {

	configure() {
		// (locale)

		// Add a rule.
		extend("password", {
			message: () => global.xe.$t("비밀번호는 영대문자, 영소문자, 숫자, 특수문자를 3종류 이상으로 조합하고 9~20자리로 설정해야 합니다."),
			validate: (value) => {
				return /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]))[A-Za-z\d!@#$%^&*()+_]{9,20}$/.test(
					value
				);
			},
		});
		// extend("required", {
		//   message: global.xe.$t("이 항목을 필수입니다."),
		// })
		extend("required", {
			message: "이 항목은 필수입니다.",
		});

		extend("confirmPass", {
			validate: (value, id) => {
				return value !== id[0];
			},
			message: "비밀번호는 아이디와 동일하지 않아야 합니다.",
		});

		extend("samePass", {
			validate: (value, id) => {
				return value !== id[0];
			},
			message: "이전 비밀번호와 동일한 비밀번호는 사용할 수 없습니다.",
		});

		/** 특수 문자는 포함 */
		extend("special", {
			validate: (value) => {
				// eslint-disable-next-line
				return /[\{\}\[\]\/?.,;:_|\)*~`!^\-+┼<>@\#$%&\'\"\\\(\=]/gi.test(value);
			},
			message: "특수문자를 포함해 주세요.",
		});

		/**특수 문자는 불가 */
		extend("non_special", {
			validate: (value) => {
				let rule = new RegExp("[\\{\\}\\[\\]\\/?,;:|\\)*~`!^\\-+┼<>@\\#$%&\\'\"\\\\(\\=]", "gi");
				return !rule.test(value);
			},
			message: "특수문자는 사용할 수 없습니다.",
		});

		/** 영문, 숫자만 가능 */
		extend("non_special_num", {
			validate: (value) => {
				return /^[a-z0-9]+$/gi.test(value);
			},
			message: "영문 또는 숫자만 입력할 수 있습니다."
		});

		/** 숫자만 가능 */
		extend("number", {
			validate: (value) => {
				return /^[0-9]+$/gi.test(value);
			},
			message: "숫자만 입력할 수 있습니다."
		});

		/** 영문만 가능 */
		extend("eng", {
			validate: (value) => {
				return /^[a-z]+$/gi.test(value);
			},
			message: "영문만 입력할 수 있습니다."
		});

		extend("phone", {
			validate: (value) => {
				return /^[0-9]{3}[-]+[0-9]{3,4}[-]+[0-9]{4}$/g.test(value);
			},
			message: () => global.xe.$t("휴대전화 형식이 잘못되었습니다."),
		});

		/** 숫자는 불가 */
		extend("non_digits", {
			validate: (value) => {
				return /^[^0-9]+$/.test(value);
			},
			message: "숫자는 사용할 수 없습니다.",
		});

		extend("non_korean", {
			validate: (value) => {
				return /^[a-z0-9!@#$%^&*()_+-={};',./<>?":+]+$/gi.test(value);
			},
			message: "한글은 사용할 수 없습니다."
		});

		extend("non_slash", {
			validate: (value) => {
				let rule = new RegExp("^[^/]+$", "gi");
				return rule.test(value);
			},
			message: "/ 는 사용할 수 없습니다.",
		});

		extend("max", {
			message: "",
		});

		extend("min", {
			message: "",
		});

		extend("check_reserved_id", {
			validate: (value) => {
				return !["admin", "administrator", "administration", "root", "system"].includes(value.toLowerCase());
			},
			message: "사용할 수 없는 아이디입니다.",
		});

		extend("korean", {
			validate: (value) => {
				return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value);
			},
			message: "한글로 작성하세요.",
		});

		extend("both-decimal", {
			validate: (value, data) => {
				let reg = new RegExp(`^(\\-)*?([0-9]{${data[0]},${data[1]}})(\\.([0-9]{1,2}))*?$`, "g");

				return reg.test(value);
			},
			message: (fieldName, { data }) => {
				return `${fieldName}은(는) 소수점 둘째 자리까지 포함하여 ${Number(data[1]) + 4}자 이내로 입력하세요.`;
			},
		});

		extend("integer-decimal", {
			validate: (value, data) => {
				let reg = new RegExp(`^([0-9]{${data[0]},${data[1]}})(\\.([0-9]{1,2}))*?$`, "g");
				return reg.test(value);
			},
			message: (fieldName, data) => {
				return `${fieldName}은(는) 소수점 둘째 자리까지 포함하여 ${Number(data[1]) + 4}자 이내로 입력하세요.`;
			},
		});
	},
};
