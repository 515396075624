<template>
	<div class="m-r-15">
		<div class="d-flex flex-row">
			<!-- <div class="col-lg-10 p-l-0 p-r-0">
				<div class="pull-left">
					<div class="h3">{{ $t("시스템 관리") }} > {{ $t("데이터 관리") }} > {{ $t("백업 관리") }}</div>
				</div>
			</div> -->
			<header-box-component :title="`${$t('시스템 관리')} > ${$t('데이터 관리')} > ${$t('백업 관리')}`" />
			<div class="btn-group m-l-auto">
				<btn-refresh @click="bindConfig()" />
				<btn-add @click="openNewModal()" />
			</div>
		</div>
		<hr style="margin: 10px 0px 10px 0px" />
		<div class="row form-group">
			<div class="col-12">
				<vue-good-table
					v-bind="merge(GRID_OPT, { paginationOptions: { enabled: false } })"
					:columns="columns"
					:rows="retentions"
					:pagination-options="{
						enabled: true,
						nextLabel: $t('다음'),
						prevLabel: $t('이전'),
						ofLabel: '/',
						pageLabel: $t('페이지'), // for 'pages' mode
						rowsPerPageLabel: $t('표시 개수'),
					}"
				>
					<div slot="emptystate" style="text-align: center;">
						{{ $t("조회된 결과가 없습니다.") }}
					</div>
					<template slot="table-row" slot-scope="props">
						<template v-if="props.column.field == 'tableComment'">
							<a v-if="props.row.tableComment !== 'Total'" href="javascript:;" @click="openRetentionDetail(props.row)">
								{{ $t(props.row.tableComment) }}
							</a>
							<span v-else>
								{{ $t(props.row.tableComment) }}
							</span>
						</template>
						<template v-else-if="props.column.field == 'totalRows'">
							{{ props.row.totalRows | filterComma(0) }}
						</template>
						<template v-else-if="props.column.field == 'dataLength'">
							{{ storageSize(props.row.dataLength, "B") }}
						</template>
						<template v-else-if="props.column.field == 'retentionDays'"> {{ props.row.retentionDays }} {{ $t("일") }} </template>
						<template v-else-if="props.column.field == 'dumpExpireDays'"> {{ props.row.dumpExpireDays }} {{ $t("일") }} </template>
						<template v-else-if="props.column.field == 'action'">
							<button v-if="props.row.tableComment !== 'Total'" class="btn btn-default btn-xs" @click="openBackupModal(props.row)">{{ $t("관리") }}</button>
						</template>
					</template>
				</vue-good-table>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-lg-12">
				<table>
					<tr>
						<td>{{ $t("샘플링 시작") }}:</td>
						<td>{{ total.samplingStartDt.format("yyyy-MM-dd") }}</td>
						<td>{{ total.samplingStartDt.passedDay() + $t("일 전") }}</td>
					</tr>

					<tr>
						<td>{{ $t("DB 저장 위치") }}:</td>
						<td colspan="2">{{ total.dataRoot }}</td>
					</tr>
					<tr>
						<td>{{ $t("DB 백업 위치") }}:</td>
						<td colspan="2">{{ total.dumpRoot }}</td>
					</tr>

					<tr>
						<td>{{ $t("DB 저장 크기") }}:</td>
						<td colspan="2">{{ storageSize(total.dataSize, "B") }}</td>
					</tr>
					<tr>
						<td>{{ $t("DB 백업 크기") }}:</td>
						<td colspan="2">{{ storageSize(total.dumpSize, "B") }}</td>
					</tr>

					<tr>
						<td>{{ $t("저장소 크기") }}:</td>
						<td colspan="2">{{ storageSize(total.hddTotal, "GB") }}</td>
					</tr>
					<tr>
						<td>{{ $t("저장소 사용") }}:</td>
					</tr>
						<td colspan="2">{{ storageSize(total.hddUsed, "GB") }}</td>
					<tr>
						<td>{{ $t("저장소 여유") }}:</td>
						<td>{{ storageSize(total.hddFree, "GB") }}</td>
						<td>{{ $t("예상기간") }}: {{ timespan(total.hddFree / ((total.daySize + total.dayDump) / 1000000000), "Day") }}</td>
					</tr>

					<tr>
						<td>{{ $t("일 평균건수") }}:</td>
						<td colspan="2">{{ total.dayRows | filterComma(0) }}</td>
					</tr>
					<tr>
						<td>{{ $t("일 평균크기") }}:</td>
						<td colspan="2">{{ storageSize(total.daySize, "B") }}</td>
					</tr>
					<tr>
						<td>{{ $t("일 평균백업") }}:</td>
						<td colspan="2">{{ storageSize(total.dayDump, "B") }}</td>
					</tr>

					<tr>
						<td>{{ $t("연 예상건수") }}:</td>
						<td>{{ total.yearRows | filterComma(0) }}</td>
						<td>3{{ $t("년") }} = {{ (total.yearRows * 3) | filterComma(0) }}</td>
					</tr>
					<tr>
						<td>{{ $t("연 예상크기") }}:</td>
						<td>{{ storageSize(total.yearSize, "B") }}</td>
						<td>3{{ $t("년") }} = {{ storageSize(total.yearSize * 3, "B") }}</td>
					</tr>
				</table>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-lg-12">
				<retention-detail :retention="curRetention" />
			</div>
		</div>

		<b-modal ref="backupModal" :hide-footer="true" :title="$t('백업')">
			<div class="row form-group">
				<div class="col-lg-12">
					<label> {{ curRetention.logTable }} </label>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-lg-12">
					<div class="form-inline m-b-10">
						<ln-picker :label="$t('시작일')" v-model="condition.sDt" :setting="{ format: 'YYYY-MM-DD' }" />
						<ln-picker :label="$t('종료일')" v-model="condition.eDt" :setting="{ format: 'YYYY-MM-DD' }" />
					</div>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-lg-12">
					<div slot="modal-footer">
						<btn-normal icon="fa-archive" color="white" @click="onFullBackup()" :label="$t('전체 백업')" :tooltip="$t('전체 백업')" />
						<btn-normal icon="fa-archive" color="default" @click="onDifferentialBackup()" :label="$t('백업')" :tooltip="$t('일별 백업')" />
					</div>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-lg-12">
					<div slot="modal-footer">
						<btn-normal icon="fa-upload" color="purple" @click="onUpdateStatus()" :label="$t('복원')" :tooltip="$t('백업파일을 복원합니다.')" />
						<btn-normal icon="fa-times" color="indigo" @click="onUpdateStatus()" :label="$t('제거')" :tooltip="$t('백업파일을 제거합니다.')" />
						<btn-normal icon="fa-trash-alt" color="primary" @click="onUpdateStatus()" :label="$t('삭제')" :tooltip="$t('취합로그를 삭제합니다.')" />
						<btn-normal icon="fa-sync" color="info" @click="onUpdateStatus()" :label="$t('갱신')" :tooltip="$t('취합로그를 갱신합니다.')" />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div slot="modal-footer">
						<!-- <btn-normal icon="fa-flag" color="yellow" @click="onUpdateStatus()" label="보간" /> -->
						<btn-normal icon="fa-calculator" color="yellow" @click="onUpdateStatus()" :label="$t('샘플링')" />
						<!-- <btn-normal icon="fa-chart-line" color="yellow" @click="onUpdateStatus()" label="취합" /> -->
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import backEndApi from "../../../api/backEndApi.js";
	import RetentionDetail from "./retention/RetentionDetail.vue";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import HeaderBoxComponent from "@views/component/headerBox/analysis/Header.vue"

	export default {
		components: {
			RetentionDetail,
			HeaderBoxComponent
		},
		data() {
			return {
				condition: {
					sDt: new Date().format("yyyy-MM-dd"),
					eDt: new Date().format("yyyy-MM-dd"),
				},

				retentionSet: {
					samplingStartDt: "",
					retentions: [],
				},

				curRetention: {},

				// grid data
				columns: [
					{
						label: this.$t("테이블"),
						field: "tableComment",
						type: "text",
						width: "10%",
						tdClass: "text-center text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("DB 자료 유지기간"),
						field: "retentionDays",
						type: "text",
						width: "10%",
						tdClass: "text-center text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("백업파일 보관기간"),
						field: "dumpExpireDays",
						type: "text",
						width: "10%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: this.$t("데이터 건수"),
						field: "totalRows",
						type: "text",
						width: "10%",
						tdClass: "text-right text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("데이터 크기"),
						field: "dataLength",
						type: "text",
						width: "10%",
						tdClass: "text-right text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("백업 경로"),
						field: "dumpPath",
						type: "text",
						width: "40%",
						tdClass: "text-left text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: "",
						field: "action",
						type: "text",
						width: "10%",
						tdClass: "text-center text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
				],
			};
		},
		computed: {
			retentions() {
				let arr = this.retentionSet.retentions && this.retentionSet.retentions.length > 0 ? this.retentionSet.retentions : [];

				arr.push({
					tableComment: "Total",
					retentionDays: "",
					dumpExpireDays: "",
					totalRows: arr.length ? arr.map((v) => v.totalRows).reduce((acc, cur) => acc + cur) : 0,
					dataLength: arr.length ? arr.map((v) => v.dataLength).reduce((acc, cur) => acc + cur) : 0,
					dumpPath: "",
				});

				return arr;
			},
			total() {
				// this.retentionSet.samplingStartDt
				// minDt; maxDt;
				// status 일평균 건수
				// 관제점수

				// 하드디스크 여유

				let totalRows = this.retentions.length ? this.retentions.map((v) => (v.tableComment == "Total" ? 0 : v.totalRows)).reduce((acc, cur) => acc + cur) : 0;
				let dataLength = this.retentions.length ? this.retentions.map((v) => (v.tableComment == "Total" ? 0 : v.dataLength)).reduce((acc, cur) => acc + cur) : 0;

				let totalDays = this.retentionSet.samplingStartDt ? this.retentionSet.samplingStartDt.passedDay() : "";

				let dayRows = totalRows / totalDays; // 일평균건수
				let daySize = dataLength / totalDays; // 일평균크기
				let dayDump = this.retentionSet.dumpSize / totalDays; // 일평균 백업크기

				let yearRows = dayRows * 365; // 년평균건수
				let yearSize = daySize * 365; // 년평균크기

				let result = {
					samplingStartDt: this.retentionSet.samplingStartDt ? this.retentionSet.samplingStartDt : "",

					dataRoot: this.retentionSet.dataRoot,
					dataSize: this.retentionSet.dataSize,
					dumpRoot: this.retentionSet.dumpRoot,
					dumpSize: this.retentionSet.dumpSize,
					dayDump: dayDump,

					hddTotal: this.retentionSet.hddTotal,
					hddUsed: this.retentionSet.hddUsed,
					hddFree: this.retentionSet.hddFree,

					totalRows: totalRows,
					dataLength: dataLength,
					dayRows: dayRows,
					daySize: daySize,
					yearRows: yearRows,
					yearSize: yearSize,
				};

				return result;
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.bindConfig();
			},
			bindConfig() {
				this.curRetention = {};
				this.retentionSet = {};

				backEndApi.engineering.getRetentions().then(({ data }) => {
					if (this.$err(data)) return;

					this.retentionSet = data;
				});
			},
			openRetentionDetail(row) {
				this.curRetention = this.retentionSet.retentions[row.originalIndex];
			},
			openNewModal() {
				this.$refs.backupModal.show();
			},
			openBackupModal(row) {
				this.curRetention = this.retentionSet.retentions[row.originalIndex];
				this.$refs.backupModal.show();
			},
			onFullBackup() {},
			onDifferentialBackup() {
				let { sDt, eDt } = this.condition;
				let tableName = this.curRetention.logTable;
				let timeType = this.curRetention.timeType;
				let isOverwrite = true;

				this.alertQuestion(popupMessages.DATA_BACKUP_COMMON_DB_BACKUP_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.engineering.differential(tableName, timeType, sDt, eDt, isOverwrite).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.DATA_BACKUP_COMMON_SYNC_POPUP_MESSAGE);
					});
				});
			},
			onUpdateStatus() {
				let { sDt, eDt } = this.condition;
				let tableName = this.curRetention.logTable;

				this.alertQuestion(popupMessages.DATA_BACKUP_COMMON_TABLE_RENEWAL_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.summary.updateLogTableStatus(sDt, eDt, tableName).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.DATA_BACKUP_COMMON_SYNC_POPUP_MESSAGE);
					});
				});
			},
		},
	};
</script>
