<template>
  <div>
    <div class="row-v">
      <div class="d-flex flex-row">
        <div class="col-2 align-items-center">
          <div class="text-left mb-2">
            <span class="">{{ $t("수집기") }}</span>
          </div>
          <div class="text-left">
            <span class="h5">{{ data.serverName }}</span>
          </div>
        </div>
        <div class="col-2 align-items-center">
          <div class="text-left mb-2">
            <span>{{ $t("계측기") }}({{ $t("센서") }})</span>
          </div>
          <div class="text-left">
            <span class="h5">{{ data.equipName }}</span>
          </div>
        </div>
        <!-- <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-3 text-left">
            <span class="h5">채널 번호</span>
          </div>
          <div class="col-8  text-left">
            <span>{{data.channelIdx}}</span>
          </div>
        </div> -->
      </div>
      <!-- <div class="w-50">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-3 text-left">
            <span class="h5">수집기 IP</span>
          </div>
          <div class="col-8  text-left">
            <span>{{data.serverIp}}</span>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-3 text-left">
            <span class="h5">계측항목 속성</span>
          </div>
          <div class="col-8  text-left">
            <span>{{data.gatherItemName}}</span>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-3 text-left">
            <span class="h5">채널명</span>
          </div>
          <div class="col-8  text-left">
            <span>{{data.channelName}}</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  components: {},
  props: ["data"],
  watch: {},
  data() {
    return {
      searchPointGroup: null,

      ptTypeList: null,
      isVirtualList: null,
      valueTypeList: null,
      itemUseageCodeList: null,
      energySourceCodeList: null,
      itemPropCodeList: null,
      itemAgrgtnDvsnCodeList: null,
      renewTypeCodeList: null,
      orgUnitDvsnCodeList: null,
      isIgnoreZeroList: null,
      isCovList: null,

      // define the default value
      applyZoneIdx: null,
      // define options
      options: null,
    };
  },
  computed: {},
  async created() {
    await this.getSearchPointGroup();
    await this.getSmallCodeByPoint();
    await this.getZoneInfoList();
  },
  methods: {
    async getSearchPointGroup() {
      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.pointInfo.searchPointGroup();
        const data = result.data;

        this.searchPointGroup = data;
      } catch (err) {
        new AppErrorHandler(err).printErrorLog().errHandling();
      }
    },
    async getSmallCodeByPoint() {
      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.publicCode.getSmallCodeByPoint();
        const data = result.data;

        this.smallCodeByPoint = data;

        // 관제점 취합 유형 select box
        this.ptTypeList = data.filter((data) => data.largeCode == "ptType");

        // 관제점 유형 select box
        this.isVirtualList = data.filter(
          (data) => data.largeCode == "isVirtual"
        );

        // 값 유형 select box
        this.valueTypeList = data.filter(
          (data) => data.largeCode == "valueType"
        );

        // 관제점 용도 select box
        this.itemUseageCodeList = data.filter(
          (data) => data.largeCode == "itemUseageCode"
        );

        // 에너지원 select box
        this.energySourceCodeList = data.filter(
          (data) => data.largeCode == "energySourceCode"
        );

        // 관제점 속성 select box
        this.itemPropCodeList = data.filter(
          (data) => data.largeCode == "itemPropCode"
        );

        // 집계 구분 select box
        this.itemAgrgtnDvsnCodeList = data.filter(
          (data) => data.largeCode == "itemAgrgtnDvsnCode"
        );

        // 에너지 생성타입 select box
        this.renewTypeCodeList = data.filter(
          (data) => data.largeCode == "renewTypeCode"
        );

        // 원단위 구분 select box
        this.orgUnitDvsnCodeList = data.filter(
          (data) => data.largeCode == "orgUnitDvsnCode"
        );

        // 0값 무시 select box
        this.isIgnoreZeroList = data.filter(
          (data) => data.largeCode == "isIgnoreZero"
        );

        // 변경확인 select box
        this.isCovList = data.filter((data) => data.largeCode == "isCov");
      } catch (err) {
        new AppErrorHandler(err).printErrorLog().errHandling();
      }
    },
    async getZoneInfoList() {
      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
				↓↓↓↓↓
			*/
      try {
        const result = await backEndApi.zoneInfo.getZoneInfoList();
        const data = result.data;

        this.options = [];
        this.options = this.setZoneTreeOption(data);
      } catch (err) {
        new AppErrorHandler(err).printErrorLog().errHandling();
      }
    },
    setZoneTreeOption(resultData) {
      resultData.forEach((data) => {
        data.id = data.zoneIdx;
        data.label = data.text;

        if (data["children"]) {
          return this.setZoneTreeOption(data["children"]);
        } else {
          return data;
        }
      });

      return resultData;
    },
    onZoneSelected(node) {
      this.applyZoneIdx = node.id;
    },
    async onRegist() {
      console.log("New modal : onRegist");

      if (this.isValidation()) {
        let param = {
          ptGroup: this.$refs.ptGroup.value,
          ptAddr: this.$refs.ptAddr.value,
          ptName: this.$refs.ptName.value,
          ptType: this.$refs.ptType.value,
          min: this.$refs.min.value,
          max: this.$refs.max.value,
          overflow: this.$refs.overflow.value,
          isCov: this.$refs.isCov.value,
          unit: this.$refs.unit.value,
          rawUnit: this.$refs.rawUnit.value,
          isVirtual: this.$refs.isVirtual.value,
          valueType: this.$refs.valueType.value,
          isIgnoreZero: this.$refs.isIgnoreZero.value,
          itemPropCode: this.$refs.itemPropCode.value,
          itemAgrgtnDvsnCode: this.$refs.itemAgrgtnDvsnCode.value,
          itemUseageCode: this.$refs.itemUseageCode.value,
          energySourceCode: this.$refs.energySourceCode.value,
          renewTypeCode: this.$refs.renewTypeCode.value,
          applyZoneIdx: this.applyZoneIdx,
        };

        // 이전 리비전과 소스 에서 아래와 같이 변경함.
        /*           
          ↓↓↓↓↓
        */
        try {
          const result = await backEndApi.pointInfo.insertPoint(param);
          const data = result.data;
          console.log(data);
        } catch (err) {
          new AppErrorHandler(err).printErrorLog().errHandling();
        }
      }
    },
    isValidation() {
      var flag = true;

      if (!this.$refs.ptAddr.value) {
        this.$refs.ptAddr.focus();
        this.alertNoti(popupMessages.POINT_INPUT_POINT_ADDRESS_POPUP_MESSAGE);
        flag = false;
      } else if (!this.$refs.ptName.value) {
        this.$refs.ptName.focus();
        this.alertNoti(popupMessages.POINT_INPUT_POINT_NAME_POPUP_MESSAGE);
        flag = false;
      } else if (!this.$refs.ptGroup.value) {
        this.$refs.ptGroup.focus();
        this.alertNoti(popupMessages.POINT_SELECT_POINT_GROUP_POPUP_MESSAGE);
        flag = false;
      } else if (!this.$refs.ptType.value) {
        this.$refs.ptType.focus();
        this.alertNoti(
          popupMessages.POINT_SELECT_POINT_COLLECTION_TYPE_POPUP_MESSAGE
        );
        flag = false;
      } else if (!this.$refs.isVirtual.value) {
        this.$refs.isVirtual.focus();
        this.alertNoti(popupMessages.POINT_SELECT_POINT_TYPE_POPUP_MESSAGE);
        flag = false;
      }
      return flag;
    },
  },
};
</script>
