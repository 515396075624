<template>
	<panel :noTitle="true" :variant="panelVariant" bodyClass="" noButton="true">
		<div class="row">
			<div class="col-lg-8">
				<!-- <span class="title">{{ facility ? facility.facilityName : $t("장비를 선택하세요.") }}</span> -->
				<span class="title"> {{ hierarchy ? hierarchy : "" }} </span>
				<span class="badge id" v-if="facility"> {{ facility.facilityIdx }} </span>
				<span class="badge title" v-if="facility && facility.ct"> CT: {{ facility.ct }} </span>
				<span class="badge option" v-if="facility && facility.mappingKey"> MAP: {{ facility.mappingKey }}
				</span>

			</div>
			<div class="col-lg-4">
				<div class="btn-group pull-right mb-0" style="width: 100%; display: flex; flex: 1;">
					<button class="btn btn-sm btn-white" v-if="facility ? true : false" @click="onTotalRealPopup">
						<i class="fa fa-circle-notch fa-spin" style="flex: 1; vertical-align: middle;"></i>
						{{ $t("실시간 상태값") }}
					</button>
					<button class="btn btn-sm btn-light" :class="[filterIO == '' ? 'active' : '']"
						@click="filterIO = ''" style="flex: 1;">
						<trans>전체</trans> ({{ facilityMap.length }})
					</button>
					<button class="btn btn-sm btn-light" :class="[filterIO == 'In' ? 'active' : '']"
						@click="filterIO = 'In'" style="flex: 1;">
						<trans>계측</trans> ({{ facilityMap.filter((v) => ['In', 'Both'].includes(v.direction)).length }})
					</button>
					<button class="btn btn-sm btn-light" :class="[filterIO == 'Out' ? 'active' : '']"
						@click="filterIO = 'Out'" style="flex: 1;">
						<trans>제어</trans> ({{ facilityMap.filter((v) => ['Out', 'Both'].includes(v.direction)).length
						}})
					</button>
				</div>
			</div>
		</div>

		<div class="row m-t-5 mb-2">
			<div class="col-lg-6">
				<!-- <div class="form-inline">
					<ln-select :label="$t('관제점 주소')" v-model="filterAddr" :items="{ Y: $t('주소있음'), N: $t('주소없음') }" />
					<label>{{ $t("검색어") }} : </label>
					<valid-input :inputType="'text'" :inputValue.sync="filterName"
						:rules="rules.COLLECT_DATA_SEARCH_RULE" :placeholder="$t('검색어를 입력하세요.')"
						:NonStatus:="true"></valid-input>
				</div> -->
				<div class="d-flex">
					<div class="input-group">
						<div class="input-group-prepend">
							<span for="searchPtAddr" class="input-group-text">관제점 주소</span>
						</div>
						<new-ln-select v-model="filterAddr" :items="{ Y: $t('주소있음'), N: $t('주소없음') }" />
					</div>
					<div class="input-group">
						<div class="input-group-prepend">
							<span for="searchPtAddr" class="input-group-text">검색어</span>
						</div>
						<valid-input :inputType="'text'" :inputValue.sync="filterName"
							:rules="rules.COLLECT_DATA_SEARCH_RULE" :placeholder="$t('검색어를 입력하세요.')"
							:NonStatus:="true"></valid-input>
					</div>
				</div>
			</div>
			<div class="col-lg-2"></div>
			<div class="col-lg-4">
				<div class="btn-group pull-right" style="width: 100%; display: flex; flex: 1;" v-xe-pm.E>
					<button class="btn btn-sm btn-danger" :disabled="checkedCount < 1" @click="onClearAddr"><i
							class="fa fa-eraser" style="flex: 1; vertical-align: middle;"></i> {{ $t("지우기") }}</button>
					<button class="btn btn-sm btn-primary" :disabled="checkedCount < 1" @click="onFillAddr"><i
							class="fa fa-paw" style="flex: 1; vertical-align: middle;"></i> {{ $t("자동채우기") }}</button>
					<button class="btn btn-sm btn-secondary" :disabled="checkedCount < 1" @click="onSaveMap"><i
							class="fa fa-save" style="flex: 1; vertical-align: middle;"></i> {{ $t("저장") }}</button>
				</div>
			</div>
		</div>

		<div class="facilityMap-table-div">
			<table class="table table-hover bg-white">
				<thead class="">
					<tr>
						<th class="text-center bg-inverse" style="width:5%">
							<button class="btn btn-xs btn-yellow" @click="toggleAll">{{ $t("선택") }}</button>
						</th>
						<th class="text-center bg-inverse text-grey" style="width:4%">{{ $t("번호") }}</th>
						<th class="text-center bg-inverse text-grey" style="width:4%">{{ $t("CT") }}</th>
						<th class="text-center bg-inverse text-grey" style="width:6%">{{ $t("입출력") }}</th>
						<th class="text-center bg-inverse text-grey" style="width:10%">{{ $t("관제속성") }}</th>
						<th class="text-center bg-inverse text-grey" style="width:7%">{{ $t("집계유형") }}</th>
						<th class="text-center bg-inverse text-grey" style="width:3%"></th>
						<th class="text-center bg-inverse text-grey" style="width:20%">{{ $t("연결된 관제점") }}</th>
						<th class="text-center bg-inverse text-grey" style="">{{ $t("설명") }}</th>
					</tr>
				</thead>
				<tbody v-if="facilityMap && facilityMap.length">
					<tr v-for="(property, i) in pagingMap" :key="i">
						<td class="text-center valign-middle" @click="rowSelect(property)" style="cursor: pointer;">
							<div class="checkbox checkbox-css m-l-20" style="margin-top:-6px; cursor: pointer;">
								<input type="checkbox" :id="'facilityMap_' + i" v-model="property.isChecked"
									style="cursor: pointer;" />
								<label :for="'facilityMap_' + i"></label>
							</div>
						</td>
						<td class="text-center valign-middle">{{ i + 1 + (curPage - 1) * viewSize }}</td>
						<td class="text-center valign-middle">{{ property.ct == 0 ? "" : property.ct }}</td>
						<td class="text-center valign-middle" style="font-size:14px">
							<!-- <b-badge :variant="property.direction == 'In' ? 'light' : 'secondary'" class="m-l-5"> {{ property.direction !== "Out" ? $t("계측") : $t("제어") }} </b-badge> -->
							<b-badge :variant="property.direction == 'In' ? 'light' : 'secondary'" class="m-l-5"> {{
								property.direction }} </b-badge>
						</td>
						<td class="text-center valign-middle">
							<!-- v-b-tooltip.hover :title="$t('드라이버 상세')"  -->
							<b-badge variant="light" v-b-toggle.DriverPropSideBar id="fcDriverModal"
								style="cursor: pointer; font-weight: bold;"
								@click="openDriverPropModal(property, $event)">
								{{ property.propName }}
							</b-badge>
						</td>
						<!-- <td class="text-center valign-middle">{{ property.ptType | codePtType }}</td> -->
						<td class="text-center valign-middle">
							{{ $store.state.commonCodes.ptTypeCode.find(type => type.value == property.ptType) ?
								$store.state.commonCodes.ptTypeCode.find(type => type.value == property.ptType).text : "-"
							}}
						</td>
						<!-- 주소가 변경되면, 아이콘 색상을 변경해서 표현함. -->
						<td class="text-center valign-middle">
							<b-badge v-if="property.ptIdx" variant="light" style="cursor: pointer;" id="fcPointModal"
								@click="openPointModal(property, $event)"
								:class="{ 'text-red': property.orgAddr !== property.ptAddr }"
								v-b-toggle.ptDetailSideBar>
								<i class="fa fa-asterisk" :class="'valign-middle'"></i>
							</b-badge>
						</td>
						<td class="text-left valign-middle p-t-0 p-b-0" style="height: 34px">
							<!-- <b-badge v-if="property.ptIdx" variant="secondary" style="cursor: pointer;"
								id="fcPointModal" @click="openPointModal(property, $event)"
								:class="{ 'text-red': property.orgAddr !== property.ptAddr }"
								v-b-toggle.ptDetailSideBar>
								<i class="fa fa-asterisk" :class="'valign-middle'"></i>
							</b-badge> -->
							<drop v-if="property.isChecked" @dragover="handleBodyDragOver(...arguments)"
								@drop="handleBodyDrop(...arguments)">
								<div class="form-inline">
									<input v-if="property.isChecked" type="text" class="form-control addr-control"
										style="width: 70%" :placeholder="$t('자동채우기로 관제점 주소를 생성')"
										v-model="property.ptAddr" @input="onChangeAddr(property)"
										@change="onChangeAddr(property)" />
								</div>
							</drop>
							<span v-else> {{ property.ptAddrText }} </span>
						</td>
						<!-- <td class="text-left valign-middle" v-if="property.ptName">
							{{ $t(property.ptName) }}
						</td>
						<td v-else>
							{{ property.remark.includes("(") === true ?
							`${$t(property.remark.split("(")[0])}(${property.remark.split("(")[1]}` :
							$t(property.remark) }}
						</td> -->
						<td class="text-left valign-middle">
							{{ property.remark.includes("(") === true ?
								`${$t(property.remark.split("(")[0])}(${property.remark.split("(")[1]}` :
								$t(property.remark) }}
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<td colspan="9">
							<no-data height="500px" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<hr />
		<div class="row" v-if="filterMap && filterMap.length">
			<div class="col-2">
				<select class="form-control" style="max-width:80px;" v-model="viewSize">
					<option v-for="(size, i) in perViews" :key="i" :value="size">
						{{ size == 99999 ? "전체" : size }}
					</option>
				</select>
			</div>
			<div class="col-8">
				<div class="text-center">
					<a href="javascript:;" class="btn btn-sm btn-default" @click="
						curPage = 1;
					minPage = 1;
					"><i class="fa fa-angle-double-left"></i></a>
					<button class="btn btn-sm" :class="[curPage != 1 ? 'btn-white' : 'xe-btn-disable btn-default']"
						@click="beforePage()"><i class="fa fa-angle-left"></i></button>
					<span v-for="i in maxPage" :key="i">
						<button class="btn btn-sm btn-white" :class="{ active: curPage == i }"
							v-if="i >= minPage && i <= minPage + pageSize - 1" @click="curPage = i">{{ i }}</button>
					</span>
					<button class="btn btn-sm"
						:class="[curPage != maxPage ? 'btn-white' : 'xe-btn-disable btn-default']"
						@click="nextPage()"><i class="fa fa-angle-right"></i></button>
					<!-- <button class="btn btn-sm btn-default"
              @click=" curPage = maxPage; minPage = maxPage; " v-b-tooltip:title="$t('전체 페이지수') + ': ' + maxPage"
              >
              <i class="fa fa-angle-double-right"></i>
            </button> -->
					<button class="btn btn-sm btn-default" @click="
						curPage = maxPage;
					minPage = maxPage;
					">
						<i class="fa fa-angle-double-right"></i>
					</button>
				</div>
			</div>
			<div class="col-2">
				<!-- {{$t('전체 페이지수')}} : {{maxPage}} -->
				<span class="pull-right"> {{ $t("전체건수") }} : {{ filterMap.length }} </span>
			</div>
		</div>

		<!-- TODO: 신규 페이징으로 전환?
        
        <div class="text-center" v-if="facilityMap && facilityMap.length">
          <div style="display: inline-block;">
            <xe-pagination  v-model="curPage" :page-count="maxPage" :classes="{}" /> 
            <span> Cur: {{ curPage }}, Total: {{ maxPage }} </span>
            <span> Min: {{ minPage }}, Total: {{ maxPage }} </span>
          </div>
        </div> -->

		<!-- <div>
          curPage : {{curPage}}  <br />
          minPage : {{minPage}}  <br />
          maxPage : {{maxPage}}  <br />
          pageSize : {{pageSize}}  <br />
          viewSize : {{viewSize}}  <br />
        </div> -->

		<!-- <b-modal ref="pointModal" :hide-footer="true" :title="$t('관제점 상세')">
			<point-detail :ptAddr="paramPtAddr" :ptIdx="ptIdx" @save-done="onPointSaveDone" readonly />
			<point-detail ref="point-detail" :title="''" :ptIdx="ptIdx" />
		</b-modal> -->

		<b-sidebar ref="ptDetailSideBar" class="ptDetailSideBar" id="ptDetailSideBar" bg-variant="light"
			backdrop-variant="secondary" width="33vw" @hidden="closePtDetailSidebar" no-header shadow right backdrop>
			<div v-if="ptSideBar" style="height: 100%">
				<point-detail ref="point-detail" :title="''" :ptIdx="ptIdx" :noWatch="true" @data-updated="dataUpdated"
					@closed="closePtDetailSidebar" />
			</div>
		</b-sidebar>

		<!-- <b-modal ref="driverPropModal" :hide-footer="true" :title="$t('드라이버속성 상세')">
			<driver-prop-popup :options="modalDriverProp" />
		</b-modal> -->

		<b-sidebar ref="DriverPropSideBar" id="DriverPropSideBar" bg-variant="light" backdrop-variant="secondary"
			width="33vw" @hidden="closeDrvPropSideBar" no-header shadow right backdrop>
			<div v-if="drvSideBar" style="height: 100%">
				<driver-prop-detail :title="''" :options="modalDriverProp" @closed="closeDrvPropSideBar" />
			</div>
		</b-sidebar>

		<b-modal ref="totalRealModal" size="lg" :hide-footer="true" :title="$t('실시간 수집데이터 조회')">
			<total-real-popup :facility="facility" />
		</b-modal>
	</panel>
</template>

<style scoped>
.checkbox.checkbox-css input {
	display: block;
}

.facilityMap-table-div {
	overflow-y: scroll;
	max-height: 600px;
}

:not(.panel-body) {
	overflow-y: auto;
	overflow-x: hidden;
}

.addr-control {
	height: calc(1em + 14px + 2px);
	padding: 2px 0.75rem;
}

.ptDetailSideBar>>>.b-sidebar {
	margin-top: 2rem !important;
	height: 92% !important;
}
</style>

<script>
import { Drop } from "vue-drag-drop";
import backEndApi from "@api/backEndApi.js";
// import PointDetail from "@views/sys/data-management/point/components/PointDetail.vue";

import PointDetail from "@src/views/new/point-management/point-mgmt/PointDetail.vue"

// import DriverPropPopup from "./DriverPropPopup.vue";
import DriverPropDetail from "@src/views/component/v2.1/DetailView/DriverPropDetail.vue"
import TotalRealPopup from "./TotalRealPopup.vue";
import * as rules from "@src/consts/ruleConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
	props: ["option", "parentData"], // TODO: save-done 부모에서 사용하지 않음..
	components: {
		Drop,
		PointDetail,
		DriverPropDetail,

		// DriverPropPopup,
		TotalRealPopup,
	},
	data() {
		return {
			driverProps: [], // 드라이버 속성들 - 속성목록의 기준임.
			facilityMap: [], // 장비맵핑 - 관제점을 생성한것만 DB에 있음.
			updateMap: [], // 주소가 변경된 것들을 모아놓았다가 나중에 저장할때 사용.

			filterIO: "", // In, Out
			filterName: "", // 속성명, 관제점주소 두가지 조회.
			filterAddr: "", // ''=전체, 'Y'=주소있음, 'N'=주소없음

			curPage: 1, // 현재 페이지
			minPage: 1, // ??
			pageSize: 10, // 페이지 버튼의 갯수
			viewSize: 100, // 한페이지에 보여질 갯수
			perViews: [10, 100, 1000, 99999],

			ptIdx: null,
			paramPtAddr: "", // 관제점 팝업창에 전달할 주소
			modalDriverProp: {
				driverType: "",
				version: "",
				direction: "",
				propertyName: "",
			},
			rules,
			popupMessages,
			ptSideBar: false,
			drvSideBar: false,
		};
	},
	computed: {
		defaultFilter() {
			let filteringData = this.parentData;

			// console.log(filteringData, this.driverProps, this.facilityMap, this.filterIO, this.filterName, this.filterAddr);
			if (filteringData) {
				if (filteringData.data.type === "facility") {
					let serverIdx = Number(filteringData.data.serverIdx) < 10 ? "0" + filteringData.data.serverIdx : filteringData.data.serverIdx;
					let channelIdx = Number(filteringData.data.channelIdx) < 10 ? "0" + filteringData.data.channelIdx : filteringData.data.channelIdx;
					let deviceId =
						Number(filteringData.data.deviceId) < 100
							? Number(filteringData.data.deviceId) >= 10
								? "0" + filteringData.data.deviceId
								: "00" + filteringData.data.deviceId
							: filteringData.data.deviceId;

					let filterQuery = `${serverIdx}${channelIdx}_NLI_${deviceId}`;

					return filterQuery;
				} else if (filteringData.data.type === "subFacility") {
					let serverIdx = Number(filteringData.data.serverIdx) < 10 ? "0" + filteringData.data.serverIdx : filteringData.data.serverIdx;
					let channelIdx = Number(filteringData.data.channelIdx) < 10 ? "0" + filteringData.data.channelIdx : filteringData.data.channelIdx;
					let deviceId =
						Number(filteringData.data.deviceId) < 100
							? Number(filteringData.data.deviceId) >= 10
								? "0" + filteringData.data.deviceId
								: "00" + filteringData.data.deviceId
							: filteringData.data.deviceId;

					let ctNum = Number(filteringData.data.ct) < 10 ? "0" + filteringData.data.ct : filteringData.data.ct;

					let filterQuery = `${serverIdx}${channelIdx}_NLI_${deviceId}F${ctNum}`;

					return filterQuery;
				}
			}

			return "";
		},
		server() {
			return this.option.server || null;
		},
		channel() {
			return this.option.channel || null;
		},
		parent() {
			return this.option.parent || null;
		},
		facility() {
			return this.option.facility || null;
		},
		hierarchy() {
			if (this.server && this.channel && this.parent) return `${this.server.serverName} / ${this.channel.channelName} / ${this.parent.facilityName}`;
			if (this.server && this.channel) return `${this.server.serverName} / ${this.channel.channelName}`;
			return "";
		},
		// FacilityMap 필터 처리
		filterMap() {
			let filterData = this.facilityMap;
			// In / Out 필터
			if (!this.isEmpty(this.filterIO)) filterData = filterData.filter((v) => v.direction == this.filterIO || v.direction == 'Both');

			// 관제점주소가 있는냐 없느냐임.  실제 관제점 주소가 할당된것과는 다름
			if (!this.isEmpty(this.filterAddr)) filterData = filterData.filter((v) => (this.filterAddr == "Y" && !this.isEmpty(v.ptAddr)) || (this.filterAddr == "N" && this.isEmpty(v.ptAddr)));

			// 조회 문자열은 속성명과 관제점 주소..
			let filterName = this.filterName.toLowerCase();
			// if (!this.isEmpty(this.filterName)) filterData = filterData.filter((v) => v.propertyName.toLowerCase().includes(filterName) || (v.ptAddr && v.ptAddr.toLowerCase().includes(filterName)));
			if (!this.isEmpty(this.filterName)) {
				filterData = filterData.filter(
					(v) => v.propertyName && v.propertyName.toLowerCase().includes(filterName)
					// v.remark.toLowerCase() === filterName || (v.ptAddr && v.ptAddr.toLowerCase().includes(filterName))
				);
			}

			return filterData;
		},
		// 현재 페이지에 표시되어야할 목록
		pagingMap() {
			let s = this.viewSize * (this.curPage - 1);
			return this.filterMap.slice(s, s + this.viewSize);
		},
		// 페이징 최대 수
		maxPage() {
			let division = Math.floor(this.filterMap.length / this.viewSize);
			let remaining = this.filterMap.length % this.viewSize != 0 ? 1 : 0;

			return division + remaining;
		},
		checkedCount() {
			return this.filterMap.filter((v) => v.isChecked).length;
		},
	},
	watch: {
		"facility.facilityIdx": function () {
			this.initSetting();
		},
		curPage() {
			// 페이지 이동시 선택 제거되어야 함..  보이지 않는 페이지에 선택된것이 남아 있으면, 저장이 되어 문제가 될 수 있다.
			this.pagingMap.forEach((v) => {
				v.isChecked = false;
			});
		},
	},
	mounted() {
		this.initSetting();
	},
	methods: {
		async dataUpdated() {
			await this.searchFacilityMapWithPtAddr();
			await this.getDriverProp();
		},
		closePtDetailSidebar() {
			this.$refs.ptDetailSideBar.hide();
			this.ptSideBar = false;
		},
		closeDrvPropSideBar() {
			this.$refs.DriverPropSideBar.hide();
			this.drvSideBar = false;
		},

		rowSelect(row) {
			row.isChecked = !row.isChecked;

			// console.log(`rowSelect ${idx}`, event, row);
		},

		//#region 초기화
		async initSetting() {
			// TODO: 초기화 오브젝트 선언해서 써야 함..
			this.driverProps.clear(); // 드라이버 속성들
			this.facilityMap.clear(); // 장비맵
			this.updateMap.clear(); // 주소가 변경된 것들을 모아놓았다가 나중에 저장할때 사용.

			this.curPage = 1;
			this.minPage = 1;
			this.pageSize = 10;
			//this.viewSize = 100; // 초기화하지 말것.. 불편함.

			this.filterIO = "";
			this.filterName = "";
			this.filterAddr = "";

			if (this.isEmpty(this.facility)) return;
			if (this.isEmpty(this.facility.driverType) || this.isEmpty(this.facility.version)) return;

			let facilityMap = await this.searchFacilityMapWithPtAddr();
			let driverProps = await this.getDriverProp();

			// 장비에 ct가 없을땐, 전체...
			if (!this.isEmpty(this.facility.ct)) driverProps = driverProps.filter((v) => v.ct == this.facility.ct);
			this.driverProps.range(driverProps);

			// 드라이버 속성목록을 기준으로 맵핑정보를 생성한다.
			let arr = driverProps.map((driverProp) => {
				let facProp = facilityMap.find((v) => v.direction === driverProp.direction && v.propertyName === driverProp.propertyName);

				let ptAddrText = null;
				if (facProp) ptAddrText = `${facProp.ptAddr} (${facProp.ptIdx})`;

				return {
					facilityIdx: this.facility.facilityIdx,
					direction: driverProp.direction,
					propertyName: driverProp.propertyName,
					propName: driverProp.propName,
					propAddr: facProp?.propAddr ?? null,
					ct: driverProp.ct,
					ptIdx: facProp ? facProp.ptIdx : null,
					ptAddr: facProp ? facProp.ptAddr : null,
					ptAddrText: ptAddrText,
					orgAddr: facProp ? facProp.ptAddr : null, // 변경여부 체크
					driverType: driverProp.driverType,
					version: driverProp.version,
					ptType: driverProp.ptType,
					remark: driverProp.remark,
					ptName: facProp ? facProp.ptName : null,
					isChecked: false,
				};
			});
			this.facilityMap.range(arr);
		},
		async searchFacilityMapWithPtAddr() {
			return backEndApi.facilityMap.searchFacilityMapWithPtAddr(this.facility.facilityIdx).then(({ data }) => {
				if (this.$err(data)) return [];
				return data;
			});
		},
		async getDriverProp() {
			return backEndApi.driver.getDriverProp(this.facility.driverType, this.facility.version).then(({ data }) => {
				if (this.$err(data)) return [];
				return data;
			});
		},
		//#endregion

		//#region 주소 생성
		onClearAddr() {
			this.alertConfirmWarning(popupMessages.POINT_DATA_COLLECTION_MODULE_DELETE_POPUP_MESSAGE).then((result) => {
				if (!result.value) return;

				this.facilityMap
					.filter((v) => v.isChecked)
					.forEach((prop) => {
						prop.ptAddr = "";
					});
			});
		},
		// 선택 관제점 자동 생성 - 기존 주소 무시...
		onFillAddr() {
			this.alertConfirmWarning(popupMessages.POINT_DATA_COLLECTION_MODULE_ADD_POPUP_MESSAGE).then((result) => {
				if (!result.value) return;

				this.facilityMap
					.filter((v) => v.isChecked)
					.forEach((prop) => {
						prop.ptAddr = this.makeAutoPtAddr(prop);
					});
			});
		},

		// 자동 관제점 생성 로직
		// 2023.02.07 물리관제점 명명체계 변경 권철규
		// 계측기 유형 + 계측기 일련번호 + 속성(피더+속성)

		// 2023.03.29 자동생성 로직 변경
		// 계측기명 + 속성(피더+속성)
		makeAutoPtAddr(facProp) {
			//계측기명
			let meterType = this.parent ? this.parent.facilityName : this.facility ? this.facility.facilityName : "ETC";

			//계측기 일련번호
			// let facilityIdx = this.parent ? this.parent.facilityIdx :
			//                   this.facility ? this.facility.facilityIdx : "0";

			//관제점 주소
			let ptFullAddr = `${meterType}.${facProp.ct}.${facProp.propName}`.toUpperCase();

			// 생성된 값이 이전과 같지 않다면 업데이트에 추가
			if (facProp.ptAddr !== ptFullAddr) this.onChangeAddr(facProp);

			return ptFullAddr;
		},

		// 관제점 업데이트 항목 정리
		onChangeAddr(facProp) {
			// 기존 ptIdx가 존재해야 업데이트 항목임
			if (!this.isEmpty(facProp.ptIdx) && !this.isEmpty(facProp.ptAddr)) {
				//let updateObj = this.updateMap.filter((v) => v.ptIdx).find((v) => v.ptIdx == facProp.ptIdx);
				let updateObj = this.updateMap.find((v) => v.ptIdx == facProp.ptIdx);

				// 기존값이 없는 경우
				if (this.isEmpty(updateObj)) {
					this.updateMap.push(facProp);
				} else {
					this.updateMap.forEach((v) => {
						Object.assign(v, facProp);
					});
				}
			}
		},

		//#endregion

		//#region 데이터 저장
		onSaveMap() {
			this.alertQuestion(popupMessages.POINT_DATA_COLLECTION_MODULE_SAVE_POPUP_MESSAGE).then(async (result) => {
				if (!result.value) return;

				// TODO : 트랜잭션 하나로 서비스 만들어야 함.
				await this.deletePointAndFacilityMap();
				await this.insertPointAndFacilityMap();
				await this.updatePointAndFacilityMap();

				this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
				this.$emit("save-done");
				this.initSetting();
			});
		},

		// 생성된 관제점 저장
		async insertPointAndFacilityMap() {
			// 체크된것들중에서 ptIdx는 없고, 주소가 있는것들은 신규임..
			let insertMap = this.facilityMap.filter((v) => v.isChecked && this.isEmpty(v.ptIdx) && !this.isEmpty(v.ptAddr));

			// console.log("onSaveMap insertMap =", insertMap);

			insertMap = insertMap
				.map((insert) => {
					let prop = this.driverProps.find((obj) => obj.direction == insert.direction && obj.propertyName == insert.propertyName);

					if (this.isEmpty(prop)) return null;

					// let facilityAlias = this.facility.facilityAlias === null ? this.parent.facilityAlias : this.facility.facilityAlias;						

					//계측기 유형
					let meterType = this.parent ? this.parent.facilityName : this.facility ? this.facility.facilityName : "ETC";

					let meterTypeCode = this.parent ? this.parent.meterTypeCode : this.facility.meterTypeCode ? this.facility.meterTypeCode : "";

					//계측기 일련번호
					// let facilityIdx = this.parent.facilityIdx ? this.parent.facilityIdx :
					//                   this.facility.facilityIdx ? this.facility.facilityIdx : "0";

					return {
						meterTypeCode: meterTypeCode,
						pt: {
							// tb_point에 입력항 데이터
							ptGroup: prop.isVirtual ? "Virtual" : `${meterType}`.toUpperCase(),
							//ptGroup: prop.isVirtual ? "Virtual" : insert.ptAddr.substr(0, 4),
							ptAddr: insert.ptAddr,
							ptName: `${this.parent.facilityAlias}/${this.facility.facilityAlias}/${prop.propName}`,
							ptTypeCode: prop.ptType.toLowerCase().replace(/^[a-z]/, (char) => char.toUpperCase()),
							filterMinValue: prop.min,
							filterMaxValue: prop.max,
							itemUseageCode: this.facility.usageCode,
							energySourceCode: this.facility.energySourceCode,
							energySplyTypeCode: this.facility.energySplyTypeCode,
							applyZoneIdx: this.facility.applyZoneIdx,
							unitLargeCode: prop.unitType,
							unitSmallCode: prop.unit,
							overFlowValue: null,
							isCov: 0,
							uplLogic: null,
							isVirtual: prop.isVirtual,
							valueType: prop.valueType,
							mappingKey: this.facility.mappingKey,
						},
						facilityMap: {
							// facilityMap에 입력할 데이터
							facilityIdx: this.facility.facilityIdx,
							direction: insert.direction,
							propertyName: insert.propertyName,
							ptIdx: null,
						},
					};
				})
				.filter((v) => v);

			if (insertMap.length == 0) return;

			return backEndApi.pointInfo.insertPointAndFacilityMap(insertMap).then(({ data }) => {
				if (this.$err(data)) return null;
				return data;
			});
		},
		// 변경된 관제점 업데이트c0
		async updatePointAndFacilityMap() {
			// console.log(this.facility);
			// 변경된 것들을 순회하면서, 체크가 되어있는지, 드라이버 속성은 존재하는지 검사하여, 정상이면 저장한다.
			let updateMap = this.updateMap

				.map((update) => {
					let facProp = this.facilityMap.find((v) => v.isChecked && v.ptIdx && v.ptIdx == update.ptIdx);
					if (this.isEmpty(facProp)) return null; // 체크되지 않았으면 제외

					let drvProp = this.driverProps.find((obj) => obj.direction == update.direction && obj.propertyName == update.propertyName);

					if (this.isEmpty(drvProp)) return null; // 드라이버 속성에 존재하지 않으면 제외

					return {
						ptIdx: update.ptIdx,
						ptAddr: update.ptAddr,
					};
				})
				.filter((v) => v);

			if (updateMap.length == 0) return;

			return backEndApi.pointInfo.updatePointAndFacilityMap(updateMap).then(({ data }) => {
				if (this.$err(data)) return null;

				// TODO: 데이터 초기화 ?? 왜 이렇게 하지???
				this.updateMap = this.updateMap.map((v) => {
					let match = updateMap.find((obj) => obj.ptIdx && obj.ptIdx == v.ptIdx);
					if (this.isEmpty(match)) return v;
				});

				return data;
			});
		},
		// 제거된 관제점 삭제
		async deletePointAndFacilityMap() {
			// 주소가 없는데, ptIdx가 있는 것들은 삭제한다.
			let deleteMap = this.facilityMap
				.map((v) => {
					if (v.isChecked && !this.isEmpty(v.ptIdx) && this.isEmpty(v.ptAddr)) {
						return v.ptIdx;
					}
					return null;
				})
				.filter((v) => v);

			if (deleteMap.length == 0) return;

			return backEndApi.pointInfo.deletePointAndFacilityMap(deleteMap).then(({ data }) => {
				if (this.$err(data)) return null;

				return data;
			});
		},
		//#endregion

		//#region 모달
		async openPointModal(property, event) {
			console.log("property!!!!!!!!!!!!!! => ", property)
			this.paramPtAddr = property.orgAddr;
			this.ptIdx = property.ptIdx;
			// await this.$refs.pointModal.show();
			this.ptSideBar = true;
			await event.stopPropagation();
		},
		onPointSaveDone() {
			this.$refs.pointModal.hide();
		},
		async openDriverPropModal(drvProp, event) {
			this.modalDriverProp = drvProp;
			// this.$refs.driverPropModal.show();
			this.drvSideBar = true;
			await event.stopPropagation();
		},
		//#endregion

		//#region 기타함수
		toggleAll() {
			let isAll = false;

			// 전체가 선택되어 있으면, 해제시킨다.
			let arr = this.pagingMap;
			if (arr.length == arr.filter((v) => v.isChecked).length) {
				isAll = false;
			} else {
				isAll = true;
			}

			this.pagingMap.forEach((v) => {
				v.isChecked = isAll;
			});
		},
		handleBodyDragOver(data, event) {
			if (data.item.type === this.CODE.Drag.Point || data.item.type === this.CODE.Drag.PointGroup) event.dataTransfer.dropEffect = "move";
			else event.dataTransfer.dropEffect = "none";
		},
		handleBodyDrop(transfer, dragEvent) {
			this.handleDropPoint(transfer, dragEvent);
		},
		handleDropPoint(transfer) {
			console.log("handleDropPoint", transfer);
		},
		// 페이징
		beforePage() {
			this.curPage -= 1;

			if (this.minPage != 1 && this.curPage < this.minPage) this.minPage -= 1;
		},
		nextPage() {
			if (this.curPage < this.maxPage) {
				this.curPage += 1;

				if (this.curPage > this.minPage + this.pageSize - 1 && this.minPage + this.pageSize - 1 < this.maxPage) this.minPage += 1;
			}
		},
		onTotalRealPopup() {
			this.$refs.totalRealModal.show();
		},
		//#endregion
	},
};
</script>

<style scoped>
.title {
	font-size: 14px;
	font-weight: 600;
	margin-left: 10px;
	margin-right: 10px
}

.badge {
	font-size: 12px;
	font-weight: 500;
	margin-right: 4px;
	display: inline-block;
	min-width: 20px;
	padding: 0px 10px 0px 10px;
	color: #fff;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	/* background-color: #777; */
	line-height: 22px;
	border-radius: 8px;
}

.badge.id {
	background-color: #FF6D00;
	color: #fff;
}

.badge.title {
	background-color: #546E7A;
	color: #fff;
}

.badge.option {
	background-color: #CFD8DC;
	color: #000;
}

.hr {
	box-sizing: content-box;
	overflow: visible;
	/* margin-top: 10px; */
	/* margin-bottom: 10px; */
	border-top: 1px solid black;
	border: 0;
	height: 1px;
	background: rgba(0, 0, 0, .1);
}
</style>